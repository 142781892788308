import logoIcon from "../../assets/icon/logo.svg";
import React, { useState, useLayoutEffect } from "react";
import { getOrderInvoicement } from "../../service/orderService";

const Inviocement = () => {
  const orderId = new URLSearchParams(window.location.search).get("orderId");
  const [invoicement, setInvoicement] = useState(null);

  useLayoutEffect(() => {
    const fetchInvoicement = async () => {
      getOrderInvoicement(orderId)
        .then((data) => {
          setInvoicement(data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchInvoicement();
  }, [orderId]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <div
        style={{
          width: "1024px",
          height: "720px",
        }}
      >
        <div
          style={{
            margin: "48px 0",
            display: "flex",
          }}
        >
          <img
            src={logoIcon}
            alt="icon"
            style={{
              width: "100px",
              height: "100px",
            }}
          />
          <div style={{ marginLeft: "12px" }}>
            <p
              style={{
                fontFamily: "PangMenZhengDaoXiXianTi",
                margin: 0,
                fontSize: "48px",
                fontWeight: "600",
              }}
            >
              乌图游戏
            </p>
            <p
              style={{
                fontFamily: "PangMenZhengDaoXiXianTi",
                margin: 0,
                marginTop: "-24px",
                marginLeft: "6px",
                fontSize: "24px",
                fontWeight: "800",
              }}
            >
              Uatut Games
            </p>
          </div>
        </div>
        <p
          style={{
            fontSize: "64px",
            fontWeight: "600",
            color: "#666666",
            margin: "32px 0",
          }}
        >
          {`${invoicement?.userMobile}，您好！`}
        </p>
        <p
          style={{
            fontSize: "56px",
            fontWeight: "600",
            color: "#333333",
            margin: "32px 0",
          }}
        >
          感谢您近期在 乌图游戏 上的交易。
        </p>
        <p
          style={{
            fontSize: "32px",
            fontWeight: "600",
            color: "#666666",
            margin: "32px 0",
          }}
        >
          以下物品已添加到您的 乌图游戏 账户。
        </p>
        <p
          style={{
            fontSize: "32px",
            fontWeight: "600",
            color: "#666666",
            margin: "32px 0",
          }}
        >
          如果您未曾使用过 乌图游戏 ，您可以 <a href="/">在此</a> 获得免费的
          乌图游戏 Windows程序。
        </p>
        <div
          style={{
            // width: "100%",
            border: "6px solid #E4891F",
            borderRadius: "48px",
            padding: "24px",
            margin: "24px 0 24px 0",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {invoicement?.invoiceItems.map((item) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "48px",
                }}
              >
                <img
                  style={{
                    height: "225px",
                    width: "400px",
                    borderRadius: "32px",
                  }}
                  src={item?.gameLandscapeUrl}
                  alt="landscape"
                />
                <p
                  style={{
                    fontSize: "48px",
                    fontWeight: "600",
                    color: "#333333",
                    maxWidth: "400px",
                    margin: "0",
                  }}
                >
                  {item?.gameName}
                </p>
              </div>
              <p
                style={{
                  fontSize: "40px",
                  fontWeight: "600",
                  color: "#333333",
                  margin: "0",
                }}
              >
                ￥ {item?.gamePrice}
              </p>
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              border: "6px solid #E4891F",
              borderRadius: "48px",
              width: "65%",
              padding: "20px 0",
            }}
          >
            <div
              style={{
                padding: "0 24px",
                display: "flex",
                flexDirection: "row",
                fontSize: "28px",
              }}
            >
              <p
                style={{
                  margin: "8px 0",
                  color: "#333333",
                  width: "200px",
                }}
              >
                账户名称：
              </p>
              <p
                style={{
                  margin: "8px 0",
                  fontWeight: "600",
                  color: "#333333",
                  maxWidth: "400px",
                }}
              >
                {invoicement?.userMobile}
              </p>
            </div>
            <div
              style={{
                padding: "0 24px",
                display: "flex",
                flexDirection: "row",
                fontSize: "28px",
              }}
            >
              <p
                style={{
                  margin: "8px 0",
                  color: "#333333",
                  width: "200px",
                  minWidth: "200px",
                }}
              >
                订单号：
              </p>
              <p
                style={{
                  margin: "8px 0",
                  fontWeight: "600",
                  color: "#333333",
                  maxWidth: "300px",
                }}
              >
                {invoicement?.orderId}
              </p>
            </div>
            <div
              style={{
                padding: "0 24px",
                display: "flex",
                flexDirection: "row",
                fontSize: "28px",
              }}
            >
              <p
                style={{
                  margin: "8px 0",
                  color: "#333333",
                  width: "200px",
                  minWidth: "200px",
                }}
              >
                创建日期：
              </p>
              <p
                style={{
                  margin: "8px 0",
                  fontWeight: "600",
                  color: "#333333",
                  maxWidth: "400px",
                }}
              >
                {invoicement?.createTime}
              </p>
            </div>
            <div
              style={{
                padding: "0 24px",
                display: "flex",
                flexDirection: "row",
                fontSize: "28px",
              }}
            >
              <p
                style={{
                  margin: "8px 0",
                  color: "#333333",
                  width: "200px",
                  minWidth: "200px",
                }}
              >
                支付方式：
              </p>
              <p
                style={{
                  margin: "8px 0",
                  fontWeight: "600",
                  color: "#333333",
                  maxWidth: "400px",
                }}
              >
                {invoicement?.payType?.toLowerCase() === "alipay"
                  ? "支付宝"
                  : invoicement?.payType === "WeChat Pay"
                    ? "微信支付"
                    : "未知"}
              </p>
            </div>
          </div>
          <div
            style={{
              border: "6px solid #E4891F",
              borderRadius: "48px",
              width: "30%",
              padding: "20px 0",
            }}
          >
            <div
              style={{
                padding: "0 24px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                fontSize: "28px",
              }}
            >
              <p
                style={{
                  margin: "8px 0",
                  color: "#333333",
                }}
              >
                合计：
              </p>
              <p
                style={{
                  margin: "8px 0",
                  fontWeight: "600",
                  color: "#333333",
                }}
              >
                ￥ {invoicement?.totalAmount}
              </p>
            </div>
          </div>
        </div>
        <p
          style={{
            fontSize: "32px",
            fontWeight: "600",
            color: "#333333",
            margin: "32px 0",
          }}
        >
          此页面将作为您的收据。
        </p>
        <p
          style={{
            fontSize: "32px",
            fontWeight: "600",
            color: "#333333",
            margin: "32px 0",
          }}
        >
          乌图游戏 上的许多游戏支持退款和/或退货。详情请通过
          support@u4ugames.com 进行咨询。
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "48px",
            margin: "72px 0",
          }}
        >
          <div
            style={{
              border: "4px solid #E4891F",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "32px 0",
            }}
          >
            <p
              style={{
                fontSize: "30px",
                color: "#333333",
                margin: "0",
              }}
            >
              祝您游戏愉快！
            </p>
            <p
              style={{
                fontSize: "30px",
                color: "#333333",
                margin: "0",
              }}
            >
              乌图游戏 团队
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inviocement;
