import React, { useEffect, useState } from "react";
import { Table, Input, Space, Select, Button } from "antd";
import {
  getGameDashboardList,
} from "../../../service/dashboardService";
import {
  gameList,
} from "../../../service/gameService";
import {useParams} from "react-router-dom";

const pageSize = 10;

const GameDashboardList = () => {
  const [datas, setDatas] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageSettings, setPageSettings] = useState([0, pageSize]);
  const [searchValue, setSearchValue] = useState("");
  const [valueOptions, setValueOptions] = useState([]);
  const { publisherId } = useParams();

  useEffect(() => {
    const fetchGameData = async () => {
      const response = await gameList(false, "", "", publisherId);
      var data = [];
      response.results.forEach(item => {
        data.push({value: item.slug, label: item.name})
      });

      setValueOptions(data);
    };

    fetchGameData();
  }, []);

  useEffect(() => {
    const fetchDatas = async () => {
      if (searchValue) {
        try {
          const dataResponse = await getGameDashboardList(searchValue, publisherId);
          setDatas(dataResponse.results);
          setTotal(dataResponse.total);
        } catch (errInfo) {
          console.log("Get game dashboard data failed:", errInfo);
          setDatas([]);
          setTotal(0);
        }
      } else {
        const dataResponse = await getGameDashboardList('', publisherId);
        setDatas(dataResponse.results);
        setTotal(dataResponse.total);
      }
    };

    fetchDatas();
  }, [searchValue]);
  // 在此处获取Dashboard数据

  const columns = [
    {
      title: "日期",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "游戏",
      dataIndex: "gameSlug",
      key: "gameSlug",
    },
    {
      title: "PV",
      dataIndex: "pv",
      key: "pv",
    },
    {
      title: "UV",
      dataIndex: "uv",
      key: "uv",
    },
    {
      title: "主页点击量 ",
      dataIndex: "homeView",
      key: "homeView",
    },
    {
      title: "新增游戏购买数量",
      dataIndex: "newPurchase",
      key: "newPurchase",
    },
    {
      title: "新增总下载量",
      dataIndex: "newDownload",
      key: "newDownload",
    },
    {
      title: "新增心愿单",
      dataIndex: "newWishList",
      key: "newWishList",
    },
    {
      title: "立即购买点击数",
      dataIndex: "purchaseNowClick",
      key: "purchaseNowClick",
    },
    {
      title: "立即支付点击数",
      dataIndex: "payNowClick",
      key: "payNowClick",
    },
    {
      title: "游戏付费成功率",
      dataIndex: "paySuccessRate",
      key: "paySuccessRate",
    },
    {
      title: "新增退款量",
      dataIndex: "newRefunded",
      key: "newRefunded",
    },
    {
      title: "累计退款量",
      dataIndex: "allRefunded",
      key: "allRefunded",
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <div style={{ display: "flex", gap: "10px" }}>
          <Space.Compact>
            <Button>
              按游戏搜索
            </Button>
            <Select
              defaultValue={searchValue}
              options={valueOptions}
              style={{ width: 96 }}
              onChange={(value) => {
                setSearchValue(value);
              }}
            />
          </Space.Compact>
        </div>
      <Table
        rowClassName={() => "editable-row"}
        columns={columns}
        dataSource={datas}
        rowKey={(record) => record.id}
        pagination={{
          total: total,
          pageSize: pageSize,
          hideOnSinglePage: false,
          onChange: (page, pageSize) => {
            setPageSettings([(page - 1) * pageSize, pageSize]);
          },
        }}
        bordered
      />
    </div>
  );
};

export default GameDashboardList;
