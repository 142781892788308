import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as opencc from "opencc-js";

const converter = opencc.Converter({ from: "cn", to: "hk" });

const resources = {
  zh: {
    translation: {
      home_page: "商城首页",
      title: "乌图游戏",
      "title.wuyu": "乌图游戏 - 森久城物语",
      "title.miwu": "乌图游戏 - 迷雾侦探",
      download: "下载客户端",
      steam_good: "特别好评",
      steam_medium: "多半好评",
      site_name: "乌图游戏",
      login_title: "登录",
      logout_title: "退出登录",
      phone_hint: "请输入手机号或邮箱",
      email_hint: "请输入邮箱",
      code_hint: "请输入验证码",
      password_hint: "请输入密码",
      code_input: "获取验证码",
      code_input_again: "重新获取",
      register: "登录/注册",
      login: "登录",
      login_validating: "登录验证中",
      login_error: "登录失败，请检查验证码是否正确",
      login_error_psd: "密码错误，请重试",
      login_error_not_found: "账号未注册，请使用验证码登录/注册",
      login_success: "登录成功",
      "terms.start": "我已阅读并同意",
      "terms.and": "和",
      "terms.user_service_agreement": "《用户服务协议》",
      "terms.personal_information_processing_rules": "《个人信息处理规则》",
      redirecting: "跳转中",
      checkout: "立即支付",
      choose1: "您已选择",
      choose2: "款内容",
      publish_date: "发行日期",
      current_account: "当前账户",
      total_hint: "项内容共计",
      checkout_hint: "点击后，将跳转至支付方式选择页面",
      purchase: "购买",
      annoymous_user: "匿名用户",
      user_comment: "用户评价",
      fold_comment: "收起阅读",
      unfold_comment: "展开阅读",
      on_sale: "火热售卖中",
      developer: "开发商",
      publisher: "发行商",
      steam_comment: "Steam评价",
      black_score: "小黑盒得分",
      game_detail: "游戏详情",
      fold_detail: "收起阅读",
      unfold_detail: "展开阅读",
      download_now: "立即下载",
      play_now: "马上开玩",
      purchase_now: "立即购买",
      show_detail: "查看详情",
      more_recommand: "更多推荐",
      support: "售后服务",
      support_email: "客服邮箱",
      game_static: "游戏数据",
      ign_score: "IGN评分",
      online_count: "最高在线人数",
      os: "操作系统",
      processor: "处理器",
      memory: "内存",
      graphics: "显卡",
      storage: "存储空间",
      configuration: "配置要求",
      minimum_configuration: "最低配置",
      recommend_configuration: "推荐配置",
      download_hint1: "请直接打开客户端，下载游戏",
      download_hint2: "若未下载客户端，请您先下载客户端",
      purchase_success1: "您已成功购买",
      purchase_success2: "款内容",
      purchase_fail: "购买失败，请重新购买",
      purchase_duplicate: "该游戏已在购买流程中，无法再次购买",
      send_code_error: "发送验证码失败，请检查手机号是否正确",
      send_code_success: "发送验证码成功，请注意查收",
      send_code_invalid: "发送验证码失败，请检查手机号或邮箱的格式",
      wishlist: "心愿单",
      remove: "移除",
      selected: "已选",
      my_wishlist: "我的心愿单",
      cancel: "取消",
      cancel_confrim_1: "是否将《",
      cancel_confrim_2: "》从心愿单移除？",
      cancel_confrim_3: "是否将所选游戏从心愿单移除？",
      wishlist_btn_hint1: "添加到我的心愿单",
      wishlist_btn_hint2: "从我的心愿单中移除",
      age_hint: "包含未经分级的内容，有可能并不是适合所有年龄段。",
      continue: "继续",
      back_home: "返回商城",
      pay_title: "安全支付",
      pay_secure: "所有交易安全且加密",
      pay_hint_1: "请使用 ",
      pay_hint_2: " 扫一扫支付",
      pay_order: "订单号",
      pay_cancel: "返回订单详情",
      pay_choose: "请选择支付方式",
      pay_btn_hint: "点击后，将弹出付款码",
      dlc_title: "DLC",
      standalone_title: "游戏本体",
      purchase_all: "购买全部",
      dlc_hint1: "请先购买",
      dlc_hint2: "游戏本体，方可畅玩",
      sid_recommand: "查看更多",
      comment_now: "写评论",
      comment_hint: "写下您的评论",
      publish_now: "发布",
      publishing: "发布中",
      like_or_not: "您会推荐这款游戏吗？",
      attitude_like: "推荐",
      attitude_dislike: "不推荐",
      cancel_order_confirm: "确认要返回详情？",
      min_char_num_is_6: "评论的有效字符不能少于6个",
      comment_success: "评论成功",
      comment_failure: "评论失败",
      purchase_before_comment: "请先购买游戏，再写评论",
      download_hub_hint: "您已收到游戏包体，请下载安装客户端",
      supply_order_text:
        "请在购买本游戏后进行游玩，点击下述按钮跳转到游戏购买页面",
      purchased_games: "已购游戏",
      basic_info: "基本信息",
      new_phone_title: "设置手机号码",
      reset_phone_title: "修改手机号码",
      new_email_title: "设置邮箱",
      reset_email_title: "修改邮箱",
      new_password_title: "设置密码",
      reset_password_title: "修改密码",
      password_title: "修改密码",
      verify: "验证",
      verify_hint:
        "为了您的账户安全，请先进行验证，验证成功后，将进行下一步操作。",
      verify_code_success: "验证成功",
      verify_code_error: "验证码错误",
      mobile_hint: "请输入新手机号",
      new_phone_verify: "确定",
      reset_phone_duplicate: "新手机号不能与原手机号重复",
      reset_phone_exist: "手机号已被绑定至其他账号",
      reset_email_duplicate: "新邮箱不能与原邮箱重复",
      reset_email_exist: "邮箱已被绑定至其他账号",
      reset_success: "修改成功",
      reset_failure: "修改失败",
      f_password_hint: "最少8位，大小写字母/数字组合",
      s_password_hint: "请重新输入密码",
      not_match: "最少8位，且最少包含1个大写、1个小写字母和1个数字",
      f_null: "请输入新密码",
      s_null: "请再次输入新密码",
      inconsistent: "两次密码不一致",
      invalid_password: "密码格式错误",
      age_format_error: "年龄的格式不正确",
    },
  },
  zhHant: {
    translation: {
      home_page: "商城首頁",
      title: "烏圖遊戲",
      "title.wuyu": "烏圖遊戲 - 森久城物語",
      "title.miwu": "烏圖遊戲 - 迷霧偵探",
      download: "下載客戶端",
      steam_good: "特別好評",
      steam_medium: "多半好評",
      site_name: "烏圖遊戲",
      login_title: "登錄",
      phone_hint: "請輸入手機號碼或信箱",
      email_hint: "請輸入信箱",
      code_hint: "請輸入驗證碼",
      password_hint: "請輸入密碼",
      code_input: "獲取驗證碼",
      code_input_again: "重新獲取",
      register: "登錄/註冊",
      login: "登錄",
      logout_title: "退出登錄",
      login_validating: "登錄驗證中",
      login_error: "登錄失敗，請檢查驗證碼是否正確",
      login_error_psd: "密碼錯誤，請重試",
      login_error_not_found: "帳號未註冊，請使用驗證碼登入/註冊",
      login_success: "登錄成功",
      "terms.start": "我已閱讀並同意",
      "terms.and": "和",
      "terms.user_service_agreement": "《用戶服務協議》",
      "terms.personal_information_processing_rules": "《個人信息處理規則》",
      redirecting: "跳轉中",
      checkout: "立即支付",
      choose1: "您已選擇",
      choose2: "款內容",
      publish_date: "發行日期",
      current_account: "當前賬戶",
      total_hint: "項內容共計",
      checkout_hint: "點擊後，將跳轉至支付方式選擇頁面",
      purchase: "購買",
      annoymous_user: "匿名用戶",
      user_comment: "用戶評價",
      fold_comment: "收起閱讀",
      unfold_comment: "展開閱讀",
      on_sale: "火熱售賣中",
      developer: "開發商",
      publisher: "發行商",
      steam_comment: "Steam評價",
      black_score: "小黑盒得分",
      game_detail: "遊戲詳情",
      fold_detail: "收起閱讀",
      unfold_detail: "展開閱讀",
      download_now: "立即下載",
      play_now: "馬上開玩",
      purchase_now: "立即購買",
      show_detail: "查看詳情",
      more_recommand: "更多推薦",
      support: "售後服務",
      support_email: "客服郵箱",
      game_static: "遊戲數據",
      ign_score: "IGN評分",
      online_count: "最高在線人數",
      os: "操作系統",
      processor: "處理器",
      memory: "內存",
      graphics: "顯卡",
      storage: "存儲空間",
      configuration: "配置要求",
      minimum_configuration: "最低配置",
      recommend_configuration: "推薦配置",
      download_hint1: "請直接開啟客戶端，下載遊戲",
      download_hint2: "若未下載客戶端，請先下載客戶端",
      purchase_success1: "您已成功購買",
      purchase_success2: "款內容",
      purchase_fail: "購買失敗，請重新購買",
      purchase_duplicate: "該遊戲已在購買流程中，無法再次購買",
      send_code_error: "發送驗證碼失敗，請檢查手機號是否正確",
      send_code_success: "發送驗證碼成功，請註意查收",
      send_code_invalid: "發送驗證碼失敗，請檢查手機號碼或信箱的格式",
      wishlist: "心願單",
      remove: "移除",
      selected: "已選",
      my_wishlist: "我的心願單",
      cancel: "取消",
      cancel_confrim_1: "是否將《",
      cancel_confrim_2: "》從心願單移除？",
      cancel_confrim_3: "是否將所選遊戲從心願單移除？？",
      wishlist_btn_hint1: "添加到我的心願單",
      wishlist_btn_hint2: "從我的心願單中移除",
      age_hint: "包含未經分級的內容，有可能並不是適合所有年齡段。",
      continue: "繼續",
      back_home: "返回商城",
      pay_title: "安全支付",
      pay_secure: "所有交易安全且加密",
      pay_hint_1: "請使用 ",
      pay_hint_2: " 掃一掃支付",
      pay_order: "訂單號",
      pay_cancel: "返回訂單詳情",
      pay_choose: "請選擇支付方式",
      pay_btn_hint: "點擊後，將彈出付款碼",
      dlc_title: "DLC",
      standalone_title: "遊戲本體",
      purchase_all: "購買全部",
      dlc_hint1: "請先購買",
      dlc_hint2: "遊戲本體，方可暢玩",
      sid_recommand: "查看更多",
      comment_now: "寫評論",
      comment_hint: "寫下您的評論",
      publish_now: "發布",
      publishing: "發佈中",
      like_or_not: "您會推薦這款遊戲嗎？",
      attitude_like: "推薦",
      attitude_dislike: "不推薦",
      cancel_order_confirm: "確認要返回詳情？",
      min_char_num_is_6: "評論的有效字元不能少於6個",
      comment_success: "評論成功",
      comment_failure: "評論失敗",
      purchase_before_comment: "請先購買遊戲，再寫評論",
      download_hub_hint: "您已收到遊戲包體，請下載安裝客戶端",
      supply_order_text:
        "請在購買本遊戲後進行遊玩，點擊下述按鈕跳轉到遊戲購買頁面",
      purchased_games: "已購遊戲",
      basic_info: "基本資訊",
      new_phone_title: "设置手機號碼",
      reset_phone_title: "修改手機號碼",
      new_email_title: "设置信箱",
      reset_email_title: "修改信箱",
      new_password_title: "设置密码",
      reset_password_title: "修改密码",
      verify: "驗證",
      verify_hint:
        "為了您的帳戶安全，請先進行驗證，驗證成功後，將進行下一步操作。",
      verify_code_success: "驗證成功",
      verify_code_error: "驗證碼錯誤",
      mobile_hint: "請輸入新手機號",
      new_phone_verify: "確定",
      reset_phone_duplicate: "新手機號碼不能與原手機號重複",
      reset_phone_exist: "手機號碼已綁定至其他帳號",
      reset_email_duplicate: "新信箱不能與原信箱重複",
      reset_email_exist: "郵箱已綁定至其他帳號",
      reset_success: "修改成功",
      reset_failure: "修改失敗",
      f_password_hint: "最少8位，大小寫字母/數字組合",
      s_password_hint: "請重新輸入密碼",
      not_match: "最少8位，且最少包含1個大寫、1個小寫字母和1個數字",
      f_null: "請輸入新密碼",
      s_null: "請再次輸入新密碼",
      inconsistent: "兩次密碼不一致",
      invalid_password: "密碼格式錯誤",
      age_format_error: "年齡的格式不正確",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "zhHant",
  interpolation: {
    escapeValue: false,
  },
});

export function extractGameName(game, language) {
  let name = game.name;
  if (game.latestVersion?.intel && game.latestVersion?.intel[language]?.name) {
    name = game.latestVersion?.intel[language]?.name;
  }
  if (game.intel && game.intel[language]?.name) {
    name = game.intel[language]?.name;
  }
  return name;
}

export function extractGameField(game, language, field) {
  let value = game[field];
  if (game.latestVersion && game.latestVersion[field]) {
    value = game.latestVersion[field];
  }
  if (
    game.latestVersion?.intel &&
    game.latestVersion?.intel[language] &&
    game.latestVersion?.intel[language][field]
  ) {
    value = game.latestVersion?.intel[language][field];
  }
  if (game.intel && game.intel[language] && game.intel[language][field]) {
    value = game.intel[language][field];
  }
  return value;
}

export function extractGameDeepField(game, language, field1, field2) {
  let value;
  if (game[field1]) {
    value = game[field1][field2];
  }
  if (
    game.latestVersion &&
    game.latestVersion[field1] &&
    game.latestVersion[field1][field2]
  ) {
    value = game.latestVersion[field1][field2];
  }
  if (
    game.latestVersion?.intel &&
    game.latestVersion?.intel[language] &&
    game.latestVersion?.intel[language][field1] &&
    game.latestVersion?.intel[language][field1][field2]
  ) {
    value = game.latestVersion?.intel[language][field1][field2];
  }
  if (
    game.intel &&
    game.intel[language] &&
    game.intel[language][field1] &&
    game.intel[language][field1][field2]
  ) {
    value = game.intel[language][field1][field2];
  }
  return value;
}

export function convertSimplifiedArrayToTraditional(arr) {
  if (!arr) return [];
  return arr.map((element) => converter(element));
}

export function convertSimplifiedToTraditional(text) {
  if (!text) return "";
  return converter(text);
}

export default i18n;
