import React, { useEffect, useState } from "react";
import { Space, Table, Tag, Input, Select, Button } from "antd";
import { ReloadOutlined, EyeOutlined } from "@ant-design/icons";
import {
  userList,
  upsertUserRoles,
  putUser,
} from "../../../service/userService";
import { EditableTableComponet, getEditableColumns } from "../../EditableTable";
import ObjectPreviewModal from "../../ObjectPreviewModal";

const { Search } = Input;

const searchOptions = [
  {
    value: "mobile",
    label: "手机",
  },
  {
    value: "id",
    label: "完整UID",
  },
];

const pageSize = 10;

function addRole(userRoles, role) {
  if (!userRoles?.includes(role)) {
    if (userRoles === null) {
      userRoles = [];
    }
    userRoles.push(role);
  }
  return userRoles;
}

function removeRole(userRoles, role) {
  const index = userRoles?.indexOf(role);
  if (index > -1 && userRoles !== null) {
    userRoles.splice(index, 1);
  }
  return userRoles;
}

const UserList = () => {
  const uidParam = new URLSearchParams(window.location.search).get("UID");
  const [searchValue, setSearchValue] = useState(uidParam ? uidParam : "");
  const [searchType, setSearchType] = useState(uidParam ? "id" : "mobile");
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageSettings, setPageSettings] = useState([0, pageSize]);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [previewingObject, setPreviewingObject] = useState(null);

  function updateUsersRole(userId, userRoles) {
    const newUsers = users.map((user) => {
      if (user.id === userId) {
        user.roles = userRoles;
      }
      return user;
    });
    setUsers(newUsers);
  }

  useEffect(() => {
    const fetchUsers = async () => {
      let searchMobile = "";
      let searchId = "";
      if (searchType === "mobile") {
        searchMobile = searchValue;
      }
      if (searchType === "id") {
        searchId = searchValue;
      }
      const userResponse = await userList(pageSettings, searchId, searchMobile);
      // console.log(orderResponse);
      setUsers(userResponse.results);
      setTotal(userResponse.total);
    };

    fetchUsers();
  }, [pageSettings, searchType, searchValue]);
  // 在此处获取订单数据

  const columns = [
    {
      title: "用户ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "名称",
      dataIndex: "name",
      key: "name",
      editable: true,
    },
    {
      title: "手机",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "角色",
      key: "roles",
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          {record.roles?.map((role) => (
            <Tag key={role} color={role === "admin" ? "red" : "blue"}>
              {role.toUpperCase()}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: "授权数据",
      render: (_, record) => (
        <Button
          icon={<EyeOutlined />}
          onClick={() => {
            setPreviewingObject(record);
            setOpenPreviewModal(true);
          }}
        />
      ),
    },
    {
      title: "操作",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <Button
            danger
            size="small"
            disabled={!record.roles?.includes("admin")}
            onClick={() => {
              const userRoles = removeRole(record.roles, 'admin');
              upsertUserRoles(record.id, userRoles)
                .then((data) => {
                  updateUsersRole(record.id, data.roles);
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
          >
            撤销管理员
          </Button>
          <Button
            size="small"
            disabled={record.roles?.includes("admin")}
            onClick={() => {
              const userRoles = addRole(record.roles, 'admin');
              upsertUserRoles(record.id, userRoles)
                .then((data) => {
                  updateUsersRole(record.id, data.roles);
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
          >
            设置为管理员
          </Button>
          <Button
              danger
              size="small"
              disabled={!record.roles?.includes("publisher")}
              onClick={() => {
                const userRoles = removeRole(record.roles, 'publisher');
                upsertUserRoles(record.id, userRoles)
                    .then((data) => {
                      updateUsersRole(record.id, data.roles);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
              }}
          >
            撤销开发者
          </Button>
          <Button
              size="small"
              disabled={record.roles?.includes("publisher")}
              onClick={() => {
                const userRoles = addRole(record.roles, 'publisher');
                upsertUserRoles(record.id, userRoles)
                    .then((data) => {
                      updateUsersRole(record.id, data.roles);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
              }}
          >
            设置为开发者
          </Button>
        </Space>
      ),
    },
  ];

  const handleSave = (row) => {
    console.log("row", row);
    const newData = [...users];
    const index = newData.findIndex((item) => row.id === item.id);
    newData[index] = row;
    putUser(row.id, row)
      .then((_) => {
        setUsers(newData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <div style={{ display: "flex", gap: "10px" }}>
        <Space.Compact>
          <Select
            defaultValue={searchType}
            options={searchOptions}
            style={{ width: 96 }}
            onChange={(value) => {
              setSearchType(value);
              setSearchValue("");
            }}
          />
          <Search
            placeholder={`输入${
              searchOptions.find((option) => option.value === searchType)
                ?.label || ""
            }搜索`}
            onSearch={(value) => {
              setSearchValue(value);
            }}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            enterButton
            style={{ width: 200 }}
          />
        </Space.Compact>
        <Button
          onClick={() => {
            window.location.reload();
          }}
          icon={<ReloadOutlined />}
        />
      </div>
      <Table
        components={EditableTableComponet}
        rowClassName={() => "editable-row"}
        columns={getEditableColumns(columns, handleSave)}
        dataSource={users}
        rowKey={(record) => record.id}
        pagination={{
          total: total,
          pageSize: pageSize,
          hideOnSinglePage: false,
          onChange: (page, pageSize) => {
            setPageSettings([(page - 1) * pageSize, pageSize]);
          },
        }}
        bordered
      />
      <ObjectPreviewModal
        show={openPreviewModal}
        object={previewingObject}
        onCancel={() => {
          setOpenPreviewModal(false);
          setPreviewingObject(null);
        }}
      />
    </div>
  );
};

export default UserList;
