import styles from "./GamePage.module.css";
import PurchaseButton from "./PurchaseButton";
import saleIcon from "../../assets/icon/sale.svg";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  extractGameDeepField,
  extractGameField,
  extractGameName,
} from "../../utils/i18n";

const Brief = ({ gameData, gameItem }) => {
  const { t, i18n } = useTranslation();

  const renderTags = (tags) => {
    if (!tags) {
      return null;
    }
    return tags.map((tag, index) => {
      return (
        <div className={styles.frame}>
          <div className={styles.button}>{tag}</div>
        </div>
      );
    });
  };

  if (!gameData && !gameItem) {
    return <div />;
  }

  const discount =
    gameItem &&
    Math.round(
      ((gameItem.originPrice - gameItem.price) / gameItem.originPrice) * 100,
    );
  const price = gameItem?.price ? `¥${gameItem.price}` : gameData?.price;
  const originPrice = gameItem?.originPrice
    ? `¥${gameItem.originPrice}`
    : gameData?.originPrice;
  const gameName = extractGameName(gameData ?? gameItem, i18n.language);
  const enName = gameData ? gameData?.enName : gameItem.latestVersion.nameEn;
  const tags = extractGameField(gameData ?? gameItem, i18n.language, "tags");
  const brief = gameData
    ? extractGameField(gameData, i18n.language, "brief")
    : extractGameField(gameItem, i18n.language, "description");
  const developer = extractGameField(
    gameData ?? gameItem,
    i18n.language,
    "developer",
  );
  const publisher = extractGameField(
    gameData ?? gameItem,
    i18n.language,
    "publisher",
  );
  const publishDate = gameData
    ? extractGameField(gameData, i18n.language, "releaseDate")
    : gameItem.firstReleaseDate;
  const steam = gameData
    ? t(gameData?.statics.steam)
    : extractGameDeepField(gameItem, i18n.language, "statistics", "steam");
  const ign = gameData
    ? gameData?.statics.ign + "分"
    : extractGameDeepField(gameItem, i18n.language, "statistics", "ign");

  return (
    <div className={styles.div59}>
      <div className={styles.div60} />
      <div className={styles.sale}>
        <div className={styles.saleChild} />
        <div className={styles.sale1}>
          <img src={saleIcon} alt="sale-icon" />
        </div>
      </div>
      <div className={styles.group}>
        <div className={styles.group1}>
          <div className={styles.div61}>
            <div className={styles.button}>🔥{t("on_sale")}</div>
          </div>
          <div className={styles.div63}>
            <div className={styles.div64}>{price}</div>
            <div className={styles.div34}>{originPrice}</div>
            {discount > 4 && (
              <div className={styles.wrapper}>
                <div className={styles.div35}>-{discount}%</div>
              </div>
            )}
          </div>
        </div>
        <PurchaseButton game={gameItem} className={styles.purchaseButton} />
      </div>
      <div className={styles.div67}>
        <div className={styles.group2}>
          <div className={styles.parent}>
            <div className={styles.div68}>
              <h1 className={styles.div69}>{gameName}</h1>
              <div className={styles.castleMorihisa}>{enName}</div>
            </div>
            <div className={styles.tag}>{renderTags(tags)}</div>
          </div>
        </div>
        <div className={styles.group3}>
          <div className={styles.roguelike} title={brief}>
            {brief}
          </div>
          <div className={styles.div74}>
            <div className={styles.div19}>
              <p className={styles.p}>{t("developer")}：</p>
              <p className={styles.p}>{t("publisher")}：</p>
              <p className={styles.p}>{t("publish_date")}：</p>
            </div>
            <div className={styles.windows7AnythingContainer}>
              <p className={styles.p}>{developer}</p>
              <p className={styles.p}>{publisher}</p>
              <p className={styles.p}>{publishDate}</p>
            </div>
          </div>
          <div className={styles.div74}>
            <div className={styles.div19}>
              <p className={styles.p}>{t("steam_comment")}：</p>
              <p className={styles.p}>{t("ign_score")}:</p>
            </div>
            <div className={styles.windows7AnythingContainer}>
              <p className={styles.p}>{steam}</p>
              <p className={styles.p}>{ign}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brief;
