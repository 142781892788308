import apiClient from "./apiClient";

export async function userEntitlements(id) {
  const entitlementResponse = await apiClient.get(
    `${process.env.REACT_APP_API_URL}/api/entitlement?userId=${id}`,
  );
  return entitlementResponse.data;
}

export async function postEntitlement(itemId, itemSlug) {
  const entitlementResponse = await apiClient.post(
    `${process.env.REACT_APP_API_URL}/api/entitlement`,
    {
      namespace: "game",
      itemSlug,
      itemId,
    },
  );
  return entitlementResponse.data;
}

export function hasEntitlement(entitlements, gameSlug) {
  if (!entitlements || !entitlements.results) {
    return false;
  }
  const has = entitlements.results.find((entitlement) => {
    return entitlement.tag === `game_${gameSlug}`;
  });
  return has && has.id;
}
