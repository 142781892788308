import apiClient from "./apiClient";

export function trackViewEvent(isIframe, slug, content, fid) {
  apiClient.post(`${process.env.REACT_APP_API_URL}/api/tracking`, {
    slug,
    content,
    platform: isIframe ? "hub" : "web",
    type: "view",
    fid: fid ?? sessionStorage.getItem("fid"),
  });
}

export function trackClickEvent(isIframe, slug, content) {
  apiClient.post(`${process.env.REACT_APP_API_URL}/api/tracking`, {
    slug,
    content,
    platform: isIframe ? "hub" : "web",
    type: "click",
    fid: sessionStorage.getItem("fid"),
  });
}

export async function trackClickEventAsync(isIframe, slug, content) {
  await apiClient.post(`${process.env.REACT_APP_API_URL}/api/tracking`, {
    slug,
    content,
    platform: isIframe ? "hub" : "web",
    type: "click",
  });
}
