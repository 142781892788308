import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PurchasedGameCard = (props) => {
  const [isHover, setIsHover] = React.useState(false);
  const navigate = useNavigate();
  const downloadButtonRef = React.useRef(null);
  // // Open a selection dialog for directories

  useEffect(() => {
    let downloadingItemId = new URLSearchParams(window.location.search).get(
      "download"
    );

    if (downloadingItemId !== null) {
      const itemId = downloadingItemId;
      if (itemId === props.item_id) {
        if (downloadButtonRef.current) {
          downloadButtonRef.current.click();
        }
      }
    }
  }, [downloadButtonRef, props.item_id]);

  const CardImageHover = {
    width: "160px",
    height: "213px",
    borderRadius: "12px",
    backgroundColor: "rgb(255 255 255 / 10%)",
    cursor: "pointer",
  };

  const CardImageStyle = {
    width: "160px",
    height: "213px",
    borderRadius: "12px",
    backgroundImage: `url(${props.imageUri})`,
    backgroundSize: "cover",
    backgroundColor: "lightgray",
    backgroundPosition: "center",
    cursor: "pointer",
  };

  return (
    <div>
      <div
        style={CardContainerStyle}
        onClick={() => {
          if (props.type === "link") {
            if (props.link) {
              navigate(props.link);
            }
          }
        }}
        onMouseOver={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
      >
        <div
          style={CardImageStyle}
          onClick={() => {
            if (props.type === "download") {
              if (props.link) {
                navigate(props.link);
              }
            }
          }}
        >
          {isHover && <div style={CardImageHover} />}
        </div>
        <p style={CardNameStyle}>{props.name}</p>
      </div>
    </div>
  );
};

export default PurchasedGameCard;

const CardContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "160px",
  gap: "4px",
};

const CardNameStyle = {
  fontSize: "14px",
  fontWeight: "600",
  color: "var(--text-primary, #333)",
  margin: "0",
  display: "inline-block",
	whiteSpace: "nowrap", 
	width: "100%", 
	overflow: "hidden",
	textOverflow: "ellipsis",
};
