import styles from "./WishListPage.module.css";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  fetchWishlistStatus,
  toggleWishlistItem,
} from "../../features/wishlistSlice";
import { Button, Checkbox, message, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { postOrder, postMultipeItemsOrder } from "../../service/orderService";
import { navigate } from "../../features/userSlice";
import { pushAutoBaiduEvent } from "../../utils/baidu";
import { trackClickEventAsync } from "../../service/trackingService";
import { extractGameField, extractGameName } from "../../utils/i18n";
import { fetchGame } from "../../features/gameSlice";
import GameMap from "../../data/gameData";

const { confirm } = Modal;

const WishListPage = () => {
  const isIframe = self !== top; /* eslint-disable-line no-restricted-globals */
  const dispatch = useDispatch();
  const { items, status, error } = useSelector((state) => state.wishlist);
  const user = useSelector((state) => state.user.value);
  const { t, i18n } = useTranslation();
  const navigateReact = useNavigate();
  const [selectedItems, setSelectedItems] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);
  const games = useSelector((state) => state.game.games);

  const toggleItem = (slug) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(slug)
        ? prevSelectedItems.filter((itemSlug) => itemSlug !== slug)
        : [...prevSelectedItems, slug],
    );
  };
  const toggleSelectAll = () => {
    if (selectedItems.length === items.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(items.map((item) => item.slug));
    }
  };
  const isAllSelected = selectedItems.length === items.length;
  const isSomeSelected =
    selectedItems.length > 0 && selectedItems.length < items.length;

  const buySelected = () => {
    handleMultiplePurchase(selectedItems);
  };

  const removeSelected = () => {
    selectedItems.forEach((slug) => {
      dispatch(toggleWishlistItem({ itemSlug: slug }));
    });
    setSelectedItems([]);
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const language = queryParams.get("language");

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
      sessionStorage.setItem("language", language);
      console.log("i18n.changeLanguage(language):", language);
    }
  }, [language]);

  useEffect(() => {
    if (status === "idle" && user.id) {
      dispatch(fetchWishlistStatus());
    }
  }, [dispatch, user, status]);

  useEffect(() => {
    items.forEach((item) => {
      if (item && item.slug && !games[item.slug]) {
        dispatch(fetchGame(item.slug));
      }
    });
  }, [items]);

  const handleRemoveItem = (itemSlug) => {
    dispatch(toggleWishlistItem({ itemSlug }));
    setSelectedItems(selectedItems.filter((slug) => slug !== itemSlug));
  };

  const handleDivClick = (slug) => {
    toggleItem(slug);
  };

  const handleMultiplePurchase = async (gameSlugs) => {
    if (user.id) {
      try {
        gameSlugs.forEach(async (slug) => {
          await trackClickEventAsync(isIframe, "purchase", slug);
          pushAutoBaiduEvent(isIframe, { tagA: "purchase", tagB: slug });
        });
        const order = await postMultipeItemsOrder(gameSlugs);
        dispatch(
          navigate({
            url: `/order-confirm/${order.id}`,
          }),
        );
      } catch (e) {
        message.error(t("purchase_duplicate"));
      }
    }
  };

  const handlePurchase = async (gameSlug) => {
    if (user.id) {
      await trackClickEventAsync(isIframe, "purchase", gameSlug);
      pushAutoBaiduEvent(isIframe, { tagA: "purchase", tagB: gameSlug });
      const order = await postOrder(gameSlug);
      dispatch(
        navigate({
          url: `/order-confirm/${order.id}`,
        }),
      );
    }
  };

  const renderTags = (tags) => {
    return tags.map((tag, index) => {
      return (
        <div className={styles.wrapper}>
          <div className={styles.button}>{tag}</div>
        </div>
      );
    });
  };

  const showDeleteConfirm = (name, slug, isSelected = false) => {
    confirm({
      title: isSelected
        ? t("cancel_confrim_3")
        : t("cancel_confrim_1") + name + t("cancel_confrim_2"),
      okText: t("remove"),
      cancelText: t("cancel"),
      okType: "primary",
      okButtonProps: {
        style: { backgroundColor: "#FE9923" },
      },
      onOk() {
        isSelected ? removeSelected() : handleRemoveItem(slug);
      },
      onCancel() {},
    });
  };

  const renderWishItems = () => {
    return items.map((item, index) => {
      const gameData = GameMap[item.slug];
      const game = games[item.slug]?.data;
      if (!game && !gameData) {
        return null;
      }
      const gameName = extractGameName(gameData ?? game, i18n.language);
      return (
        <div
          key={item.id}
          onClick={() => handleDivClick(item.slug)}
          onMouseEnter={() => setHoveredItem(item.id)}
          onMouseLeave={() => setHoveredItem(null)}
          className={styles.wishListCard}
        >
          <div className={styles.checkBox1}>
            <Checkbox
              checked={selectedItems.includes(item.slug)}
              onClick={(e) => e.stopPropagation()}
              onChange={() => toggleItem(item.slug)}
            />
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.rectangleContainer}>
              <img
                style={{ cursor: "pointer" }}
                onClick={() => navigateReact(`/game/${game.slug}`)}
                className={styles.rectangleIcon}
                alt="cover-icon"
                src={
                  gameData
                    ? gameData.coverIcon
                    : game.latestVersion?.images?.landscapeIcon
                }
              />
              <div className={styles.group}>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => navigateReact(`/game/${game.slug}`)}
                  className={styles.div4}
                >
                  {gameName}
                </div>
                <div className={styles.div5}>
                  {t("publish_date")}：
                  {gameData ? gameData.releaseDate : game.firstReleaseDate}
                </div>
                <div className={styles.tag}>
                  {renderTags(
                    extractGameField(gameData ?? game, i18n.language, "tags"),
                  )}
                </div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.cachedParent}>
                <div className={styles.div10}>¥{item.originPrice}</div>
                <div className={styles.div11}>¥{item.price}</div>
              </div>
              <div className={styles.buttonsecondarydefault}>
                <div className={styles.button}>
                  <Button
                    style={{ color: "#fe9923" }}
                    type="secondary"
                    onClick={() => handlePurchase(game.slug)}
                  >
                    {t("purchase_now")}
                  </Button>
                </div>
              </div>
              {hoveredItem === item.id ? (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => showDeleteConfirm(gameName, game.slug)}
                  className={styles.closeIcon}
                >
                  {t("remove")}
                </div>
              ) : (
                <div style={{ width: "32px" }}></div>
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={styles.div}>
      <div className={styles.frameParent}>
        <div className={styles.parent}>
          <div className={styles.div1}>{t("my_wishlist")}</div>
          {selectedItems.length > 0 && (
            <div className={styles.buttonprimarydefaultParent}>
              <Button
                type="primary"
                disabled={selectedItems.length === 0}
                onClick={buySelected}
              >
                {t("purchase_now")}
              </Button>
              <Button
                disabled={selectedItems.length === 0}
                type="text"
                onClick={() => showDeleteConfirm("", "", true)}
                className={styles.button1}
              >
                {t("remove")}
              </Button>
            </div>
          )}
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.checkBoxWrapper}>
            <div className={styles.checkBox}>
              <Checkbox
                indeterminate={isSomeSelected}
                onChange={toggleSelectAll}
                checked={isAllSelected}
              />
              <div className={styles.button}>
                {t("selected")}（{selectedItems.length}）
              </div>
            </div>
          </div>
          {renderWishItems()}
        </div>
      </div>
    </div>
  );
};

export default WishListPage;
