import React from "react";
import ReactDOM from "react-dom";
import "./utils/i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./app/store";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import GamePage from "./components/Game/GamePage";
import Layout from "./components/Layouts/Layout";
import { ConfigProvider } from "antd";
import OrderConfirm from "./components/Purchase/OrderConfirm";
import OrderStatus from "./components/Purchase/OrderStatus";
import OrderList from "./components/Admin/Orders/OrderList";
import AdminLayout from "./components/Layouts/AdminLayout";
import UserList from "./components/Admin/Users/UserList";
import EntitlementList from "./components/Admin/Entitlements/EntitlementList";
import ItemList from "./components/Admin/Items/ItemList";
import CommentList from "./components/Admin/Comments/CommentList";
import Invoicement from "./components/Invoicement/Invoicement";
import HubCheckout from "./components/Purchase/HubCheckout";
import HomePage from "./components/Home/HomePage";
import WishListPage from "./components/Wishlist/WishListPage";
import DashboardList from "./components/Admin/Dashboard/DashboardList";
import GameDashboardList from "./components/Admin/Dashboard/GameDashboardList";
import DownloadHub from "./components/Purchase/DownloadHub";
import AffiliateRouteMiddleware from "./contexts/affliateMiddleware";
import SupplyOrderPage from "./components/Purchase/SupplyOrderPage";
import PublisherLayout from "./components/Layouts/PublisherLayout";
import PromotionList from "./components/Admin/Promotions/PromotionList";
import UserPage from "./components/User/UserPage";

const router = createBrowserRouter([
  {
    element: (
      <AffiliateRouteMiddleware>
        <Layout />
      </AffiliateRouteMiddleware>
    ),
    children: [
      {
        path: "/public",
        element: <Navigate replace to="/" />,
      },
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/wuyu",
        element: <GamePage />,
      },
      {
        path: "/game/:slug",
        element: <GamePage />,
      },
      {
        path: "/order-confirm/:id",
        element: <OrderConfirm />,
      },
      {
        path: "/order-confirm/:id/status",
        element: <OrderStatus />,
      },
      {
        path: "/hub-checkout",
        element: <HubCheckout />,
      },
      {
        path: "/wishlist",
        element: <WishListPage />,
      },
      {
        path: "/download",
        element: <DownloadHub />,
      },
      {
        path: "/supply-order/:id",
        element: <SupplyOrderPage />
      },
      {
        path: "/user",
        element: <UserPage />
      }
    ],
  },
  {
    path: "/order/invoicement",
    element: <Invoicement />,
  },
  {
    element: <PublisherLayout />,
    children: [
      {
        path: "/publisher/:publisherId",
        element: <ItemList/>,
      },
      {
        path: "/publisher/:publisherId/items",
        element: <ItemList />,
      },
      {
        path: "/publisher/:publisherId/orders",
        element: <OrderList />,
      },
      {
        path: "/publisher/:publisherId/gameDashboard",
        element: <GameDashboardList />,
      },
    ],
  },
  {
    element: <AdminLayout />,
    children: [
      {
        path: "/admin",
        element: <Navigate replace to="/admin/orders" />,
      },
      {
        path: "/admin/orders",
        element: <OrderList />,
      },
      {
        path: "/admin/users",
        element: <UserList />,
      },
      {
        path: "/admin/items",
        element: <ItemList />,
      },
      {
        path: "/admin/promotions",
        element: <PromotionList />,
      },
      {
        path: "/admin/entitlements",
        element: <EntitlementList />,
      },
      {
        path: "/admin/comments",
        element: <CommentList />,
      },
      {
        path: "/admin/dashboard",
        element: <DashboardList />,
      },
      {
        path: "/admin/gameDashboard",
        element: <GameDashboardList />,
      },
    ],
  },
]);

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: "#FE9923",
          },
        }}
      >
        <RouterProvider router={router} />
      </ConfigProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root"),
);

reportWebVitals();
