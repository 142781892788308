import apiClient from "./apiClient";

export async function getOrderById(id, nonHosted, publisherId = '') {
  const orderResponse = await apiClient.get(
    `${process.env.REACT_APP_API_URL}/api/order/${id}?publisherId=${publisherId}&nh=${nonHosted ? "true" : "false"}`,
  );
  return orderResponse.data;
}

export async function postOrder(itemSlug) {
  const fid = sessionStorage.getItem("fid");
  const orderResponse = await apiClient.post(
    `${process.env.REACT_APP_API_URL}/api/order/${itemSlug}?fid=${fid}`,
    {},
  );
  return orderResponse.data;
}

export async function postMultipeItemsOrder(itemSlugs) {
  const fid = sessionStorage.getItem("fid");
  const orderResponse = await apiClient.post(
    `${process.env.REACT_APP_API_URL}/api/order`,
    {
      slugs: itemSlugs,
      fid: fid,
    },
  );
  return orderResponse.data;
}

export async function prePay(orderId, paymethod) {
  const prePayResponse = await apiClient.post(
    `${process.env.REACT_APP_API_URL}/api/payment/${paymethod}/prepay/web`,
    {
      outTradeNo: orderId,
    },
  );
  return prePayResponse.data;
}

export async function unifiedPay(orderId, paymethod) {
  const prePayResponse = await apiClient.post(
    `${process.env.REACT_APP_API_URL}/api/payment/${paymethod}/prepay/web?nh=true`,
    {
      outTradeNo: orderId,
    },
  );
  return prePayResponse.data;
}

export async function orderList(pageSettings, statusList, publisherId = '') {
  const statusListString = statusList.join(",");
  const orderResponse = await apiClient.get(
    `${process.env.REACT_APP_API_URL}/api/order/list?start=${pageSettings[0]}&count=${pageSettings[1]}&statusList=${statusListString}&publisherId=${publisherId}`,
  );
  return orderResponse.data;
}

export async function postRevokeEntitlements(orderId) {
  const revokeResponse = await apiClient.delete(
    `${process.env.REACT_APP_API_URL}/api/order/${orderId}/entitlements`,
    {},
  );
  return revokeResponse.data;
}

export async function postRefund(orderId, needRevokeEntitlements) {
  const refundResponse = await apiClient.post(
    `${process.env.REACT_APP_API_URL}/api/order/refund/${orderId}?needRevokeEntitlements=${needRevokeEntitlements}`,
    {},
  );
  return refundResponse.data;
}

export async function getOrderInvoicement(orderId) {
  const invoicementResponse = await apiClient.get(
    `${process.env.REACT_APP_API_URL}/api/order/${orderId}/invoicement`,
  );
  return invoicementResponse.data;
}
