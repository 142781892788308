import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userInfo } from "../../service/userService";
import { Modal, Input, Button, message } from "antd";
import styles from "./UserPage.module.css";
import { CloseOutlined } from "@ant-design/icons";
import { sendCodeCall, VerifyPhoneCode } from "../../service/authService";
import { useTranslation } from "react-i18next";
import {
  hideMobileVerifyModal,
  showEmailVerifyModal,
  showMobileResetModal,
  showEmailResetModal,
  showPasswordResetModal,
} from "../../features/userSlice";

const MobileVerifyModal = ({user}) => {
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [countDown, setCountDown] = useState(0);
  const [firstCount, setFirstCount] = useState(true);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const codeInput = useRef(null);
  const [hintVisible, setHintVisible] = useState(false);
  const isMobileVerifyModalVisible = useSelector(
    (state) => state.user.isMobileVerifyModalVisible
  );
  const verifyTitle = useSelector((state) => state.user.verifyTitle);

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => setCountDown(countDown - 1), 1000);
    }
  }, [countDown]);

  const sendCode = async () => {
    // pushAutoBaiduEvent(isIframe, {tagA: "login_send_code", tagB: phone});
    // Call API to send code
    try {
      await sendCodeCall(phone);
      setCountDown(60);
      setFirstCount(false);
      message.success({
        key: "send_code_success",
        content: t("send_code_success"),
        duration: 5,
      });
    } catch (e) {
      console.log(e);
      message.error({
        key: "send_code_error",
        content: t("send_code_error"),
        duration: 5,
      });
    }
  };

  const onCode = (e) => {
    const { value: inputValue } = e.target;
    const reg = /^\d*(\d*)?$/;
    if (reg.test(inputValue) || inputValue === "") {
      setCode(inputValue);
    }
  };

  const handleVerifyCode = async () => {
    try {
      // Call API to verify code
      await VerifyPhoneCode(phone, code);
      message.success({
        key: "verify_code_success",
        content: t("verify_code_success"),
        duration: 5,
      });
      dispatch(hideMobileVerifyModal());
      setCode("");
      if (verifyTitle === "password") {
        dispatch(showPasswordResetModal());
      } else if (verifyTitle === "email") {
        dispatch(showEmailResetModal());
      } else {
        dispatch(showMobileResetModal());
      }
    } catch (e) {
      console.log(e);
      message.error({
        key: "verify_code_error",
        content: t("verify_code_error"),
        duration: 5,
      });
    }
  };

  const handleChangeVerifyWay = () => {
    dispatch(showEmailVerifyModal(verifyTitle));
    dispatch(hideMobileVerifyModal());
    setCode("");
  };

  var modalTitle = "";
  if (verifyTitle === "password") {
    modalTitle = user.password === "" ? "new_password_title" : "reset_password_title";
  } else if (verifyTitle === "phone") {
    modalTitle = user.mobile === "" ? "new_phone_title" : "reset_phone_title";
  } else {
    modalTitle = user.email === "" ? "new_email_title" : "reset_email_title";
  }

  return (
    <Modal
      title={t(modalTitle)}
      open={isMobileVerifyModalVisible}
      onCancel={() => {
        dispatch(hideMobileVerifyModal());
        setCode("");
      }}
      footer={null}
    >
      <div style={{ color: "#666", marginBottom: "16px" }}>
        {t("verify_hint")}
      </div>
      <div>
        使用手机号 +86 {user.mobile }验证
      </div>
      <div className={styles.row2}>
        <Input
          // onClick={() => pushAutoBaiduEvent(isIframe, {tagA: "login_code_enter", tagB: phone})}
          ref={codeInput}
          className={styles.codeInput}
          allowClear={{
            clearIcon: <CloseOutlined className={styles.clear} />,
          }}
          style={{ backgroundColor: "#efefef" }}
          bordered={false}
          maxLength={6}
          placeholder={t("code_hint")}
          value={code}
          onChange={onCode}
        />
        <Button
          className={styles.codeBtn}
          onClick={sendCode}
          disabled={phone !== "" && countDown}
        >
          {countDown ? countDown + "s" : (firstCount ?  t("code_input") : t("code_input_again"))}
        </Button>
      </div>
      <Button
            className={styles.loginBtn}
            type="primary"
            onClick={handleVerifyCode}
            disabled={code.length !== 6}
          >
            {t("verify")}
          </Button>
      <div className={styles.row3}>
        {user.email !== "" &&
        <div 
          style={{ marginRight: "4px" }}
          onClick={handleChangeVerifyWay}>
          使用邮箱验证
        </div>}
        <div
          onClick={() => setHintVisible(true)}
          >
          需要帮助？
        </div>
      </div>
      {hintVisible &&
      <div className={styles.row4}>
        <div>
          验证身份遇到问题？您可以发送邮件到 
        </div>
        <div style={{ color: "#3354FF"}}>
          support@u4ugames.com 
        </div>
        <div>
          联系管理员
        </div>
      </div>}
    </Modal>
  );
};

export default MobileVerifyModal;
