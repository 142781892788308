import styles from "./GamePage.module.css";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { PlayCircleFilled } from "@ant-design/icons";

import "./Gallery.css";

const Gallery = ({ gameData, gameItem, onImgLoaded }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [mainLoadedCount, setMainLoadedCount] = useState(0);
  const [subLoadedCount, setSubLoadedCount] = useState(0);
  const focusRef = useRef(null);

  let images = gameData?.images;
  if (!gameData && gameItem) {
    images = gameItem.latestVersion.gallery.map((url) => {
      return {
        Icon: url,
        Main: url,
      };
    });
    if (gameItem.latestVersion.bilibili) {
      images.unshift({
        Icon: gameItem.latestVersion.gallery[0],
        Main: gameItem.latestVersion.bilibili,
        Type: "bilibili",
      });
    }
  }

  useEffect(() => {
    if (
      mainLoadedCount > images?.length - 2 &&
      subLoadedCount > images?.length - 2
    ) {
      onImgLoaded();
    }
  }, [images?.length, mainLoadedCount, onImgLoaded, subLoadedCount]);

  if (!gameItem && !gameData) {
    return <div />;
  }

  const bilibiliVideo = (url) => {
    return (
      <iframe
        src={url}
        onLoad={onMainLOaded}
        width="100%"
        height="100%"
        scrolling="no"
        border="0"
        frameBorder="no"
        framespacing="0"
        allowFullScreen="true"
      ></iframe>
    );
  };

  const onMainLOaded = () => {
    setMainLoadedCount(mainLoadedCount + 1);
  };

  return (
    <div className={styles.div57}>
      <div ref={focusRef} tabIndex="-1">
        <Swiper
          // style={{
          // '--swiper-navigation-color': '#fff',
          // '--swiper-pagination-color': '#fff',
          // }}
          loop={true}
          spaceBetween={10}
          // navigation={true}
          navigation={{
            nextEl: ".btn-next",
            prevEl: ".btn-prev",
          }}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper2"
        >
          <SwiperSlide>
            {images[0].Type === "bilibili" ? (
              bilibiliVideo(images[0].Main)
            ) : (
              <img src={images[0].Main} alt="slide-img" onLoad={onMainLOaded} />
            )}
          </SwiperSlide>
          {mainLoadedCount > 0 &&
            images
              .slice(1)
              .map((image) => (
                <SwiperSlide>
                  {image.Type === "bilibili" ? (
                    bilibiliVideo(image.Main)
                  ) : (
                    <img
                      src={image.Main}
                      alt="slide-img"
                      onLoad={onMainLOaded}
                    />
                  )}
                </SwiperSlide>
              ))}
        </Swiper>
      </div>
      <div className={"mySwiper-wrap"}>
        <div className={"btn-prev"}>
          <LeftOutlined className={styles.frameIcon} />
        </div>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={16}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper"
        >
          {images.map((image) => (
            <SwiperSlide>
              <img
                src={image.Icon}
                alt="slide-img"
                onLoad={() => {
                  setSubLoadedCount(subLoadedCount + 1);
                }}
              />
              {image.Type === "bilibili" && (
                <PlayCircleFilled className="play-icon" />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={"btn-next"}>
          <RightOutlined className={styles.frameIcon} />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
