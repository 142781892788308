import { showLoginPopup } from "../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { getOrderById, unifiedPay } from "../../service/orderService";
import { Button, message, QRCode, Modal } from "antd";
import styles from "./OrderConfirm.module.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SafetyCertificateFilled } from "@ant-design/icons";
import { pushAutoBaiduEvent } from "../../utils/baidu";
import { trackClickEvent } from "../../service/trackingService";

const payMeta = {
  pingpong: {
    name: "WeChat Pay",
  },
  alipay: {
    name: "Alipay",
  },
};

const { confirm } = Modal;

const NonHostedCheckoutButton = ({ payMethod, orderId }) => {
  const isIframe = self !== top; /* eslint-disable-line no-restricted-globals */
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [polling, setPolling] = useState(null);
  const [pollingCount, setPollingCount] = useState(0);
  const [orderAmount, setOrderAmount] = useState("");
  const maxPollingAttempts = 200;

  useEffect(() => {
    return () => {
      if (polling) {
        clearInterval(polling);
      }
    };
  }, [polling]);

  const checkPaymentStatus = async () => {
    if (pollingCount >= maxPollingAttempts) {
      clearInterval(polling);
      setIsModalVisible(false);
      window.location.href = `/order-confirm/${orderId}/status`;
    }
    try {
      const response = await getOrderById(orderId, true);
      if (response?.status === "finished") {
        clearInterval(polling);
        setIsModalVisible(false);
        if (isIframe) {
          window.parent.postMessage(
            "playgameid_" + response.orderItems[0]?.itemId,
            "*",
          );
        } else {
          window.location.href = `/order-confirm/${orderId}/status`;
        }
      }
    } catch (error) {
      console.error("Error checking payment status:", error);
    }
    setPollingCount(pollingCount + 1);
  };

  const cancelPay = () => {
    confirm({
      title: t("cancel_order_confirm"),
      okText: t("pay_cancel"),
      cancelText: t("cancel"),
      okType: "primary",
      okButtonProps: {
        style: { backgroundColor: "#FE9923" },
      },
      async onOk() {
        trackClickEvent(isIframe, "cancel_pay", orderId);
        pushAutoBaiduEvent(isIframe, { tagA: "cancel_pay", tagB: orderId });
        setIsModalVisible(false);
        clearInterval(polling);
        setIsLoading(false);
      },
    });
  };

  const handlePurchase = async () => {
    setIsLoading(true);
    trackClickEvent(isIframe, "checkout", orderId);
    pushAutoBaiduEvent(isIframe, { tagA: "checkout", tagB: orderId });
    if (!user || !user.id) {
      dispatch(showLoginPopup());
      setIsLoading(false);
      return;
    }

    try {
      const unifiedPayResponse = await unifiedPay(orderId, payMethod);
      if (unifiedPayResponse && unifiedPayResponse.action?.qrCode) {
        setOrderAmount(unifiedPayResponse.amount);
        setQrCode(unifiedPayResponse.action?.qrCode);
        setIsModalVisible(true);
        const intervalId = setInterval(checkPaymentStatus, 5000);
        setPolling(intervalId);
        setPollingCount(0);
      } else {
        messageApi.open({
          type: "error",
          content: t("purchase_fail"),
          duration: 5,
        });
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);

      const statusCode = e?.response?.status;
      if (statusCode === 404) {
        messageApi.open({
          type: "error",
          content: t("purchase_duplicate"),
          duration: 5,
        });
      } else {
        messageApi.open({
          type: "error",
          content: t("purchase_fail"),
          duration: 5,
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      {isLoading ? (
        <Button type="primary" disabled className={styles.buttonprimarylarge}>
          {t("redirecting")}...
        </Button>
      ) : (
        <Button
          type="primary"
          className={styles.buttonprimarylarge}
          onClick={handlePurchase}
        >
          {t("checkout")}
        </Button>
      )}
      <Modal
        visible={isModalVisible}
        onCancel={cancelPay}
        footer={null}
        centered
        width={480}
        title={t("pay_title")}
        wrapClassName={styles.paymentModalWrap}
      >
        <div className={styles.paymentModalContent}>
          <div>
            <SafetyCertificateFilled style={{ color: "#5EC968" }} />
            {t("pay_secure")}
          </div>
          <div className={styles.paymentModalSection}>
            <div className={styles.paymentInstruction}>
              {t("pay_hint_1")}
              {payMeta[payMethod]?.name}
              {t("pay_hint_2")}
            </div>
            <div className={styles.paymentAmount}>
              ¥{parseFloat(orderAmount).toFixed(2)}
            </div>
            <div className={styles.paymentOrder}>
              {t("pay_order")}：{orderId}
            </div>
            <QRCode value={qrCode} size={200} />
            <Button className={styles.paymentCancel} onClick={cancelPay}>
              {t("pay_cancel")}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NonHostedCheckoutButton;
