import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import "./WishlistButton.css";
import {
  fetchWishlistStatus,
  toggleWishlistItem,
} from "../../features/wishlistSlice";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { pushAutoBaiduEvent } from "../../utils/baidu";

const WishlistButton = ({ itemSlug }) => {
  const isIframe = self !== top; /* eslint-disable-line no-restricted-globals */
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { items, status } = useSelector((state) => state.wishlist);
  const user = useSelector((state) => state.user.value);

  const isWished = items.some((item) => item?.slug === itemSlug);

  useEffect(() => {
    if (status === "idle" && user.id) {
      dispatch(fetchWishlistStatus());
    }
  }, [dispatch, status, user]);

  const handleClick = () => {
    // trackClickEvent(isIframe, "wishlist", itemSlug);
    pushAutoBaiduEvent(isIframe, { tagA: "wishlist", tagB: itemSlug });
    dispatch(toggleWishlistItem({ isIframe, itemSlug }));
  };

  if (!user || !user.id) {
    return;
  }

  // 根据是否在心愿单中来选择图标
  const HeartIcon = isWished ? HeartFilled : HeartOutlined;

  return (
    <Tooltip
      placement="top"
      overlayInnerStyle={{
        backgroundColor: "#f5f5f5",
        color: "#666",
        padding: "4px 16px",
      }}
      title={!isWished ? t("wishlist_btn_hint1") : t("wishlist_btn_hint2")}
      arrow={false}
    >
      <HeartIcon
        className={`wishlist-button ${isWished ? "wished" : ""}`}
        onClick={handleClick}
      />
    </Tooltip>
  );
};

export default WishlistButton;
