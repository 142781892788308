import React, { useContext } from "react";
import { Menu, Dropdown, Button, Divider } from "antd";
import { useSelector } from "react-redux";
import { showLoginPopup } from "../../features/userSlice";
import { useDispatch } from "react-redux";
import LoginPopup from "../Popup/LoginPopup";
import { handleOpenHub } from "../../utils/openHub";
import styles from "./AppHeader.module.css";
import userIcon from "../../assets/icon/user.svg";
import logoIcon from "../../assets/icon/logoWhite.svg";
import wishlistIcon from "../../assets/icon/wishlist.svg";
import { AvatarMap } from "../../data/gameData";
import { useTranslation } from "react-i18next";
import {
  LogoutOutlined,
  LoginOutlined,
  UserOutlined,
  DashboardOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import siteTitleHantIcon from "../../assets/icon/siteTitleHant.svg";
import { useParams, useNavigate } from "react-router-dom";
import { BlurContext } from "../../contexts/blurContext";
import * as baidu from "../../utils/baidu";
import { pushAutoBaiduEvent } from "../../utils/baidu";
import { trackClickEvent } from "../../service/trackingService";

const AppHeader = () => {
  const isIframe = self !== top; /* eslint-disable-line no-restricted-globals */
  const user = useSelector((state) => state.user.value);
  const { isBlurred } = useContext(BlurContext);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isOrderConfirmPage =
    window.location.pathname.startsWith("/order-confirm");
  const { slug } = useParams();
  const gameItem = useSelector((state) => state.game.games[slug]);

  const changeLanguage = () => {
    if (i18n.language === "zhHant") {
      i18n.changeLanguage("zh");
      trackClickEvent(isIframe, "i18n", "zh");
      sessionStorage.setItem("language", "zh");
    } else {
      i18n.changeLanguage("zhHant");
      trackClickEvent(isIframe, "i18n", "zhHant");
      sessionStorage.setItem("language", "zhHant");
    }
  };

  const toLanguageLabel = (language) => {
    if (language === "zhHant") {
      return "繁體中文";
    } else {
      return "简体中文";
    }
  };

  const menu = !user.name ? (
    <Menu>
      <Menu.Item>
        <div
          onClick={() => {
            trackClickEvent(isIframe, "login", "header");
            pushAutoBaiduEvent(isIframe, { tagA: "login", tagB: "header" });
            dispatch(showLoginPopup());
          }}
        >
          <LoginOutlined className={styles.menuIcon} />
          {t("login_title")}
        </div>
      </Menu.Item>
    </Menu>
  ) : (
    <Menu>
      <Menu.Item>
        <div>
          {user.name}
        </div>
      </Menu.Item>
      <Menu.Item>
        <div
        onClick={() => {
          window.location.href = "/user";
        }}>
          <UserOutlined className={styles.menuIcon} />
          个人中心
        </div>
      </Menu.Item>
      {user.roles?.includes("admin") && (
        <Menu.Item>
          <div
            onClick={() => {
              window.location.href = "/admin";
            }}
          >
            <DashboardOutlined className={styles.menuIcon} />
            管理后台
          </div>
        </Menu.Item>
      )}
      {user.roles?.includes("publisher") && (
          <Menu.Item>
            <div
                onClick={() => {
                  window.location.href = `/publisher/${user.publisherId}`
                }}
            >
              <DashboardOutlined className={styles.menuIcon} />
              开发者平台
            </div>
          </Menu.Item>
      )}
      <Menu.Item disabled={isOrderConfirmPage}>
        <div
          onClick={() => {
            if (!isOrderConfirmPage) {
              baidu.clearUserId();
              localStorage.removeItem("token");
              window.location.href = "/";
            }
          }}
        >
          <LogoutOutlined className={styles.menuIcon} />
          {t("logout_title")}
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className={styles.header}
      style={{ filter: isBlurred ? "blur(15px)" : "unset" }}
    >
      <div className={styles.web1}>
        <div
          className={styles.uParent}
          onClick={() => {
            window.location.href = "/";
            if (gameItem && slug) {
              trackClickEvent(isIframe, "home", slug);
            }
          }}
        >
          {/*<div className={styles.button}>U</div>*/}
          <img className={styles.button} src={logoIcon} alt="icon" />
          <div
            style={{
              marginLeft: "2px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {i18n.language === "zhHant" ? (
              <img
                src={siteTitleHantIcon}
                alt="sale-icon"
                style={{
                  width: "96px",
                  marginTop: "7px",
                  marginBottom: "10px",
                }}
              />
            ) : (
              <div className={styles.div25}>{t("site_name")}</div>
            )}
            <div className={styles.div26}>Uatut Games</div>
          </div>
        </div>
        <div className={styles.headerRight}>
          <div
            style={{ marginRight: "24px", cursor: "pointer" }}
            onClick={() => changeLanguage()}
          >
            {toLanguageLabel(i18n.language)}
            <SyncOutlined style={{ marginLeft: "5px" }} />
          </div>
          <Button
            type="primary"
            className={styles.buttonsecondarydefault}
            onClick={() => {
              trackClickEvent(isIframe, "open_hub", "header");
              pushAutoBaiduEvent(isIframe, {
                tagA: "open_hub",
                tagB: "header",
              });
              handleOpenHub();
            }}
          >
            {t("download")}
          </Button>
          {user.name && (
            <Divider type="vertical" className={styles.heartDivider} />
          )}
          {user.name && (
            <div className={styles.heartWrapper}>
              <div className={styles.heartSection}>
                <img src={wishlistIcon} alt={"wishlist"} />
                <div
                  style={{ marginLeft: "4px", wordBreak: "keep-all" }}
                  onClick={() => navigate("/wishlist")}
                >
                  {t("wishlist")}
                </div>
              </div>
            </div>
          )}
          <Dropdown placement="bottomRight" overlay={menu}>
            <div className={styles.webFrame245}>
              <div className={styles.webFrame245Child}>
                {user.name ? (
                  <img
                    className={styles.iconLoginUser}
                    src={AvatarMap[user.mobile % 10]}
                    alt="avatar"
                  />
                ) : (
                  <img
                    className={styles.iconUser}
                    src={userIcon}
                    alt="avatar"
                  />
                )}
              </div>
            </div>
          </Dropdown>
        </div>
      </div>
      <LoginPopup />
    </div>
  );
};

export default AppHeader;
