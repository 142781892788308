import axios from "axios";
import apiClient from "./apiClient";
import md5 from "js-md5";

export async function loginUserByCode(input, code) {
  var response;
  if (input.indexOf("@") != -1) {
    // login user by email
    console.log("login user by email", input);
    response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/email/login`,
      {
        email: input,
        code,
        fid: sessionStorage.getItem("fid"),
      },
    );
  } else {
    // login user by phone
    console.log("login user by phone", input);
    response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/phone/login`,
      {
        phone: input,
        code,
        fid: sessionStorage.getItem("fid"),
      },
    );
  }

  const { data } = response;
  localStorage.setItem("token", data.access_token);
  const headers = {
    Authorization: `${data.access_token}`,
  };
  headers["X-Token-Type"] = "user";
  const userResponse = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/user/me`,
    { headers },
  );
  return userResponse.data;
}

export async function loginUserByPassword(input, password) {
  var response;
  if (input.indexOf("@") != -1) {
    // login user by email
    console.log("login user by email", input);
    response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/email/login`,
      {
        email: input,
        password: md5(password),
        fid: sessionStorage.getItem("fid"),
      },
    );
  } else {
    // login user by phone
    console.log("login user by phone", input);
    response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/phone/login`,
      {
        phone: input,
        password: md5(password),
        fid: sessionStorage.getItem("fid"),
      },
    );
  }

  const { data } = response;
  localStorage.setItem("token", data.access_token);
  const headers = {
    Authorization: `${data.access_token}`,
  };
  headers["X-Token-Type"] = "user";
  const userResponse = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/user/me`,
    { headers },
  );
  return userResponse.data;
}

export async function currentUser() {
  const userResponse = await apiClient.get(
    `${process.env.REACT_APP_API_URL}/api/user/me`,
  );
  return userResponse.data;
}

export async function sendCodeCall(phone) {
  const { data } = axios.get(
    `${process.env.REACT_APP_API_URL}/auth/phone/verify?phoneNumber=${phone}`,
  );
  return data;
}

export async function VerifyPhoneCode(phone, code) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/auth/phone/verify`,
    {
      phone,
      code,
      fid: sessionStorage.getItem('fid'),
    },
  );
  return response;
}

export async function sendEmailCodeCall(email) {
  const { data } = axios.get(
    `${process.env.REACT_APP_API_URL}/auth/email/verify?email=${email}`,
  );
  return data;
}

export async function VerifyEmailCode(email, code) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/auth/email/verify`,
    {
      email,
      code,
      fid: sessionStorage.getItem('fid'),
    },
  );
  return response;
}