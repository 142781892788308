import React, { useEffect, useState } from "react";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import dayjs from "dayjs";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  DatePicker,
} from "antd";
import "react-markdown-editor-lite/lib/index.css";
import {
  createPromotion,
  putPromotion,
} from "../../../service/promotionService";
import { gameList } from "../../../service/gameService";
const { RangePicker } = DatePicker;
const { Option } = Select;
dayjs.extend(weekday);
dayjs.extend(localeData);

const PromotionModal = ({ record }) => {
  const [visible, setVisible] = useState(false);
  const [games, setGames] = useState([]);

  useEffect(() => {
    const fetchGames = async () => {
      const data = await gameList(true, "", "");
      setGames(data.results);
    };
    fetchGames();
  }, []);

  const initialValues = {
    name: record?.name,
    status: record?.status || "inactive",
    type: record?.type || "item",
    benefitValue: record?.benefit.amount || 0,
    benefitType: record?.benefit.type || "percent",
    minAmount: record?.criteria.minAmount || 0,
    includes: record?.criteria.includeItemIds || [],
    excludes: record?.criteria.excludeItemIds || [],
    timePicker: record
      ? [
          dayjs(record?.startTime?.replace("Z", ".000Z")),
          dayjs(record?.endTime?.replace("Z", ".000Z")),
        ]
      : [],
  };

  const showModal = () => setVisible(true);
  const handleOk = async (values) => {
    const promotion = {
      id: record?.id || "",
      name: values["name"],
      type: values["type"],
      status: values["status"],
      benefit: {
        amount: values["benefitValue"],
        type: values["benefitType"],
      },
      criteria: {
        minAmount: values["minAmount"],
        includeItemIds: values["includes"],
        excludeItemIds: values["excludes"],
      },
      startTime: values.timePicker[0].format().split("+")[0] + "Z",
      endTime: values.timePicker[1].format().split("+")[0] + "Z",
    };
    console.log(values);
    console.log(promotion);
    if (record) {
      await putPromotion(record.id, promotion);
    } else {
      await createPromotion(promotion);
    }
    setVisible(false);
    window.location.reload();
  };
  const handleCancel = () => setVisible(false);

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {record ? "更新促销" : "创建促销"}
      </Button>
      <Modal
        title={record ? "更新促销" : "创建促销"}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form onFinish={handleOk} initialValues={initialValues}>
          <Form.Item
            label="促销名"
            name="name"
            rules={[{ required: true, message: "请输入促销名!" }]}
          >
            <Input placeholder="促销名" />
          </Form.Item>
          <Form.Item
            name="timePicker"
            rules={[{ required: true, message: "活动时间!" }]}
            label="活动时间(UTC)"
          >
            <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
          <Form.Item
            name="status"
            rules={[{ required: true, message: "状态！" }]}
            label="状态"
          >
            <Select>
              <Option value="active">ACTIVE</Option>
              <Option value="inactive">INACTIVE</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="type"
            rules={[{ required: true, message: "促销类型！" }]}
            label="促销类型"
          >
            <Select>
              <Option value="item">ITEM</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="benefitType"
            rules={[{ required: true, message: "折扣类型！" }]}
            label="折扣类型"
          >
            <Select>
              <Option value="percent">打折</Option>
              <Option value="flat">直减</Option>
              <Option value="fixed">固定价格</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="折扣力度"
            name="benefitValue"
            rules={[{ required: true, message: "折扣力度！" }]}
          >
            <InputNumber placeholder="50" />
          </Form.Item>
          <Form.Item name="excludes" label="排除游戏">
            <Select mode="multiple">
              {games.map((g) => (
                <Option value={g.id}>{g.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="includes" label="包含游戏">
            <Select mode="multiple">
              {games.map((g) => (
                <Option value={g.id}>{g.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="符合条件最小金额" name="minAmount">
            <InputNumber placeholder="0" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PromotionModal;
