import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Upload,
  Button,
  InputNumber,
  Select,
  Switch,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import MarkdownIt from "markdown-it";
import {
  convertSimplifiedToTraditional,
  convertSimplifiedArrayToTraditional,
} from "../../../utils/i18n";
import { createGame, putGame, uploadImg } from "../../../service/gameService";
import { getAuthHeaders } from "../../../service/apiClient";

const mdParser = new MarkdownIt(/* Markdown-it options */);
const { Option } = Select;

const ItemEditModal = ({ record }) => {
  const convertUrlToFileList = (url, uid) => {
    if (!url) {
      return [];
    }
    return [
      {
        uid: uid,
        name: url.substring(url.lastIndexOf("/") + 1),
        status: "done",
        url: url,
      },
    ];
  };

  const extractBilibiliBvid = (url) => {
    const match = url?.match(/bvid=([^&]+)/);
    return match ? match[1] : "";
  };

  const latestVersion = record?.latestVersion;
  const landscapeInitialUrl =
    record?.latestVersion?.images?.landscapeIcon || "";
  const portraitInitialUrl = record?.latestVersion?.images?.portraitIcon || "";
  const sideRecommendInitialUrl =
    record?.latestVersion?.images?.sideRecommendIcon || "";
  const galleryInitialUrls = record?.latestVersion?.gallery || [];

  const [type, setType] = useState(record?.type || "standalone");
  const [visible, setVisible] = useState(false);
  const [markDown, setMarkDown] = useState(
    record?.latestVersion?.markdown || "",
  );
  const [landscapeFileList, setLandscapeFileList] = useState(
    convertUrlToFileList(latestVersion?.images?.landscapeIcon, "-1"),
  );
  const [portraitFileList, setPortraitFileList] = useState(
    convertUrlToFileList(latestVersion?.images?.portraitIcon, "-2"),
  );
  const [sideRecommendFileList, setSideRecommendFileList] = useState(
    convertUrlToFileList(latestVersion?.images?.sideRecommendIcon, "-3"),
  );
  const [galleryFileList, setGalleryFileList] = useState(
    latestVersion?.gallery
      ? latestVersion.gallery.map(
          (url, index) => convertUrlToFileList(url, `gallery-${index}`)[0],
        )
      : [],
  );
  const [landscapeFileUrl, setLandscapeFileUrl] = useState(landscapeInitialUrl);
  const [portraitFileUrl, setPortraitFileUrl] = useState(portraitInitialUrl);
  const [sideRecommendFileUrl, setSideRecommendFileUrl] = useState(
    sideRecommendInitialUrl,
  );
  const [galleryUrls, setGalleryUrls] = useState(galleryInitialUrls);

  const initialValues = {
    zhName: record?.name,
    enName: record?.latestVersion?.nameEn,
    slug: record?.slug,
    developer: record?.developer,
    publisher: record?.publisher,
    originPrice: record?.originPrice,
    price: record?.price,
    zhDescription: record?.latestVersion?.description,
    tags: record?.latestVersion?.tags || [],
    firstReleaseDate: record?.firstReleaseDate,
    steam: record?.latestVersion?.statistics?.steam,
    ign: record?.latestVersion?.statistics?.ign,
    heihe: record?.latestVersion?.statistics?.heihe,
    online: record?.latestVersion?.statistics?.online,
    lowOS: record?.latestVersion?.lowConfiguration?.os,
    lowCPU: record?.latestVersion?.lowConfiguration?.processor,
    lowMemory: record?.latestVersion?.lowConfiguration?.ram,
    lowGPU: record?.latestVersion?.lowConfiguration?.graphic,
    lowDisk: record?.latestVersion?.lowConfiguration?.disk,
    highOS: record?.latestVersion?.highConfiguration?.os,
    highCPU: record?.latestVersion?.highConfiguration?.processor,
    highMemory: record?.latestVersion?.highConfiguration?.ram,
    highGPU: record?.latestVersion?.highConfiguration?.graphic,
    highDisk: record?.latestVersion?.highConfiguration?.disk,
    bilibili: extractBilibiliBvid(record?.latestVersion?.bilibili),
    status: record?.status || "TESTING",
    isAdultGame: record?.latestVersion?.isAdultGame || false,
    type: record?.type || "standalone",
    standaloneId: record?.standaloneId || "",
  };

  const showModal = () => setVisible(true);
  const handleOk = async (values) => {
    const game = {
      name: values["zhName"],
      slug: values["slug"],
      description: values["zhDescription"],
      developer: values["developer"],
      publisher: values["publisher"],
      nameEn: values["enName"],
      price: values["price"],
      originPrice: values["originPrice"],
      type: values["type"],
      status: values["status"],
      isAdultGame: values["isAdultGame"],
      firstReleaseDate: values["firstReleaseDate"],
      standaloneId: values["standaloneId"],
      intel: {
        zhHant: {
          name: convertSimplifiedToTraditional(values["zhName"]),
          description: convertSimplifiedToTraditional(values["zhDescription"]),
          tags: convertSimplifiedArrayToTraditional(values["tags"]),
          markdown: convertSimplifiedToTraditional(markDown),
          statistics: {
            steam: convertSimplifiedToTraditional(values["steam"]),
            ign: convertSimplifiedToTraditional(values["ign"]),
            heihe: convertSimplifiedToTraditional(values["heihe"]),
            online: convertSimplifiedToTraditional(values["online"]),
          },
          lowConfiguration: {
            os: convertSimplifiedToTraditional(values["lowOS"]),
            processor: convertSimplifiedToTraditional(values["lowCPU"]),
            graphic: convertSimplifiedToTraditional(values["lowGPU"]),
            ram: convertSimplifiedToTraditional(values["lowMemory"]),
            disk: convertSimplifiedToTraditional(values["lowDisk"]),
          },
          highConfiguration: {
            os: convertSimplifiedToTraditional(values["highOS"]),
            processor: convertSimplifiedToTraditional(values["highCPU"]),
            graphic: convertSimplifiedToTraditional(values["highGPU"]),
            ram: convertSimplifiedToTraditional(values["highMemory"]),
            disk: convertSimplifiedToTraditional(values["highDisk"]),
          },
        },
      },
      images: {
        landscapeIcon: landscapeFileUrl,
        portraitIcon: portraitFileUrl,
        sideRecommendIcon: sideRecommendFileUrl,
      },
      tags: values["tags"] || [],
      statistics: {
        steam: values["steam"],
        ign: values["ign"],
        heihe: values["heihe"],
        online: values["online"],
      },
      markdown: markDown,
      gallery: galleryUrls || [],
      bilibili:
        "https://www.bilibili.com/blackboard/html5mobileplayer.html?bvid=" +
        values["bilibili"] +
        "&p=1&autoplay=true&danmaku=false&high_quality=1&hideDanmakuButton=true&noFullScreenButton=true&hideCoverInfo=true",
      lowConfiguration: {
        os: values["lowOS"],
        processor: values["lowCPU"],
        graphic: values["lowGPU"],
        ram: values["lowMemory"],
        disk: values["lowDisk"],
      },
      highConfiguration: {
        os: values["highOS"],
        processor: values["highCPU"],
        graphic: values["highGPU"],
        ram: values["highMemory"],
        disk: values["highDisk"],
      },
    };
    if (record) {
      await putGame(record.id, game);
    } else {
      await createGame(game);
    }
    setVisible(false);
    window.location.reload();
  };
  const handleCancel = () => setVisible(false);

  function handleEditorChange({ html, text }) {
    setMarkDown(text);
  }

  const handleTypeChange = (value) => {
    setType(value);
  };

  const handleImageUpload = async (file, callback) => {
    if (!["image/jpeg", "image/png", "image/gif"].includes(file.type)) {
      alert("只能上传 JPEG、PNG 和 GIF 格式的图片");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    const url = await uploadImg(formData);
    callback(url);
  };

  const handleLandscapeUploadChange = (info) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-1);
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response[0];
        setLandscapeFileUrl(file.url);
      }
      return file;
    });
    setLandscapeFileList(newFileList);
  };

  const handlePortraitUploadChange = (info) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-1);
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response[0];
        setPortraitFileUrl(file.url);
      }
      return file;
    });
    setPortraitFileList(newFileList);
  };

  const handleSideRecommendUploadChange = (info) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-1);
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response[0];
        setSideRecommendFileUrl(file.url);
      }
      return file;
    });
    setSideRecommendFileList(newFileList);
  };

  const handleGalleryUploadChange = (info) => {
    let fileList = [...info.fileList];
    const urls = [];
    fileList.map((file) => {
      if (file.response) {
        file.url = file.response[0];
        urls.push(file.url);
      }
      return file;
    });
    setGalleryUrls(urls);
    setGalleryFileList(fileList);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {record ? "更新游戏" : "创建游戏"}
      </Button>
      <Modal
        title={record ? "更新游戏" : "创建游戏"}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form onFinish={handleOk} initialValues={initialValues}>
          <Form.Item
            label="游戏名（简体）"
            name="zhName"
            rules={[{ required: true, message: "请输入游戏名（简体）!" }]}
          >
            <Input placeholder="游戏名（简体）" />
          </Form.Item>
          <Form.Item label="游戏名（英文）" name="enName">
            <Input placeholder="游戏名（英文）" />
          </Form.Item>
          <Form.Item
            label="Slug"
            name="slug"
            rules={[{ required: true, message: "请输入slug!" }]}
          >
            <Input placeholder="Slug" />
          </Form.Item>
          <Form.Item label="开发商" name="developer">
            <Input placeholder="开发商" />
          </Form.Item>
          <Form.Item label="发行商" name="publisher">
            <Input placeholder="发行商" />
          </Form.Item>
          <Form.Item
            label="原价"
            name="originPrice"
            rules={[{ required: true, message: "原价!" }]}
          >
            <InputNumber prefix="￥" placeholder="原价" />
          </Form.Item>
          <Form.Item
            label="现价"
            name="price"
            rules={[{ required: true, message: "现价!" }]}
          >
            <InputNumber prefix="￥" placeholder="现价" />
          </Form.Item>
          <Form.Item label="简介（简体）" name="zhDescription">
            <Input placeholder="简介（简体）" />
          </Form.Item>
          <Form.Item name="tags" label="标签">
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="标签"
              value={record?.latestVersion?.tags}
              dropdownStyle={{ display: "none" }}
            />
          </Form.Item>
          <Form.Item name="status" label="状态">
            <Select>
              <Option value="PUBLISHED">PUBLISHED</Option>
              <Option value="UNPUBLISHED">UNPUBLISHED</Option>
              <Option value="TESTING">TESTING</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="isAdultGame"
            label="成人内容"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item name="type" label="类型">
            <Select onChange={handleTypeChange}>
              <Option value="standalone">游戏本体</Option>
              <Option value="dlc">DLC</Option>
            </Select>
          </Form.Item>
          {type === "dlc" && (
            <Form.Item name="standaloneId" label="游戏本体ID（非GameId）">
              <Input placeholder="64f1ab00e48fbf74793c9eb3" />
            </Form.Item>
          )}
          <Form.Item label="发行日期" name="firstReleaseDate">
            <Input placeholder="2022年2月10日" />
          </Form.Item>
          <Form.Item label="Steam评价" name="steam">
            <Input placeholder="特别好评" />
          </Form.Item>
          <Form.Item label="IGN评分" name="ign">
            <Input placeholder="8.2" />
          </Form.Item>
          <Form.Item label="小黑盒得分" name="heihe">
            <Input placeholder="8万" />
          </Form.Item>
          <Form.Item label="最高在线人数" name="online">
            <Input placeholder="135" />
          </Form.Item>
          <Form.Item label="最低配置-操作系统" name="lowOS">
            <Input placeholder="Windows 7" />
          </Form.Item>
          <Form.Item label="最低配置-处理器" name="lowCPU">
            <Input placeholder="Intel Core2" />
          </Form.Item>
          <Form.Item label="最低配置-内存" name="lowMemory">
            <Input placeholder="需要 4 GB内存（RAM）" />
          </Form.Item>
          <Form.Item label="最低配置-显卡" name="lowGPU">
            <Input placeholder="GeForce GT 730" />
          </Form.Item>
          <Form.Item label="最低配置-存储空间" name="lowDisk">
            <Input placeholder="需要2GB可用空间（ROM）" />
          </Form.Item>
          <Form.Item label="推荐配置-操作系统" name="highOS">
            <Input placeholder="Windows 7" />
          </Form.Item>
          <Form.Item label="推荐配置-处理器" name="highCPU">
            <Input placeholder="Intel Core2" />
          </Form.Item>
          <Form.Item label="推荐配置-内存" name="highMemory">
            <Input placeholder="需要 4 GB内存（RAM）" />
          </Form.Item>
          <Form.Item label="推荐配置-显卡" name="highGPU">
            <Input placeholder="GeForce GT 730" />
          </Form.Item>
          <Form.Item label="推荐配置-存储空间" name="highDisk">
            <Input placeholder="需要2GB可用空间（ROM）" />
          </Form.Item>
          <Form.Item label="B站视频BV ID" name="bilibili">
            <Input placeholder="BV1eT4y1W75o" />
          </Form.Item>
          <Form.Item name="landscape">
            <Upload
              action="/api/admin/game/image"
              headers={getAuthHeaders()}
              name="file"
              accept=".jpg,.jpeg,.png,.gif"
              onChange={handleLandscapeUploadChange}
              fileList={landscapeFileList}
            >
              <Button icon={<UploadOutlined />}>上传横小图</Button>
            </Upload>
          </Form.Item>
          <Form.Item name="portrait">
            <Upload
              action="/api/admin/game/image"
              headers={getAuthHeaders()}
              name="file"
              accept=".jpg,.jpeg,.png,.gif"
              onChange={handlePortraitUploadChange}
              fileList={portraitFileList}
            >
              <Button icon={<UploadOutlined />}>上传竖小图</Button>
            </Upload>
          </Form.Item>
          <Form.Item name="sideRecommand">
            <Upload
              action="/api/admin/game/image"
              headers={getAuthHeaders()}
              name="file"
              accept=".jpg,.jpeg,.png,.gif"
              onChange={handleSideRecommendUploadChange}
              fileList={sideRecommendFileList}
            >
              <Button icon={<UploadOutlined />}>上传推荐图</Button>
            </Upload>
          </Form.Item>
          <Form.Item name="images">
            <Upload
              multiple
              action="/api/admin/game/image"
              headers={getAuthHeaders()}
              name="file"
              accept=".jpg,.jpeg,.png,.gif"
              fileList={galleryFileList}
              onChange={handleGalleryUploadChange}
            >
              <Button icon={<UploadOutlined />}>上传介绍图片</Button>
            </Upload>
          </Form.Item>
          <MdEditor
            style={{ height: "500px" }}
            renderHTML={(text) => mdParser.render(text)}
            onChange={handleEditorChange}
            onImageUpload={handleImageUpload}
            value={markDown}
          />
          <Form.Item>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ItemEditModal;
