import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import GameButton from "./GameButton";
import { navigate } from "../../features/userSlice";
import { useParams } from "react-router-dom";
import { postOrder } from "../../service/orderService";
import { hasEntitlement } from "../../service/entitlementService";
import styles from "./GamePage.module.css";
import { Button, Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import WishlistButton from "./WishlistButton";
import { extractGameName } from "../../utils/i18n";
import { pushAutoBaiduEvent } from "../../utils/baidu";
import { trackClickEventAsync } from "../../service/trackingService";

const { confirm } = Modal;

const PurchaseButton = ({ game }) => {
  const isIframe = self !== top; /* eslint-disable-line no-restricted-globals */
  const dispatch = useDispatch();
  const entitlements = useSelector((state) => state.entitlements);
  const user = useSelector((state) => state.user.value);
  const userLoaded = useSelector((state) => state.user.loaded);
  const { slug } = useParams();
  const { t, i18n } = useTranslation();

  const gameSlug = slug;

  const showConfirmDialog = () => {
    if (
      game.type === "dlc" &&
      !hasEntitlement(entitlements, game?.standaloneGame.slug)
    ) {
      let title =
        `${t("dlc_hint1")}《` +
        extractGameName(game?.standaloneGame, i18n.language) +
        `》${t("dlc_hint2")}`;
      title += "《" + extractGameName(game, i18n.language) + "》";
      confirm({
        title,
        okText: t("purchase_now"),
        cancelText: t("cancel"),
        okType: "primary",
        okButtonProps: {
          style: { backgroundColor: "#FE9923" },
        },
        async onOk() {
          await trackClickEventAsync(
            isIframe,
            "purchase",
            game?.standaloneGame?.slug,
          );
          pushAutoBaiduEvent(isIframe, {
            tagA: "purchase",
            tagB: game?.standaloneGame?.slug,
          });
          const order = await postOrder(game?.standaloneGame?.slug);
          dispatch(
            navigate({
              url: `/order-confirm/${order.id}`,
            }),
          );
        },
      });
      return true;
    }
    return false;
  };

  const handlePurchase = async () => {
    if (user.id) {
      if (showConfirmDialog()) {
        return;
      }

      await trackClickEventAsync(isIframe, "purchase", gameSlug);
      pushAutoBaiduEvent(isIframe, { tagA: "purchase", tagB: gameSlug });
      const order = await postOrder(gameSlug);
      dispatch(
        navigate({
          url: `/order-confirm/${order.id}`,
        }),
      );
    } else {
      await trackClickEventAsync(isIframe, "purchase", gameSlug);
      pushAutoBaiduEvent(isIframe, { tagA: "purchase", tagB: gameSlug });
      dispatch(
        navigate({
          gameSlug,
        }),
      );
    }
  };

  const renderButton = () => {
    if (hasEntitlement(entitlements, gameSlug) && game) {
      return <GameButton gameId={game.id} />;
    }
    return (
      <div className={styles.purchasebuttonwrap}>
        <Button
          type="primary"
          className={styles.purchasebutton}
          onClick={handlePurchase}
        >
          {t("purchase_now")}
        </Button>
        <WishlistButton itemSlug={game?.slug} game={game} />
      </div>
    );
  };

  return (
    <div>
      {(userLoaded && !user.id) || (entitlements && game && game.id) ? (
        renderButton()
      ) : (
        <Button
          type="primary"
          loading
          className={styles.buttonprimarylarge}
        ></Button>
      )}
    </div>
  );
};

export default PurchaseButton;
