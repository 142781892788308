import apiClient from "./apiClient";

export async function getDashboardList() {
  const response = await apiClient.get(
    `${process.env.REACT_APP_API_URL}/api/admin/dashboard`,
  );
  return response.data;
}

export async function getGameDashboardList(slug, publisherId = '') {
  if (slug) {
    const response = await apiClient.get(
      `${process.env.REACT_APP_API_URL}/api/admin/gameDashboard?slug=${slug}&publisherId=${publisherId}`,
    );
    return response.data;
  } else {
    const response = await apiClient.get(
      `${process.env.REACT_APP_API_URL}/api/admin/gameDashboard?&publisherId=${publisherId}`,
    );
    return response.data;
  }
}
