import React from "react";
import ReactMarkdown from "react-markdown";

const MarkdownRender = (props) => {
  return (
    <ReactMarkdown
      children={props.content}
      components={{
        p: ({ node, ...props }) => (
          <p
            {...props}
            className="markdown-p"
            style={{
              margin: 0,
            }}
          />
        ),
        h1: ({ node, ...props }) => (
          // eslint-disable-next-line jsx-a11y/heading-has-content
          <h1
            {...props}
            className="markdown-h1"
            style={{
              margin: 0,
            }}
          />
        ),
        h2: ({ node, ...props }) => (
          // eslint-disable-next-line jsx-a11y/heading-has-content
          <h2
            {...props}
            className="markdown-h2"
            style={{
              margin: 0,
            }}
          />
        ),
        h3: ({ node, ...props }) => (
          // eslint-disable-next-line jsx-a11y/heading-has-content
          <h3
            {...props}
            className="markdown-h3"
            style={{
              margin: 0,
            }}
          />
        ),
        h4: ({ node, ...props }) => (
          // eslint-disable-next-line jsx-a11y/heading-has-content
          <h4
            {...props}
            className="markdown-h4"
            style={{
              margin: 0,
            }}
          />
        ),
        h5: ({ node, ...props }) => (
          // eslint-disable-next-line jsx-a11y/heading-has-content
          <h5
            {...props}
            className="markdown-h5"
            style={{
              margin: 0,
            }}
          />
        ),
        h6: ({ node, ...props }) => (
          // eslint-disable-next-line jsx-a11y/heading-has-content
          <h6
            {...props}
            className="markdown-h6"
            style={{
              margin: 0,
            }}
          />
        ),
        img: ({ node, ...props }) => (
          //
          <img
            {...props}
            className="markdown-img"
            style={{
              maxWidth: "100%",
              margin: 0,
            }}
            alt="markdown-img"
          />
        ),
      }}
    />
  );
};

export default MarkdownRender;
