import styles from "./OrderConfirm.module.css";
import React from "react";
import { Button } from "antd";
import successIcon from "../../assets/icon/orderSuccess.svg";
import { handleOpenHub } from "../../utils/openHub";
import { useTranslation } from "react-i18next";
import { pushAutoBaiduEvent } from "../../utils/baidu";
import { trackClickEvent } from "../../service/trackingService";

const DownloadHub = () => {
  const { t } = useTranslation();

  const isIframe = self !== top; /* eslint-disable-line no-restricted-globals */

  const renderDownload = () => {
    return (
      <div className={styles.downloadWrapper}>
        <div className={styles.hint}>
          <div>{t("download_hub_hint")}</div>
        </div>
        <Button
          type="primary"
          className={styles.buttonDownload}
          onClick={() => {
            trackClickEvent(isIframe, "open_hub", "download_hub_page");
            pushAutoBaiduEvent(isIframe, {
              tagA: "open_hub",
              tagB: "download_hub_page",
            });
            handleOpenHub();
          }}
        >
          {t("download")}
        </Button>
      </div>
    );
  };

  return (
    <div className={styles.web}>
      <div className={styles.div1}>
        <div className={styles.orderStatusInfo}>
          <div className={styles.statusIcon}>
            <img src={successIcon} alt="success-icon" />
          </div>
          {renderDownload()}
        </div>
      </div>
    </div>
  );
};

export default DownloadHub;
