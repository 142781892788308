import apiClient from "./apiClient";

export async function fetchWishlistStatusFromAPI() {
  const response = await apiClient.get(
    `${process.env.REACT_APP_API_URL}/api/wishlist`,
  );
  return response.data;
}

export async function removeItemFromWishlistAPI(gameSlug) {
  const response = await apiClient.delete(
    `${process.env.REACT_APP_API_URL}/api/wishlist/${gameSlug}`,
  );
  return response.data;
}

export async function addItemToWishlistAPI(gameSlug) {
  const response = await apiClient.post(
    `${process.env.REACT_APP_API_URL}/api/wishlist`,
    {
      gameSlug: gameSlug,
    },
  );
  return response.data;
}
