import React, { useEffect, useState } from "react";
import { Space, Table, Input, Select, Button, Modal } from "antd";
import {
  ReloadOutlined,
  EyeOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { useParams } from 'react-router-dom';

import UpdatePackageModal from "./UpdatePackageModal";
import {
  gameList,
  putGame,
  getGamePackagePutUrl,
  deleteGame, getGameWithHubPackagePutUrl,
} from "../../../service/gameService";
import { EditableTableComponet, getEditableColumns } from "../../EditableTable";
import ObjectPreviewModal from "../../ObjectPreviewModal";
import ItemEditModal from "./ItemEdit";
import UpdatePackageHubModal from "./UpdatePackageHubModal";

const { Search } = Input;
const { confirm } = Modal;

const searchOptions = [
  {
    value: "name",
    label: "名称",
  },
  {
    value: "slug",
    label: "短标签",
  },
];

const ItemList = ({}) => {
  const [games, setGames] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchType, setSearchType] = useState("name");

  const [openUpdatePackageModal, setOpenUpdatePackageModal] = useState(false);
  const [openUpdatePackageHubModal, setOpenUpdatePackageHubModal] = useState(false);
  const [updatingGameId, setUpdatingGameId] = useState(null);
  const [updatingGameName, setUpdatingGameName] = useState(null);
  const [putGameUrl, setPutGameUrl] = useState(null);
  const [putGameExePath, setPutGameExePath] = useState(null);
  const [putGamePackageName, setPutGamePackageName] = useState(null);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [previewingObject, setPreviewingObject] = useState(null);
  const { publisherId } = useParams();

  const showPromiseConfirm = (id) => {
    confirm({
      title: "确认删除这个游戏?",
      icon: <ExclamationCircleFilled />,
      async onOk() {
          await deleteGame(id);
          window.location.reload();
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    const fetchGames = async () => {
      let searchName = "";
      let searchSlug = "";
      if (searchType === "name") {
        searchName = searchValue;
      }
      if (searchType === "slug") {
        searchSlug = searchValue;
      }
      const response = gameList(true, searchName, searchSlug, publisherId);
      const data = await response;
      setGames(data.results);
    };

    fetchGames();
  }, [searchType, searchValue]);

  const columns = [
    {
      title: "游戏ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "名称",
      dataIndex: "name",
      key: "name",
      editable: false,
    },
    {
      title: "短标签",
      dataIndex: "slug",
      key: "slug",
      render: (text, record) => (
        <a
          href={`/game/${record.slug}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text}
        </a>
      ),
    },
    {
      title: "开发商",
      dataIndex: "developer",
      key: "developer",
      editable: false,
    },
    {
      title: "发行商",
      dataIndex: "publisher",
      key: "publisher",
      editable: false,
    },
    {
      title: "价格",
      dataIndex: "price",
      key: "price",
      editable: false,
      isNumber: true,
      // defaultSortOrder: "descend",
      // searchOptions: ["descend", "ascend"],
      // sorter: {
      //   compare: (a, b) => a.price - b.price,
      //   multiple: 1,
      // }
    },
    {
      title: "原价",
      dataIndex: "originPrice",
      key: "originPrice",
      editable: false,
      isNumber: true,
      // defaultSortOrder: "descend",
      // searchOptions: ["descend", "ascend"],
      // sorter: {
      //   compare: (a, b) => a.originPrice - b.originPrice,
      //   multiple: 2,
      // }
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      editable: false,
      selections: [
        { value: "PUBLISHED", label: "PUBLISHED" },
        { value: "UNPUBLISHED", label: "UNPUBLISHED" },
        { value: "TESTING", label: "TESTING" },
      ],
    },
    {
      title: "类型",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "最新版本属性",
      render: (_, record) => (
        <Button
          icon={<EyeOutlined />}
          onClick={() => {
            setPreviewingObject(record);
            setOpenPreviewModal(true);
          }}
        />
      ),
      key: "latestVersion",
    },
    {
      title: "操作",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <ItemEditModal record={record} />
          <Button
            size="small"
            onClick={() => {
              getGamePackagePutUrl(record.id).then((data) => {
                if (process.env.NODE_ENV === "development") {
                  console.log("data", data);
                  data.url = data.url.replace(
                    `https://gamecenter-prd-1319533103.cos.ap-hongkong.myqcloud.com`,
                    `${process.env.REACT_APP_API_URL}/storage`,
                  );
                  console.log("data", data);
                }
                console.log(record);
                setPutGameUrl(data.url);
                setPutGamePackageName(data.object);
                setUpdatingGameId(record.id);
                setUpdatingGameName(record.name);
                setPutGameExePath(record?.latestVersion?.uploads?.exePath);
                setOpenUpdatePackageModal(true);
              });
            }}
          >
            更新游戏包
          </Button>
          <Button
            size="small"
            onClick={() => {
              getGameWithHubPackagePutUrl(record.id).then((data) => {
                if (process.env.NODE_ENV === "development") {
                  console.log("game with hub data", data);
                  data.url = data.url.replace(
                    `https://gamecenter-prd-1319533103.cos.ap-hongkong.myqcloud.com`,
                    `${process.env.REACT_APP_API_URL}/storage`,
                  );
                  console.log("game with hub data", data);
                }
                console.log(record);
                setPutGameUrl(data.url);
                setPutGamePackageName(data.object);
                setUpdatingGameId(record.id);
                setUpdatingGameName(record.name);
                setPutGameExePath(record?.latestVersion?.packageUploads &&
                    record.latestVersion.packageUploads["lenovo"]?.exePath);
                setOpenUpdatePackageHubModal(true);
              });
            }}
          >
            更新游戏包（联想）
          </Button>
          <Button danger onClick={() => showPromiseConfirm(record.id)}>
            删除
          </Button>
        </Space>
      ),
    },
  ];

  const handleSave = (row) => {
    console.log("row", row);
    const newData = [...games];
    const index = newData.findIndex((item) => row.id === item.id);
    newData[index] = row;
    putGame(row.id, row)
      .then((_) => {
        setGames(newData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <div style={{ display: "flex", gap: "8px" }}>
        <Space.Compact>
          <Select
            defaultValue={searchType}
            options={searchOptions}
            style={{ width: 96 }}
            onChange={(value) => {
              setSearchType(value);
              setSearchValue("");
            }}
          />
          <Search
            placeholder={`输入${
              searchOptions.find((option) => option.value === searchType)
                ?.label || ""
            }搜索`}
            onSearch={(value) => {
              setSearchValue(value);
            }}
            enterButton
            style={{ width: 200 }}
          />
        </Space.Compact>
        <ItemEditModal />
        <Button
          onClick={() => {
            window.location.reload();
          }}
          icon={<ReloadOutlined />}
        />
      </div>
      <Table
        components={EditableTableComponet}
        rowClassName={() => "editable-row"}
        columns={getEditableColumns(columns, handleSave)}
        dataSource={games}
        rowKey={(record) => record.id}
        pagination={{
          hideOnSinglePage: true,
        }}
        onChange={(pagination, filters, sorter, extra) => {
          console.log("params", pagination, filters, sorter, extra);
        }}
        bordered
      />
      <UpdatePackageModal
        gameId={updatingGameId}
        gameName={updatingGameName}
        putGameUrl={putGameUrl}
        putGamePackageName={putGamePackageName}
        exePath={putGameExePath}
        open={openUpdatePackageModal}
        onCancel={() => {
          setOpenUpdatePackageModal(false);
        }}
        onRefreshUrl={() => {
          getGamePackagePutUrl(updatingGameId).then((data) => {
            setPutGameUrl(data.url);
            setPutGamePackageName(data.object);
          });
        }}
        setGames={setGames}
      />
      <UpdatePackageHubModal
        gameId={updatingGameId}
        gameName={updatingGameName}
        putGameUrl={putGameUrl}
        putGamePackageName={putGamePackageName}
        exePath={putGameExePath}
        open={openUpdatePackageHubModal}
        onCancel={() => {
          setOpenUpdatePackageHubModal(false);
        }}
        onRefreshUrl={() => {
          getGameWithHubPackagePutUrl(updatingGameId).then((data) => {
            setPutGameUrl(data.url);
            setPutGamePackageName(data.object);
          });
        }}
        setGames={setGames}
      />
      <ObjectPreviewModal
        show={openPreviewModal}
        object={previewingObject}
        onCancel={() => {
          setOpenPreviewModal(false);
          setPreviewingObject(null);
        }}
      />
    </div>
  );
};

export default ItemList;
