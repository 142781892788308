import React, { useEffect, useState } from "react";
import { purchasedGameList } from "../../service/gameService";
import PurchasedGameCard from "./PurchasedGameCard";
import BasicInfo from "./BasicInfo";
import styles from "./UserPage.module.css";
import { useTranslation } from "react-i18next";
import { extractGameName } from "../../utils/i18n";
import { trackViewEvent } from "../../service/trackingService";
import { useLocation } from "react-router-dom";
import { Menu } from "antd";
import { UserOutlined, RocketOutlined } from "@ant-design/icons";

const UserPage = () => {
  const isIframe = self !== top; /* eslint-disable-line no-restricted-globals */
  const [activeTab, setActiveTab] = useState("basicInfo");
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fid = queryParams.get("fid");

  useState(() => {
    setActiveTab("basicInfo");
  }, []);

  const menu = (
    <Menu>
      <Menu.Item>
        <div
          className={
            activeTab === "basicInfo" ? styles.menuItemWrapperSelected : null
          }
          onClick={() => setActiveTab("basicInfo")}
        >
          <UserOutlined className={styles.menuIcon} />
          基本信息
        </div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => setActiveTab("purchasedGames")}>
          <RocketOutlined className={styles.menuIcon} />
          已购游戏
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.web}>
      <div className={styles.leftWrap}>
        <div style={{
        display: "flex",
        fontWeight: "500",
        }}>
          个人中心
        </div>
        {menu}
      </div>
      {activeTab === "basicInfo" && <BasicInfo/>}
      {activeTab === "purchasedGames" && <PurchasedGames/>}
    </div>
  );
};

const PurchasedGames = () => {
  const [games, setGames] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchGames = async () => {
      const response = await purchasedGameList();
      setGames(response.results);
    };

    fetchGames();
  }, []);

  return (
    <div className={styles.rightWrap}>
       <div
        style={{
          width: "100%",
          fontSize: "24px",
          fontWeight: "500",
          top: "0",
        }}
      >
        {t("purchased_games")}
      </div>
      <div
        style={{
          height: "70vh",
          overflowY: "auto",
          display: "flex",
          flexDirection: "row",
          gap: "12px",
          flexWrap: "wrap",
          alignContent: "flex-start",
        }}
      >  
        {games.map((game) => {
          const name = extractGameName(game, i18n.language);
          return (
            <PurchasedGameCard
              key={game.id}
              imageUri={game.latestVersion?.images?.portraitIcon}
              name={name}
              slug={game.slug}
              type="link"
              link={`/game/${game.slug}`}
              price={game.price}
              originPrice={game.originPrice}
            />
          );
        })}      
      </div>
    </div>
  );
}

export default UserPage;
