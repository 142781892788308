import styles from "./OrderConfirm.module.css";
import React, { useEffect, useState } from "react";
import { Button } from "antd";
import orderFail from "../../assets/icon/orderFail.svg";
import { useTranslation } from "react-i18next";
import { pushAutoBaiduEvent } from "../../utils/baidu";
import { trackClickEvent } from "../../service/trackingService";
import { navigate } from "../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { postOrder } from "../../service/orderService";
import { gameList } from "../../service/gameService";

const SupplyOrderPage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isIframe = self !== top; /* eslint-disable-line no-restricted-globals */

  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const language = queryParams.get("language");
  const [games, setGames] = useState([]);
  const user = useSelector((state) => state.user.value);

  useEffect(() => {
    const fetchGames = async () => {
      const data = await gameList(false, "", "");
      setGames(data.results);
    };
    fetchGames();
  }, []);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
      sessionStorage.setItem("language", language);
    }
  }, [language]);

  const renderSupplyOrder = () => {
    return (
      <div className={styles.downloadWrapper}>
        <div className={styles.hint}>
          <div>{t("supply_order_text")}</div>
        </div>
        <Button
          type="primary"
          className={styles.buttonDownload}
          onClick={() => {
            trackClickEvent(isIframe, "supply_order", "supply_order_page");
            pushAutoBaiduEvent(isIframe, {
              tagA: "supply_order",
              tagB: "supply_order_page",
            });
            games.map(async (game) => {
              if (game.id === id && user.id) {
                const order = await postOrder(game.slug);
                dispatch(
                  navigate({
                    url: `/order-confirm/${order.id}`,
                  }),
                );
              }
            });
          }}
        >
          {t("purchase_now")}
        </Button>
      </div>
    );
  };

  return (
    <div className={styles.web}>
      <div className={styles.div1}>
        <div className={styles.orderStatusInfo}>
          <div className={styles.statusIcon}>
            <img src={orderFail} alt="failed-icon" />
          </div>
          {renderSupplyOrder()}
        </div>
      </div>
    </div>
  );
};

export default SupplyOrderPage;
