import React, { useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import { Space, Table, Button, Tag, Select, Input, Checkbox } from "antd";
import { ReloadOutlined, EyeOutlined } from "@ant-design/icons";
import {
  getOrderById,
  orderList,
  postRefund,
  postRevokeEntitlements,
} from "../../../service/orderService";
import { ConfirmDialog } from "../../ConfirmModal";

import GetCSVModal from "./GetCSVModal";
import ObjectPreviewModal from "../../ObjectPreviewModal";

const pageSize = 10;
const { Search } = Input;

const searchOptions = [
  {
    value: "id",
    label: "订单ID",
  },
];

const OrderList = () => {
  const navigate = useNavigate();
  const uidParam = new URLSearchParams(window.location.search).get("OID");
  const [searchValue, setSearchValue] = useState(uidParam ? uidParam : "");
  const [searchType, setSearchType] = useState("id");
  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageSettings, setPageSettings] = useState([0, pageSize]);
  const [showConfirmRevokeEntitlements, setShowConfirmRevokeEntitlements] =
    useState(false);
  const [showConfirmRefund, setShowConfirmRefund] = useState(false);
  const [actionRecord, setActionRecord] = useState(null);
  const [needRevokeEntitlements, setNeedRevokeEntitlements] = useState(true);
  const [openGetCSVModal, setOpenGetCSVModal] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [previewingObject, setPreviewingObject] = useState(null);
  const [selectRefund, setSelectRefund] = useState(false);
  const { publisherId } = useParams();

  useEffect(() => {
    const fetchOrders = async () => {
      let searchId = "";
      if (searchType === "id") {
        searchId = searchValue;
      }
      var orderResponse;
      if (selectRefund) {
        orderResponse = await orderList(pageSettings, ["refunded"], publisherId);
        setOrders(orderResponse.results);
        setTotal(orderResponse.total);
      } else {
        if (searchValue) {
          try {
            orderResponse = await getOrderById(searchValue, "true", publisherId);
            var arr = new Array(1);
            arr[0] = orderResponse;
            setOrders(arr);
            setTotal(1);
          } catch (errInfo) {
            console.log("Get order failed:", errInfo);
            setOrders([]);
            setTotal(0);
          }
        } else {
          orderResponse = await orderList(pageSettings, [
            "finished",
            "failed",
            "refunded",
            "refunding",
            "refund_failed",
          ], publisherId);
          // console.log(orderResponse);
          setOrders(orderResponse.results);
          setTotal(orderResponse.total);
        }
      }
    };

    fetchOrders();
  }, [pageSettings, searchType, searchValue, selectRefund]);
  // 在此处获取订单数据

  const columns = [
    {
      title: "订单创建时间",
      key: "createdTime",
      render: (_, record) => (
        <span>{new Date(record.createdTime).toLocaleString()}</span>
      ),
    },
    {
      title: "订单ID",
      key: "id",
      render: (_, record) => (
        <u
          style={{ cursor: publisherId? "" : "pointer" }}
          onClick={() => {
              if (!publisherId) {
                  navigate(`/order/invoicement?orderId=${record.id}`);
              }
          }}
        >
          {record.id}
        </u>
      ),
    },
    {
      title: "支付平台",
      dataIndex: "payType",
      render: (_, record) => {
        return (
          record.payType && (
            <Tag color={record.payType === "alipay" ? "blue" : "green"}>
              {record.payType.toUpperCase()}
            </Tag>
          )
        );
      },
    },
    {
      title: "金额",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
    {
      title: "状态",
      key: "status",
      render: (_, record) => {
        return (
          <Tag
            color={
              record.status === "finished"
                ? "green"
                : record.status === "refund_failed" ||
                    record.status === "failed"
                  ? "red"
                  : record.status === "refunding"
                    ? "orange"
                    : "blue"
            }
          >
            {record.status.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: "全部内容",
      key: "raw",
      render: (_, record) => (
        <Button
          icon={<EyeOutlined />}
          onClick={() => {
            setPreviewingObject(record);
            setOpenPreviewModal(true);
          }}
        />
      ),
      //<ReactJson src={record} collapsed={true} />,
    },
  ];

  if (!publisherId) {
      columns.push(
          {
              title: "用户ID",
              key: "user",
              render: (_, record) => (
                  <u
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                          navigate(`/admin/users?UID=${record.user}`);
                      }}
                  >
                      {record.user}
                  </u>
              ),
          }
      );
      columns.push(      {
              title: "操作",
              key: "actions",
              render: (_, record) => (
                  <Space size="middle">
                      <Button
                          danger
                          type="primary"
                          size="small"
                          onClick={() => {
                              setActionRecord(record);
                              setShowConfirmRefund(true);
                              setNeedRevokeEntitlements(true);
                          }}
                      >
                          退款并取消授权
                      </Button>
                      <Button
                          danger
                          size="small"
                          onClick={() => {
                              setActionRecord(record);
                              setShowConfirmRevokeEntitlements(true);
                          }}
                      >
                          仅取消授权
                      </Button>
                      <Button
                          danger
                          size="small"
                          onClick={() => {
                              setActionRecord(record);
                              setShowConfirmRefund(true);
                              setNeedRevokeEntitlements(false);
                          }}
                      >
                          仅退款但不取消授权
                      </Button>
                  </Space>
              ),
          }
      );
  }

  return (
    <div style={{ display: "flex", gap: "8px", flexDirection: "column" }}>
      <div style={{ display: "flex", gap: "8px" }}>
        <div style={{ display: "flex", gap: "10px" }}>
          <Space.Compact>
            <Select
              defaultValue={searchType}
              options={searchOptions}
              style={{ width: 96 }}
              onChange={(value) => {
                setSearchType(value);
                setSearchValue("");
              }}
            />
            <Search
              placeholder={`输入${
                searchOptions.find((option) => option.value === searchType)
                  ?.label || ""
              }搜索`}
              onSearch={(value) => {
                setSearchValue(value);
              }}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              enterButton
              style={{ width: 200 }}
            />
          </Space.Compact>
        </div>
        <Button
          style={{ width: "120px" }}
          type="primary"
          onClick={() => {
            setOpenGetCSVModal(true);
          }}
        >
          导出CSV统计
        </Button>
        <Button
          onClick={() => {
            window.location.reload();
          }}
          icon={<ReloadOutlined />}
        />
        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "16px" }}
        >
          <Checkbox
            checked={selectRefund === true}
            onClick={(e) => e.stopPropagation()}
            onChange={() => {
              setSelectRefund(!selectRefund);
            }}
          >
            只看已退款
          </Checkbox>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={orders}
        rowKey={(record) => record.id}
        pagination={{
          total: total,
          pageSize: pageSize,
          hideOnSinglePage: false,
          onChange: (page, pageSize) => {
            setPageSettings([(page - 1) * pageSize, pageSize]);
          },
        }}
        bordered
      />
      <GetCSVModal
        open={openGetCSVModal}
        handleClose={() => {
          setOpenGetCSVModal(false);
        }}
      />
      <ObjectPreviewModal
        show={openPreviewModal}
        object={previewingObject}
        onCancel={() => {
          setOpenPreviewModal(false);
          setPreviewingObject(null);
        }}
      />
      <ConfirmDialog
        show={showConfirmRevokeEntitlements}
        title="确认取消授权"
        message="确认取消授权?"
        onConfirm={() => {
          postRevokeEntitlements(actionRecord.id)
            .then(() => {
              alert("取消授权成功");
            })
            .catch((error) => {
              console.log(error);
              alert("删除失败");
            });
        }}
        onCancel={() => {
          setShowConfirmRevokeEntitlements(false);
        }}
      />
      <ConfirmDialog
        show={showConfirmRefund}
        title="确认退款"
        message="确认退款?"
        onConfirm={() => {
          postRefund(actionRecord.id, needRevokeEntitlements)
            .then(() => {
              alert("退款发起成功");
            })
            .catch((error) => {
              console.log(error);
              alert("退款发起失败");
            });
        }}
        onCancel={() => {
          setShowConfirmRefund(false);
        }}
      />
    </div>
  );
};

export default OrderList;
