import styles from "./Footer.module.css";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { BlurContext } from "../../contexts/blurContext";

const Footer = () => {
  const { t } = useTranslation();
  const { isBlurred } = useContext(BlurContext);

  return (
    <div
      className={styles.footer}
      style={{ filter: isBlurred ? "blur(15px)" : "unset" }}
    >
      <div className={styles.copyright20003000HhhhhhhWrapper}>
        <div className={styles.button}>
          <a href="/public/乌图游戏_用户服务协议.pdf">
            {t("terms.user_service_agreement")}
          </a>
          {t("terms.and")}
          <a href="/public/乌图游戏_个人信息处理规则.pdf">
            {t("terms.personal_information_processing_rules")}
          </a>
        </div>
        <div className={styles.button}>
          CopyRight © 2022-2023 <a href="https://u4ugames.com">u4ugames.com</a>{" "}
          All Right Reserved
        </div>
      </div>
    </div>
  );
};

export default Footer;
