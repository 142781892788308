import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchGameBySlug } from "../service/gameService";

const dataExpiryTime = 3600000;

export const fetchGame = createAsyncThunk(
  "game/fetchGame",
  async (slug, { getState, rejectWithValue }) => {
    const { games } = getState().game;
    const existingGame = games[slug];
    const now = new Date().getTime();
    if (existingGame && now - existingGame.timestamp < dataExpiryTime) {
      return rejectWithValue("Data already exists and is not expired");
    }
    const response = await fetchGameBySlug(slug);
    return response;
  },
);

const gameSlice = createSlice({
  name: "game",
  initialState: {
    games: {},
    loadingStatus: {},
    error: {},
  },
  extraReducers: {
    [fetchGame.pending]: (state, action) => {
      const { arg: slug } = action.meta;
      state.loadingStatus[slug] = "loading";
    },
    [fetchGame.fulfilled]: (state, action) => {
      const { arg: slug } = action.meta;
      state.games[slug] = {
        data: action.payload,
        timestamp: new Date().getTime(),
      };
      state.loadingStatus[slug] = "succeeded";
    },
    [fetchGame.rejected]: (state, action) => {
      const { arg: slug } = action.meta;
      state.loadingStatus[slug] = "failed";
      state.error[slug] = action.error.message;
    },
  },
});

export default gameSlice.reducer;
