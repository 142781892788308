import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Button, Checkbox, message } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./UserPage.module.css";
import { userInfo, updateUserInfo } from "../../service/userService";
import MobileVerifyModal from "./MobileVerifyModal";
import MobileResetModal from "./MobileResetModal";
import EmailVerifyModal from "./EmailVerifyModal";
import EmailResetModal from "./EmailResetModal";
import PasswordResetModal from "./PasswordResetModal";
import {
  showMobileVerifyModal,
  showEmailVerifyModal,
} from "../../features/userSlice";

const BasicInfo = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState([]);
  const { t, i18n } = useTranslation();
  const [name, setName] = useState("");
  const [gender, setGender] = useState(0);
  const [age, setAge] = useState(-1);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [infoNeedUpdate, setInfoNeedUpdate] = useState(false);
  const refreshInfo = useSelector(
    (state) => state.user.refreshInfo,
  );
  useEffect(() => {
    const fetchUser = async () => {
      const userResponse = await userInfo();
      setUser(userResponse);
      setName(userResponse.name);
      setGender(userResponse.gender);
      setAge(userResponse.age);
      setPhone(userResponse.phone);
      setEmail(userResponse.email);
      setPassword(userResponse.password);
    };

    fetchUser();
    setInfoNeedUpdate(false);
  }, [infoNeedUpdate, refreshInfo]);

  const saveName = async (value) => {
    if (value !== user.name) {
      await updateUserInfo({ name: value });
      setInfoNeedUpdate(true);
    }
  };

  const changeGender = async (value) => {
    setGender(value);
    await updateUserInfo({ gender: value });
    setInfoNeedUpdate(true);
  };

  const saveAge = async (value) => {
    const regex = /^[1-9]\d*$/;
    if (regex.test(value) || value == "") {
      const age = value == "" ? 0 : parseInt(value);

      if (age >= 0 && age < 100) {
        await updateUserInfo({ age: value });
        setInfoNeedUpdate(true);
      } else {
        message.error({
          key: "age_format_error",
          content: t("age_format_error"),
          duration: 1,
        });
      }
    } else {
      message.error({
        key: "age_format_error",
        content: t("age_format_error"),
        duration: 1,
      });
    }    
  };

  return (
    <div className={styles.rightWrap}>
       <div
        style={{
          width: "100%",
          fontSize: "24px",
          fontWeight: "500",
          top: "0",
        }}
      >{
        t('basic_info')
      }</div>
      <div style={{
        display: "flex",
        gap: "12px",
        flexWrap: "wrap",
      }}>
        账户ID： {user.id}
      </div>
      <div className={styles.title}>用户昵称</div>
      <Input
        // placeholder={name}
        onChange={(e) => {
          saveName(e.target.value);
        }}
        // onMouseLeave={(e) => {
        //   saveName(e.target.value);
        // }}
        value={name}
      />
      <div className={styles.title}>手机号</div>
      {user.mobile === "" &&
        <div className={styles.msgWrap}>
        <div className={styles.content}>{"未绑定"}</div>
          <Button
            type="primary"
            onClick={() => dispatch(showEmailVerifyModal("phone"))}
          >
            {"去绑定"}   
          </Button>
        </div>}
        {user.mobile !== "" &&
        <div className={styles.msgWrap}>
        <div className={styles.content}>+86 {user.mobile}</div>
          <Button
            type="primary"
            onClick={() => dispatch(showMobileVerifyModal("phone"))}
          >
            {"修改"}   
          </Button>
      </div>}
      <div className={styles.title}>邮箱</div>
      {user.email === "" &&
        <div className={styles.msgWrap}>
        <div className={styles.content}>{"未绑定"}</div>
          <Button
            type="primary"
            onClick={() => dispatch(showMobileVerifyModal("email"))}
          >
            {"去绑定"}   
          </Button>
        </div>}
        {user.email !== "" &&
        <div className={styles.msgWrap}>
        <div className={styles.content}>{user.email}</div>
          <Button
            type="primary"
            onClick={() => dispatch(showEmailVerifyModal("email"))}
          >
            {"修改"}   
          </Button>
      </div>}
      <div className={styles.title}>密码</div>
      <div className={styles.msgWrap}>
        <div className={styles.content}>{user.password === "" ? "未设置" : "********"}</div>
        {user.mobile !== "" &&
         <Button
         type="primary"
         onClick={() => dispatch(showMobileVerifyModal("password"))}
        >
          {user.password === "" ? "去设置" : "修改"}
        </Button>}
        {user.mobile === "" &&
         <Button
         type="primary"
         onClick={() => dispatch(showEmailVerifyModal("password"))}
        >
          {user.password === "" ? "去设置" : "修改"}
        </Button>}
      </div>
      <div className={styles.title}>性别</div>
      <div className={styles.genderWrap}>
        <div className={styles.checkboxWrap}>
          <Checkbox checked={gender === 1} onClick={() => changeGender(1)} />
          <div className={styles.content}>男</div>
        </div>
        <div className={styles.checkboxWrap}>
          <Checkbox checked={gender === 2} onClick={() => changeGender(2)} />
          <div className={styles.content}>女</div>
        </div>
        <div className={styles.checkboxWrap}>
          <Checkbox checked={gender === 3} onClick={() => changeGender(3)} />
          <div className={styles.content}>保密</div>
        </div>
      </div>
      <div className={styles.title}>年龄（选填）</div>
      <Input
        class={styles.textInput}
        onChange={(e) => {
          saveAge(e.target.value);
        }}
        // onMouseLeave={(e) => {
        //   saveAge(e.target.value);
        // }}
        value={age == -1 ? "" : age}
      />
      <MobileVerifyModal user={user}/>
      <MobileResetModal user={user}/>
      <EmailVerifyModal user={user}/>
      <EmailResetModal user={user}/>
      <PasswordResetModal user={user}/>
    </div>
  );
}

export default BasicInfo;
