import { showLoginPopup } from "../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { prePay } from "../../service/orderService";
import { Button, message } from "antd";
import styles from "./OrderConfirm.module.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { pushAutoBaiduEvent } from "../../utils/baidu";
import { trackClickEvent } from "../../service/trackingService";

const CheckoutButton = ({ payMethod, orderId }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const isIframe = self !== top; /* eslint-disable-line no-restricted-globals */
  const [messageApi, contextHolder] = message.useMessage();

  const handlePurchase = async () => {
    setIsLoading(true);
    trackClickEvent(isIframe, "checkout", orderId);
    pushAutoBaiduEvent(isIframe, { tagA: "checkout", tagB: orderId });
    if (!user || !user.id) {
      dispatch(showLoginPopup());
      setIsLoading(false);
      return;
    }

    try {
      const prePayResponse = await prePay(orderId, payMethod);
      if (
        prePayResponse &&
        payMethod === "alipay" &&
        prePayResponse.normalUrl
      ) {
        window.open(prePayResponse.normalUrl, "_self");
      } else if (
        prePayResponse &&
        payMethod === "pingpong" &&
        prePayResponse.paymentUrl
      ) {
        if (isIframe) {
          window.parent.postMessage(
            "checkouturl_" + encodeURIComponent(prePayResponse.paymentUrl),
            "*",
          );
        } else {
          window.open(prePayResponse.paymentUrl, "_self");
        }
      }
    } catch (e) {
      console.log(e);

      const statusCode = e?.response?.status;
      if (statusCode === 404) {
        messageApi.open({
          type: "error",
          content: t("purchase_duplicate"),
          duration: 5,
        });
      } else {
        messageApi.open({
          type: "error",
          content: t("purchase_fail"),
          duration: 5,
        });
      }
    }
    setIsLoading(false);
  };

  return isLoading ? (
    <>
      {contextHolder}
      <Button type="primary" disabled className={styles.buttonprimarylarge}>
        {t("redirecting")}...
      </Button>
    </>
  ) : (
    <>
      {contextHolder}
      <Button
        type="primary"
        className={styles.buttonprimarylarge}
        onClick={handlePurchase}
      >
        {t("checkout")}
      </Button>
    </>
  );
};

export default CheckoutButton;
