function _pushBaiduEvent({ where = "", tagA = "", tagB = "" }) {
  // eslint-disable-next-line no-undef
  _hmt.push(["_trackEvent", where, tagA, tagB]);
  // _hmt.push(['_trackEvent', 'cms-button', 'click', `text`]);
}

// web的
export function pushWebBaiduEvent({ tagA = "", tagB = "" }) {
  _pushBaiduEvent({ where: "web", tagA, tagB });
}

// 客户端的
export function pushClientBaiduEvent({ tagA = "", tagB = "" }) {
  _pushBaiduEvent({ where: "client", tagA, tagB });
}

export function pushAutoBaiduEvent(isIframe, { tagA = "", tagB = "" }) {
  _pushBaiduEvent({ where: isIframe ? "client" : "web", tagA, tagB });
}

export function setUserId(userId) {
  // eslint-disable-next-line no-undef
  _hmt.push(["_setUserId", userId]);
}

export function clearUserId() {
  // eslint-disable-next-line no-undef
  _hmt.push(["_clearUserId", true]);
}

// example
// pushWebBaiduEvent({tagA: 'home-page-image', tagB: 'xxxid'})
