import styles from "./GamePage.module.css";
import { useTranslation } from "react-i18next";
import { extractGameDeepField } from "../../utils/i18n";

const Statics = ({ gameData, gameItem }) => {
  const { t, i18n } = useTranslation();

  const steam = gameData
    ? t(gameData?.statics.steam)
    : extractGameDeepField(gameItem, i18n.language, "statistics", "steam");
  const ign = gameData
    ? extractGameDeepField(gameData, i18n.language, "statics", "ign")
    : extractGameDeepField(gameItem, i18n.language, "statistics", "ign");
  const heihe = gameData
    ? extractGameDeepField(gameData, i18n.language, "statics", "heiHe")
    : extractGameDeepField(gameItem, i18n.language, "statistics", "heihe");
  const online = gameData
    ? extractGameDeepField(gameData, i18n.language, "statics", "online")
    : extractGameDeepField(gameItem, i18n.language, "statistics", "online");

  return (
    <div className={styles.div1}>
      <div className={styles.div2}>
        <div className={styles.rectangleParent}>
          <div className={styles.instanceChild} />
          <div className={styles.dlc2}>{t("game_static")}</div>
        </div>
      </div>
      <div className={styles.div3}>
        <div className={styles.steam}>
          <div className={styles.button}>{steam}</div>
          <div className={styles.steam1}>{t("steam_comment")}</div>
        </div>
        <div className={styles.div5}>
          <div className={styles.ign}>
            <div className={styles.button}>{ign}</div>
            <div className={styles.steam1}>{t("ign_score")}</div>
          </div>
          <div className={styles.ign}>
            <div className={styles.button}>{heihe}</div>
            <div className={styles.steam1}>{t("black_score")}</div>
          </div>
          <div className={styles.div10}>
            <div className={styles.button}>{online}</div>
            <div className={styles.steam1}>{t("online_count")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statics;
