import { Modal } from "antd";
import ReactJson from "react-json-view";

const ObjectPreviewModal = ({ show, object, onCancel }) => {
  return (
    <Modal title={"Object 预览"} open={show} onCancel={onCancel} footer={null}>
      <ReactJson src={object} collapsed={1} />
    </Modal>
  );
};

export default ObjectPreviewModal;
