import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addItemToWishlistAPI,
  fetchWishlistStatusFromAPI,
  removeItemFromWishlistAPI,
} from "../service/wishlistService";
import { trackClickEventAsync } from "../service/trackingService";

export const fetchWishlistStatus = createAsyncThunk(
  "wishlist/fetchStatus",
  async () => {
    const response = await fetchWishlistStatusFromAPI();
    return response;
  },
);

export const toggleWishlistItem = createAsyncThunk(
  "wishlist/toggleItem",
  async ({ isIframe, itemSlug }, { getState, rejectWithValue }) => {
    try {
      const { wishlist } = getState();
      const isItemInWishlist = wishlist.items.some(
        (item) => item.slug === itemSlug,
      );
      if (isItemInWishlist) {
        await trackClickEventAsync(isIframe, "cancel-wishlist", itemSlug);
      } else {
        await trackClickEventAsync(isIframe, "wishlist", itemSlug);
      }
      const response = isItemInWishlist
        ? await removeItemFromWishlistAPI(itemSlug)
        : await addItemToWishlistAPI(itemSlug);
      return { gameItem: response, isItemInWishlist: !isItemInWishlist };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState: {
    items: [],
    status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWishlistStatus.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchWishlistStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload;
      })
      .addCase(fetchWishlistStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(toggleWishlistItem.pending, (state) => {})
      .addCase(toggleWishlistItem.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { gameItem, isItemInWishlist } = action.payload;
        if (isItemInWishlist) {
          state.items.unshift(gameItem);
        } else {
          state.items = state.items.filter(
            (item) => item.slug !== gameItem.slug,
          );
        }
      })
      .addCase(toggleWishlistItem.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default wishlistSlice.reducer;
