import { createSlice } from "@reduxjs/toolkit";

export const entitlementSlice = createSlice({
  name: "entitlement",
  initialState: null,
  reducers: {
    setEntitlements: (state, action) => action.payload,
  },
});

export const { setEntitlements } = entitlementSlice.actions;

export default entitlementSlice.reducer;
