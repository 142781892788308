import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const HubCheckout = () => {
  const [searchParams] = useSearchParams();

  const paymentUrl = searchParams.get("url");
  const token = searchParams.get("t");

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
    }
    // todo add url check
    if (paymentUrl) {
      window.open(paymentUrl, "_self");
    } else {
      window.open("/", "_self");
    }
  }, []);

  return <div></div>;
};

export default HubCheckout;
