const ImageBase = `${process.env.PUBLIC_URL}/images/`;
const MarkdownBase = `${process.env.PUBLIC_URL}/markdown/`;
const AvatarFolder = "avatar/";
const MiwuFolder = "miwu/";
const MantingfangFolder = "mantingfang/";
const WuyuFolder = "wuyu/";
const DustFolder = "dust/";
const BlueFolder = "blue/";

const getImageBaseUrl = () => {
  let ImageBaseUrl = ImageBase;
  if (
    process.env.REACT_APP_CDN_BASE &&
    process.env.REACT_APP_CDN_IMAGE_FOLDER
  ) {
    ImageBaseUrl = `${process.env.REACT_APP_CDN_BASE}${process.env.REACT_APP_CDN_IMAGE_FOLDER}`;
  }
  return ImageBaseUrl;
};

const getMarkdownBaseUrl = () => {
  let MarkdownBaseUrl = MarkdownBase;
  if (process.env.REACT_APP_CDN_BASE) {
    MarkdownBaseUrl = `${process.env.REACT_APP_CDN_BASE}markdown/`;
  }
  return MarkdownBaseUrl;
};

const ImageBaseUrl = getImageBaseUrl();
const avatarBaseUrl = ImageBaseUrl + AvatarFolder;
const wuyuBaseUrl = ImageBaseUrl + WuyuFolder;
const miwuBaseUrl = ImageBaseUrl + MiwuFolder;
const mantingfangBaseUrl = ImageBaseUrl + MantingfangFolder;
const dustBaseUrl = ImageBaseUrl + DustFolder;
const blueBaseUrl = ImageBaseUrl + BlueFolder;

const Avatar01 = avatarBaseUrl + "Avatar01.webp";
const Avatar02 = avatarBaseUrl + "Avatar02.webp";
const Avatar03 = avatarBaseUrl + "Avatar03.webp";
const Avatar04 = avatarBaseUrl + "Avatar04.webp";
const Avatar05 = avatarBaseUrl + "Avatar05.webp";
const Avatar06 = avatarBaseUrl + "Avatar06.webp";
const Avatar07 = avatarBaseUrl + "Avatar07.webp";
const Avatar08 = avatarBaseUrl + "Avatar08.webp";
const Avatar09 = avatarBaseUrl + "Avatar09.webp";
const Avatar10 = avatarBaseUrl + "Avatar10.webp";

const main1IconWuyu = wuyuBaseUrl + "main1-icon.webp";
const main1Wuyu = wuyuBaseUrl + "main1.webp";
const main2IconWuyu = wuyuBaseUrl + "main2-icon.webp";
const main2Wuyu = wuyuBaseUrl + "main2.webp";
const main3IconWuyu = wuyuBaseUrl + "main3-icon.webp";
const main3Wuyu = wuyuBaseUrl + "main3.webp";
const main4IconWuyu = wuyuBaseUrl + "main4-icon.webp";
const main4Wuyu = wuyuBaseUrl + "main4.webp";
const main5IconWuyu = wuyuBaseUrl + "main5-icon.webp";
const main5Wuyu = wuyuBaseUrl + "main5.webp";
const moreWuyu = wuyuBaseUrl + "more1.webp";
const sideMoreWuyu = wuyuBaseUrl + "sideRecommand.webp";

const main1IconDust = dustBaseUrl + "main-icon1.webp";
const main1Dust = dustBaseUrl + "main1.webp";
const main2IconDust = dustBaseUrl + "main-icon2.webp";
const main2Dust = dustBaseUrl + "main2.webp";
const main3IconDust = dustBaseUrl + "main-icon3.webp";
const main3Dust = dustBaseUrl + "main3.webp";
const main4IconDust = dustBaseUrl + "main-icon4.webp";
const main4Dust = dustBaseUrl + "main4.webp";
const main5IconDust = dustBaseUrl + "main-icon5.webp";
const main5Dust = dustBaseUrl + "main5.webp";
const main6IconDust = dustBaseUrl + "main-icon6.webp";
const main6Dust = dustBaseUrl + "main6.webp";
const main7IconDust = dustBaseUrl + "main-icon7.webp";
const main7Dust = dustBaseUrl + "main7.webp";
const main8IconDust = dustBaseUrl + "main-icon8.webp";
const main8Dust = dustBaseUrl + "main8.webp";
const main9IconDust = dustBaseUrl + "main-icon9.webp";
const main9Dust = dustBaseUrl + "main9.webp";
const main10IconDust = dustBaseUrl + "main-icon10.webp";
const main10Dust = dustBaseUrl + "main10.webp";
const main11IconDust = dustBaseUrl + "main-icon11.webp";
const main11Dust = dustBaseUrl + "main11.webp";
const main12IconDust = dustBaseUrl + "main-icon12.webp";
const main12Dust = dustBaseUrl + "main12.webp";
const main13IconDust = dustBaseUrl + "main-icon13.webp";
const main13Dust = dustBaseUrl + "main13.webp";
const main14IconDust = dustBaseUrl + "main-icon14.webp";
const main14Dust = dustBaseUrl + "main14.webp";
const main15IconDust = dustBaseUrl + "main-icon15.webp";
const main15Dust = dustBaseUrl + "main15.webp";
const main16IconDust = dustBaseUrl + "main-icon16.webp";
const main16Dust = dustBaseUrl + "main16.webp";
const main17IconDust = dustBaseUrl + "main-icon17.webp";
const main17Dust = dustBaseUrl + "main17.webp";
const moreDust = dustBaseUrl + "landscapehub.webp";
const sideMoreDust = dustBaseUrl + "sideRecommand.webp";

const main1IconBlue = blueBaseUrl + "main-icon1.webp";
const main1Blue = blueBaseUrl + "main1.webp";
const main2IconBlue = blueBaseUrl + "main-icon2.webp";
const main2Blue = blueBaseUrl + "main2.webp";
const main3IconBlue = blueBaseUrl + "main-icon3.webp";
const main3Blue = blueBaseUrl + "main3.webp";
const main4IconBlue = blueBaseUrl + "main-icon4.webp";
const main4Blue = blueBaseUrl + "main4.webp";
const main5IconBlue = blueBaseUrl + "main-icon5.webp";
const main5Blue = blueBaseUrl + "main5.webp";
const main6IconBlue = blueBaseUrl + "main-icon6.webp";
const main6Blue = blueBaseUrl + "main6.webp";
const main7IconBlue = blueBaseUrl + "main-icon7.webp";
const main7Blue = blueBaseUrl + "main7.webp";
const main8IconBlue = blueBaseUrl + "main-icon8.webp";
const main8Blue = blueBaseUrl + "main8.webp";
const moreBlue = blueBaseUrl + "landscapehub.webp";
const sideMoreBlue = blueBaseUrl + "sideRecommand.webp";

const main1IconMiwu = miwuBaseUrl + "main1-icon.webp";
const main1Miwu = miwuBaseUrl + "main1.webp";
const main2IconMiwu = miwuBaseUrl + "main2-icon.webp";
const main2Miwu = miwuBaseUrl + "main2.webp";
const main3IconMiwu = miwuBaseUrl + "main3-icon.webp";
const main3Miwu = miwuBaseUrl + "main3.webp";
const main4IconMiwu = miwuBaseUrl + "main4-icon.webp";
const main4Miwu = miwuBaseUrl + "main4.webp";
const main5IconMiwu = miwuBaseUrl + "main5-icon.webp";
const main5Miwu = miwuBaseUrl + "main5.webp";
const main6IconMiwu = miwuBaseUrl + "main6-icon.webp";
const main6Miwu = miwuBaseUrl + "main6.webp";
const main7IconMiwu = miwuBaseUrl + "main7-icon.webp";
const main7Miwu = miwuBaseUrl + "main7.webp";
const moreMiwu = miwuBaseUrl + "more.webp";
const sideMoreMiwu = miwuBaseUrl + "sideReommand.webp";

const main1Mantingfang = mantingfangBaseUrl + "detail1.webp";
const main2Mantingfang = mantingfangBaseUrl + "detail2.webp";
const main3Mantingfang = mantingfangBaseUrl + "detail3.webp";
const main4Mantingfang = mantingfangBaseUrl + "detail4.webp";
const main5Mantingfang = mantingfangBaseUrl + "detail5.webp";
const main6Mantingfang = mantingfangBaseUrl + "detail6.webp";
const main7Mantingfang = mantingfangBaseUrl + "detail7.webp";
const main8Mantingfang = mantingfangBaseUrl + "detail8.webp";
const main9Mantingfang = mantingfangBaseUrl + "detail9.webp";
const main10Mantingfang = mantingfangBaseUrl + "detail10.webp";
const main11Mantingfang = mantingfangBaseUrl + "detail11.webp";
const main12Mantingfang = mantingfangBaseUrl + "detail12.webp";
const moreMantingfang = mantingfangBaseUrl + "more.webp";
const sideMoreMantingfang = mantingfangBaseUrl + "sideRecommand.webp";

const wuyuDetailMdUrl = getMarkdownBaseUrl() + "wuyu-detail.md";
const miwuDetailMdUrl = getMarkdownBaseUrl() + "miwu-detail.md";
const mantingfangDetailMdUrl = getMarkdownBaseUrl() + "mantingfang-detail.md";
const blueDetailMdUrl = getMarkdownBaseUrl() + "blue-detail.md";
const dustDetailMdUrl = getMarkdownBaseUrl() + "dust-detail.md";
const wuyuDetailMdUrlZhHant = getMarkdownBaseUrl() + "wuyu-detail-zhHant.md";
const miwuDetailMdUrlZhHant = getMarkdownBaseUrl() + "miwu-detail-zhHant.md";
const mantingfangDetailMdUrlZhHant =
  getMarkdownBaseUrl() + "mantingfang-detail-zhHant.md";
const blueDetailMdUrlZhHant = getMarkdownBaseUrl() + "blue-detail-zhHant.md";
const dustDetailMdUrlZhHant = getMarkdownBaseUrl() + "dust-detail-zhHant.md";

export const AvatarMap = [
  Avatar01,
  Avatar02,
  Avatar03,
  Avatar04,
  Avatar05,
  Avatar06,
  Avatar07,
  Avatar08,
  Avatar09,
  Avatar10,
];

const GameMap = {
  dust: {
    coverIcon: moreDust,
    slug: "dust",
    isAdultGame: process.env.REACT_APP_API_ENV !== "prod",
    statics: {
      steam: "steam_good",
      ign: "8.3",
      heiHe: "1万",
      online: "164",
    },
    configuration: {
      base: {
        os: "Windows 7/Windows 10",
        processor: "Intel Core i3",
        ram: "1 GB RAM",
        graphic: "Geforce GT 760",
        disk: "需要 2 GB 可用空间",
      },
    },
    price: "¥48",
    originPrice: "¥99",
    discount: "-60%",
    intel: {
      zhHant: {
        name: "塵末(Dust to the End)",
        tags: ["模擬", "手繪", "末日", "冒險", "貿易", "開放世界"],
        brief:
          "《塵末（Dust to the end）》是一款末日廢土跑商經營遊戲，遊戲集廢土生存跑商、回合製戰鬥、基地建設等多種玩法於一體。 玩家自塵封百年的避難所回歸到末世廢土，曾經的文明秩序早已蕩然無存，面對橫行盜匪、變種野獸以及隨時出現的天災氣候，組建商隊在這片廢土上開闢你的商業帝國 。",
        developer: "Haojoy Game, Zjoy Game",
        publisher: "2P Games",
        releaseDate: "2021年8月11日",
        detailMarkdownUrl: dustDetailMdUrlZhHant,
        statics: {
          steam: "steam_good",
          ign: "8.3",
          heiHe: "1萬",
          online: "164",
        },
        configuration: {
          base: {
            os: "Windows 7/Windows 10",
            processor: "Intel Core i3",
            ram: "1 GB RAM",
            graphic: "Geforce GT 760",
            disk: "需要 2 GB 可用空間",
          },
        },
        recommend: [
          {
            slug: "miwu",
            price: "¥39.9",
            originPrice: "¥99.9",
            discount: "-60%",
            name: "迷霧偵探",
            link: "/game/miwu",
            image: sideMoreMiwu,
          },
          {
            slug: "wuyu",
            price: "¥39.9",
            originPrice: "¥99.9",
            discount: "-60%",
            name: "森久城物語",
            link: "/game/wuyu",
            image: sideMoreWuyu,
          },
          {
            slug: "blue",
            name: "憂鬱星期三",
            link: "/game/blue",
            image: sideMoreBlue,
          },
          {
            slug: "mantingfang",
            price: "¥39.9",
            originPrice: "¥99.9",
            discount: "-60%",
            name: "滿庭芳：宋上繁華",
            link: "/game/mantingfang",
            image: sideMoreMantingfang,
          },
        ],
      },
    },
    name: "尘末(Dust to the End)",
    enName: "Dust to the End",
    tags: ["模拟", "手绘", "后末日", "冒险", "贸易", "开放世界"],
    brief:
      "《尘末（Dust to the end）》是一款末日废土跑商经营游戏，游戏集废土生存跑商、回合制战斗、基地建设等多种玩法于一体。玩家自尘封百年的避难所回归到末世废土，曾经的文明秩序早已荡然无存，面对横行盗匪、变异野兽以及随时出现的天灾气候，组建商队在这片废土上开辟你的商业帝国。",
    developer: "Haojoy Game, Zjoy Game",
    publisher: "2P Games",
    releaseDate: "2021年8月11日",
    images: [
      {
        Icon: main1IconDust,
        Main: main1Dust,
      },
      {
        Icon: main2IconDust,
        Main: main2Dust,
      },
      {
        Icon: main3IconDust,
        Main: main3Dust,
      },
      {
        Icon: main4IconDust,
        Main: main4Dust,
      },
      {
        Icon: main5IconDust,
        Main: main5Dust,
      },
      {
        Icon: main6IconDust,
        Main: main6Dust,
      },
      {
        Icon: main7IconDust,
        Main: main7Dust,
      },
      {
        Icon: main8IconDust,
        Main: main8Dust,
      },
      {
        Icon: main9IconDust,
        Main: main9Dust,
      },
      {
        Icon: main10IconDust,
        Main: main10Dust,
      },
      {
        Icon: main11IconDust,
        Main: main11Dust,
      },
      {
        Icon: main12IconDust,
        Main: main12Dust,
      },
      {
        Icon: main13IconDust,
        Main: main13Dust,
      },
      {
        Icon: main14IconDust,
        Main: main14Dust,
      },
      {
        Icon: main15IconDust,
        Main: main15Dust,
      },
      {
        Icon: main16IconDust,
        Main: main16Dust,
      },
      {
        Icon: main17IconDust,
        Main: main17Dust,
      },
    ],
    recommend: [
      {
        slug: "miwu",
        price: "¥39.9",
        originPrice: "¥99.9",
        discount: "-60%",
        name: "迷雾侦探",
        link: "/game/miwu",
        image: sideMoreMiwu,
      },
      {
        slug: "wuyu",
        price: "¥39.9",
        originPrice: "¥99.9",
        discount: "-60%",
        name: "森久城物语",
        link: "/game/wuyu",
        image: sideMoreWuyu,
      },
      {
        slug: "blue",
        name: "忧郁星期三",
        link: "/game/blue",
        image: sideMoreBlue,
      },
      {
        slug: "mantingfang",
        price: "¥39.9",
        originPrice: "¥99.9",
        discount: "-60%",
        name: "满庭芳：宋上繁华",
        link: "/game/mantingfang",
        image: sideMoreMantingfang,
      },
    ],
    detailMarkdownUrl: dustDetailMdUrl,
    comments: [
      {
        userAvatar: Avatar03,
        userName: "WastelandWalker",
        userNameZhHant: "WastelandWalker",
        comment:
          "《尘末》真是一场末日冒险的绝佳体验。跑商和战斗的结合让我时刻保持兴奋。每次遇到盗匪都是一次新挑战！",
        commentZhHant:
          "《尘末》真是一场末日冒险的绝佳体验。跑商和战斗的结合让我时刻保持兴奋。每次遇到盗匪都是一次新挑战！",
      },
      {
        userAvatar: Avatar04,
        userName: "SurvivorSam",
        userNameZhHant: "SurvivorSam",
        comment:
          "游戏的基地建设元素真的很吸引人。能在这样的废土环境中创建自己的避风港，感觉就像是在重建文明一样。",
        commentZhHant:
          "游戏的基地建设元素真的很吸引人。能在这样的废土环境中创建自己的避风港，感觉就像是在重建文明一样。",
      },
      {
        userAvatar: Avatar05,
        userName: "MutantHunter",
        userNameZhHant: "MutantHunter",
        comment:
          "《尘末》的战斗系统非常有深度，每一次回合制战斗都让我全神贯注。变异野兽的设计也很有特色，挑战性十足。",
        commentZhHant:
          "《尘末》的战斗系统非常有深度，每一次回合制战斗都让我全神贯注。变异野兽的设计也很有特色，挑战性十足。",
      },
      {
        userAvatar: Avatar06,
        userName: "DustDealer",
        userNameZhHant: "DustDealer",
        comment:
          "我喜欢游戏中的经营策略元素，从一个小商队发展到商业帝国的过程既有趣又充满挑战。",
        commentZhHant:
          "我喜欢游戏中的经营策略元素，从一个小商队发展到商业帝国的过程既有趣又充满挑战。",
      },
      {
        userAvatar: Avatar07,
        userName: "ApocalypseAva",
        userNameZhHant: "ApocalypseAva",
        comment:
          "游戏的废土氛围做得很好，给人一种真正身处末日世界的感觉。天灾气候的随机事件增加了不少惊喜和挑战。",
        commentZhHant:
          "游戏的废土氛围做得很好，给人一种真正身处末日世界的感觉。天灾气候的随机事件增加了不少惊喜和挑战。",
      },
      {
        userAvatar: Avatar08,
        userName: "ShelterSeeker",
        userNameZhHant: "ShelterSeeker",
        comment:
          "从避难所出发的故事情节很吸引人，让人感到既新奇又怀旧。在废土上探索旧世界的遗迹，每次都有新发现。",
        commentZhHant:
          "从避难所出发的故事情节很吸引人，让人感到既新奇又怀旧。在废土上探索旧世界的遗迹，每次都有新发现。",
      },
      {
        userAvatar: Avatar09,
        userName: "BanditBoss",
        userNameZhHant: "BanditBoss",
        comment:
          "游戏中的盗匪和其他敌人设计得很有个性，每次遭遇战都是一次新的故事。",
        commentZhHant:
          "游戏中的盗匪和其他敌人设计得很有个性，每次遭遇战都是一次新的故事。",
      },
      {
        userAvatar: Avatar10,
        userName: "WastelandTrader",
        userNameZhHant: "WastelandTrader",
        comment:
          "我喜欢如何在游戏中通过交易和探索获得资源，这增加了游戏的重玩价值和深度。",
        commentZhHant:
          "我喜欢如何在游戏中通过交易和探索获得资源，这增加了游戏的重玩价值和深度。",
      },
      {
        userAvatar: Avatar01,
        userName: "DustSurvivor",
        userNameZhHant: "DustSurvivor",
        comment:
          "《尘末》的图形和音效都很出色，为废土环境增添了很多真实感。每次游玩都是一次视觉和听觉的享受。",
        commentZhHant:
          "《尘末》的图形和音效都很出色，为废土环境增添了很多真实感。每次游玩都是一次视觉和听觉的享受。",
      },
      {
        userAvatar: Avatar02,
        userName: "EndTimesExplorer",
        userNameZhHant: "EndTimesExplorer",
        comment:
          "这款游戏让我沉浸在一个充满危险和机遇的世界中。每次探索都充满未知，非常刺激。",
        commentZhHant:
          "这款游戏让我沉浸在一个充满危险和机遇的世界中。每次探索都充满未知，非常刺激。",
      },
    ],
  },
  blue: {
    coverIcon: moreBlue,
    slug: "blue",
    isAdultGame: process.env.REACT_APP_API_ENV !== "prod",
    statics: {
      steam: "steam_good",
      ign: "8.2",
      heiHe: "1840",
      online: "89",
    },
    configuration: {
      base: {
        os: "Windows 10",
        processor: "Intel Core i3-540 | AMD FX-4350",
        ram: "4 GB RAM",
        graphic: "Nvidia GeForce GTX 650, 1 GB | AMD Radeon HD 7870, 2 GB",
        disk: "需要 6 GB 可用空间",
      },
      recommend: {
        os: "Windows 10",
        processor: "Intel Core i5-6600 | AMD FX-8350",
        ram: "8 GB RAM",
        graphic: "Nvidia GeForce GTX 1070, 8 GB | AMD Radeon RX 580, 4 GB",
        disk: "需要 6 GB 可用空间",
      },
    },
    price: "¥70",
    originPrice: "¥99",
    discount: "-60%",
    intel: {
      zhHant: {
        name: "憂鬱星期三",
        tags: ["指向點擊", "放鬆", "選擇取向", "劇情豐富", "2D"],
        brief:
          "喜歡舒適的遊戲嗎？ 《憂鬱星期三》是一款關於爵士樂、愛情與失敗的敘事冒險遊戲。 探索繁華都市，發現人物故事，收集珍貴音樂專輯。 你的選擇，你的爵士樂之旅。",
        developer: "Buff Studio",
        publisher: "Buff Studio",
        releaseDate: "2023年8月28日",
        detailMarkdownUrl: blueDetailMdUrlZhHant,
        statics: {
          steam: "steam_good",
          ign: "8.3",
          heiHe: "4萬",
          online: "3758",
        },
        configuration: {
          base: {
            os: "Windows 10",
            processor: "Intel Core i3-540 | AMD FX-4350",
            ram: "4 GB RAM",
            graphic: "Nvidia GeForce GTX 650, 1 GB | AMD Radeon HD 7870, 2 GB",
            disk: "需要 6 GB 可用空間",
          },
          recommend: {
            os: "Windows 10",
            processor: "Intel Core i5-6600 | AMD FX-8350",
            ram: "8 GB RAM",
            graphic: "Nvidia GeForce GTX 1070, 8 GB | AMD Radeon RX 580, 4 GB",
            disk: "需要 6 GB 可用空間",
          },
        },
        recommend: [
          {
            slug: "miwu",
            price: "¥39.9",
            originPrice: "¥99.9",
            discount: "-60%",
            name: "迷霧偵探",
            link: "/game/miwu",
            image: sideMoreMiwu,
          },
          {
            slug: "wuyu",
            price: "¥39.9",
            originPrice: "¥99.9",
            discount: "-60%",
            name: "森久城物語",
            link: "/game/wuyu",
            image: sideMoreWuyu,
          },
          {
            slug: "dust",
            name: "塵末(Dust to the End)",
            link: "/game/dust",
            image: sideMoreDust,
          },
          {
            slug: "mantingfang",
            price: "¥39.9",
            originPrice: "¥99.9",
            discount: "-60%",
            name: "滿庭芳：宋上繁華",
            link: "/game/mantingfang",
            image: sideMoreMantingfang,
          },
        ],
      },
    },
    name: "忧郁星期三",
    enName: "Blue Wednesday",
    tags: ["指向点击", "放松", "选择取向", "剧情丰富", "2D"],
    brief:
      "喜欢舒适的游戏吗？《忧郁星期三》是一款关于爵士乐、爱情和失败的叙事冒险游戏。探索繁华都市，发现人物故事，收集珍贵音乐专辑。你的选择，你的爵士乐之旅。",
    developer: "Buff Studio",
    publisher: "Buff Studio",
    releaseDate: "2023年8月28日",
    images: [
      {
        Icon: main1IconBlue,
        Main: main1Blue,
      },
      {
        Icon: main2IconBlue,
        Main: main2Blue,
      },
      {
        Icon: main3IconBlue,
        Main: main3Blue,
      },
      {
        Icon: main1IconBlue,
        Main: main1Blue,
      },
      {
        Icon: main4IconBlue,
        Main: main4Blue,
      },
      {
        Icon: main5IconBlue,
        Main: main5Blue,
      },
      {
        Icon: main6IconBlue,
        Main: main6Blue,
      },
      {
        Icon: main7IconBlue,
        Main: main7Blue,
      },
      {
        Icon: main8IconBlue,
        Main: main8Blue,
      },
    ],
    recommend: [
      {
        slug: "miwu",
        price: "¥39.9",
        originPrice: "¥99.9",
        discount: "-60%",
        name: "迷雾侦探",
        link: "/game/miwu",
        image: sideMoreMiwu,
      },
      {
        slug: "wuyu",
        price: "¥39.9",
        originPrice: "¥99.9",
        discount: "-60%",
        name: "森久城物语",
        link: "/game/wuyu",
        image: sideMoreWuyu,
      },
      {
        slug: "dust",
        name: "尘末(Dust to the End)",
        link: "/game/dust",
        image: sideMoreDust,
      },
      {
        slug: "mantingfang",
        price: "¥39.9",
        originPrice: "¥99.9",
        discount: "-60%",
        name: "满庭芳：宋上繁华",
        link: "/game/mantingfang",
        image: sideMoreMantingfang,
      },
    ],
    detailMarkdownUrl: blueDetailMdUrl,
    comments: [
      {
        userAvatar: Avatar03,
        userName: "JazzJourneyer",
        userNameZhHant: "JazzJourneyer",
        comment:
          "《忧郁星期三》让我完全沉浸在爵士乐的魅力中。游戏中的每一个选择都像是在编织自己的音乐旅程。",
        commentZhHant:
          "《忧郁星期三》让我完全沉浸在爵士乐的魅力中。游戏中的每一个选择都像是在编织自己的音乐旅程。",
      },
      {
        userAvatar: Avatar04,
        userName: "UrbanExplorer",
        userNameZhHant: "UrbanExplorer",
        comment:
          "探索游戏中的都市环境令人着迷，每个角落都有自己的故事，仿佛真的置身于繁华都市中。",
        commentZhHant:
          "探索游戏中的都市环境令人着迷，每个角落都有自己的故事，仿佛真的置身于繁华都市中。",
      },
      {
        userAvatar: Avatar05,
        userName: "MelodySeeker",
        userNameZhHant: "MelodySeeker",
        comment:
          "收集游戏中的音乐专辑是一次独特的体验，每张专辑都带来了不同的情感和故事。",
        commentZhHant:
          "收集游戏中的音乐专辑是一次独特的体验，每张专辑都带来了不同的情感和故事。",
      },
      {
        userAvatar: Avatar06,
        userName: "JazzAfficionado",
        userNameZhHant: "JazzAfficionado",
        comment:
          "游戏中关于爱情和失败的故事让人深有共鸣，它以一种独特的方式触动了我的心弦。",
        commentZhHant:
          "游戏中关于爱情和失败的故事让人深有共鸣，它以一种独特的方式触动了我的心弦。",
      },
      {
        userAvatar: Avatar07,
        userName: "CityWanderer",
        userNameZhHant: "CityWanderer",
        comment:
          "作为一名爵士乐爱好者，我被游戏中丰富的爵士乐元素所吸引。每一段音乐都是一次美妙的听觉盛宴。",
        commentZhHant:
          "作为一名爵士乐爱好者，我被游戏中丰富的爵士乐元素所吸引。每一段音乐都是一次美妙的听觉盛宴。",
      },
      {
        userAvatar: Avatar08,
        userName: "StoryCollector",
        userNameZhHant: "StoryCollector",
        comment:
          "在这款游戏中漫步于都市的感觉很棒，每次探索都发现了新的东西，仿佛在现实中游历一般。",
        commentZhHant:
          "在这款游戏中漫步于都市的感觉很棒，每次探索都发现了新的东西，仿佛在现实中游历一般。",
      },
      {
        userAvatar: Avatar09,
        userName: "UrbanJazzFan",
        userNameZhHant: "UrbanJazzFan",
        comment:
          "我喜欢如何通过与人物的互动来发现他们的故事。这些故事增添了游戏的深度和情感。",
        commentZhHant:
          "我喜欢如何通过与人物的互动来发现他们的故事。这些故事增添了游戏的深度和情感。",
      },
      {
        userAvatar: Avatar09,
        userName: "Heartstrings",
        userNameZhHant: "Heartstrings",
        comment:
          "《忧郁星期三》带来了一种独特的忧郁美感，游戏的叙事和音乐完美融合，创造了一个难忘的体验。",
        commentZhHant:
          "《忧郁星期三》带来了一种独特的忧郁美感，游戏的叙事和音乐完美融合，创造了一个难忘的体验。",
      },
      {
        userAvatar: Avatar09,
        userName: "UrbanJazzFan",
        userNameZhHant: "UrbanJazzFan",
        comment:
          "游戏中的音乐选择对我产生了深刻的影响，每一首歌都让我感受到不同的情绪和故事。",
        commentZhHant:
          "游戏中的音乐选择对我产生了深刻的影响，每一首歌都让我感受到不同的情绪和故事。",
      },
      {
        userAvatar: Avatar09,
        userName: "UrbanJazzFan",
        userNameZhHant: "UrbanJazzFan",
        comment:
          "游戏对都市生活和爵士乐文化的描绘令人印象深刻，每个细节都充满了爱。",
        commentZhHant:
          "游戏对都市生活和爵士乐文化的描绘令人印象深刻，每个细节都充满了爱。",
      },
    ],
  },
  mantingfang: {
    coverIcon: moreMantingfang,
    slug: "mantingfang",
    isAdultGame: process.env.REACT_APP_API_ENV !== "prod",
    statics: {
      steam: "steam_good",
      ign: "8.3",
      heiHe: "4万",
      online: "3758",
    },
    configuration: {
      base: {
        os: "Windows 10",
        processor: "Intel Core i5-4590",
        ram: "16 GB RAM",
        graphic: "GeForce GTX 750ti",
        disk: "需要 2 GB 可用空间",
      },
      recommend: {
        os: "Windows 10",
        processor: "Intel Core i7-8700k",
        ram: "32 GB RAM",
        graphic: "GeForce GTX 1060",
        disk: "需要 5 GB 可用空间",
      },
    },
    price: "¥39.9",
    originPrice: "¥99.9",
    discount: "-60%",
    intel: {
      zhHant: {
        name: "滿庭芳：宋上繁華",
        tags: ["休閒", "獨立", "模擬", "策略"],
        brief:
          "公元960年，趙宋王朝建立，社會百廢待興。作為新上任的知縣大人，你將帶領百姓開荒建城，振興百業，打造盛世繁華！車水馬龍，士農工商，看那酒樓茶坊遍布大街小巷，勾欄瓦舍唱盡人世悲歡。然而歌舞昇平背後則是暗藏的危機。面對黨閥相爭、外敵入侵等重重困境，在時代的洪流中你將如何抉擇？",
        developer: "White Star Studio",
        publisher: "IndieArk",
        releaseDate: "2023年4月21日",
        detailMarkdownUrl: mantingfangDetailMdUrlZhHant,
        statics: {
          steam: "steam_good",
          ign: "8.3",
          heiHe: "4萬",
          online: "3758",
        },
        configuration: {
          base: {
            os: "Windows 10",
            processor: "Intel Core i5-4590",
            ram: "16 GB RAM",
            graphic: "GeForce GTX 750ti",
            disk: "需要 2 GB 可用空間",
          },
          recommend: {
            os: "Windows 10",
            processor: "Intel Core i7-8700k",
            ram: "32 GB RAM",
            graphic: "GeForce GTX 1060",
            disk: "需要 5 GB 可用空間",
          },
        },
        recommend: [
          {
            slug: "miwu",
            price: "¥39.9",
            originPrice: "¥99.9",
            discount: "-60%",
            name: "迷霧偵探",
            link: "/game/miwu",
            image: sideMoreMiwu,
          },
          {
            slug: "wuyu",
            price: "¥39.9",
            originPrice: "¥99.9",
            discount: "-60%",
            name: "森久城物語",
            link: "/game/wuyu",
            image: sideMoreWuyu,
          },
          {
            slug: "dust",
            name: "塵末(Dust to the End)",
            link: "/game/dust",
            image: sideMoreDust,
          },
          {
            slug: "blue",
            name: "憂鬱星期三",
            link: "/game/blue",
            image: sideMoreBlue,
          },
        ],
      },
    },
    name: "满庭芳：宋上繁华",
    enName: "Thriving City: Song",
    tags: ["休闲", "独立", "模拟", "迷宫探索", "策略"],
    brief:
      "公元960年，赵宋王朝建立，社会百废待兴。作为新上任的知县大人，你将带领百姓开荒建城，振兴百业，打造盛世繁华！ 车水马龙，士农工商，看那酒楼茶坊遍布大街小巷，勾栏瓦舍唱尽人世悲欢。然而歌舞升平背后则是暗藏的危机。面对党阀相争、外敌入侵等重重困境，在时代的洪流中你将如何抉择？",
    developer: "White Star Studio",
    publisher: "IndieArk",
    releaseDate: "2023年4月21日",
    images: [
      {
        Icon: main1Mantingfang,
        Main: main1Mantingfang,
      },
      {
        Icon: main2Mantingfang,
        Main: main2Mantingfang,
      },
      {
        Icon: main3Mantingfang,
        Main: main3Mantingfang,
      },
      {
        Icon: main4Mantingfang,
        Main: main4Mantingfang,
      },
      {
        Icon: main5Mantingfang,
        Main: main5Mantingfang,
      },
      {
        Icon: main6Mantingfang,
        Main: main6Mantingfang,
      },
      {
        Icon: main7Mantingfang,
        Main: main7Mantingfang,
      },
      {
        Icon: main8Mantingfang,
        Main: main8Mantingfang,
      },
      {
        Icon: main1Mantingfang,
        Main: main1Mantingfang,
      },
      {
        Icon: main9Mantingfang,
        Main: main9Mantingfang,
      },
      {
        Icon: main10Mantingfang,
        Main: main10Mantingfang,
      },
      {
        Icon: main11Mantingfang,
        Main: main11Mantingfang,
      },
      {
        Icon: main12Mantingfang,
        Main: main12Mantingfang,
      },
    ],
    recommend: [
      {
        slug: "miwu",
        price: "¥39.9",
        originPrice: "¥99.9",
        discount: "-60%",
        name: "迷雾侦探",
        link: "/game/miwu",
        image: sideMoreMiwu,
      },
      {
        slug: "wuyu",
        price: "¥39.9",
        originPrice: "¥99.9",
        discount: "-60%",
        name: "森久城物语",
        link: "/game/wuyu",
        image: sideMoreWuyu,
      },
      {
        slug: "dust",
        name: "尘末(Dust to the End)",
        link: "/game/dust",
        image: sideMoreDust,
      },
      {
        slug: "blue",
        name: "忧郁星期三",
        link: "/game/blue",
        image: sideMoreBlue,
      },
    ],
    detailMarkdownUrl: mantingfangDetailMdUrl,
    comments: [
      {
        userAvatar: Avatar03,
        userName: "LotusGamer88",
        userNameZhHant: "LotusGamer88",
        comment:
          "《满庭芳：宋上繁华》是一款让人沉浸在宋代文化中的游戏。游戏的细节处理令人赞叹，从街道布局到角色服饰，每一处都透露出浓厚的历史氛围。玩家能够深入体验宋代的日常生活，非常推荐！",
        commentZhHant:
          "《滿庭芳：宋上繁華》是一款讓人沉浸在宋代文化中的遊戲。遊戲的細節處理令人讚嘆，從街道布局到角色服飾，每一處都透露出濃厚的歷史氛圍。玩家能夠深入體驗宋代的日常生活，非常推薦！",
      },
      {
        userAvatar: Avatar04,
        userName: "DynastyExplorer",
        userNameZhHant: "DynastyExplorer",
        comment:
          "这款游戏不仅仅是一次历史的穿越，更是一次文化的深度体验。游戏中的任务设计巧妙，故事情节吸引人，让人在玩耍的同时对宋代文化有了更深的理解。",
        commentZhHant:
          "這款遊戲不僅僅是一次歷史的穿越，更是一次文化的深度體驗。遊戲中的任務設計巧妙，故事情節吸引人，讓人在玩耍的同時對宋代文化有了更深的理解。",
      },
      {
        userAvatar: Avatar05,
        userName: "AncientTimesFan",
        userNameZhHant: "AncientTimesFan",
        comment:
          "《满庭芳：宋上繁华》真的让我惊喜，游戏的画面精美，角色丰富多彩，每个任务都像是在讲述一个小故事，非常有趣。特别是背景音乐，非常符合宋代的氛围。",
        commentZhHant:
          "《滿庭芳：宋上繁華》真的讓我驚喜，遊戲的畫面精美，角色豐富多彩，每個任務都像是在講述一個小故事，非常有趣。特別是背景音樂，非常符合宋代的氛圍。",
      },
      {
        userAvatar: Avatar06,
        userName: "SongDynastyLover",
        userNameZhHant: "SongDynastyLover",
        comment:
          "这款游戏对宋代的再现堪称完美。无论是建筑风格、人物对话还是整个故事背景，都让人感觉置身于那个时代。每次游玩都有新的发现，非常推荐给喜欢历史的玩家。",
        commentZhHant:
          "這款遊戲對宋代的再現堪稱完美。無論是建築風格、人物對話還是整個故事背景，都讓人感覺置身於那個時代。每次遊玩都有新的發現，非常推薦給喜歡歷史的玩家。",
      },
      {
        userAvatar: Avatar07,
        userName: "HistoricJourney",
        userNameZhHant: "HistoricJourney",
        comment:
          "作为一个历史游戏爱好者，我对《满庭芳：宋上繁华》给予高度评价。游戏中的历史细节处理得非常到位，让玩家能够真实感受到宋代的生活方式和文化氛围。",
        commentZhHant:
          "作為一個歷史遊戲愛好者，我對《滿庭芳：宋上繁華》給予高度評價。遊戲中的歷史細節處理得非常到位，讓玩家能夠真實感受到宋代的生活方式和文化氛圍。",
      },
      {
        userAvatar: Avatar08,
        userName: "CulturalGamer",
        userNameZhHant: "CulturalGamer",
        comment:
          "这款游戏是我玩过的历史题材游戏中最出色的之一。它不仅仅是游戏，更像是一堂生动的历史课。游戏中的每个细节都充满了教育意义，非常适合对中国历史感兴趣的玩家。",
        commentZhHant:
          "這款遊戲是我玩過的歷史題材遊戲中最出色的之一。它不僅僅是遊戲，更像是一堂生動的歷史課。遊戲中的每個細節都充滿了教育意義，非常適合對中國歷史感興趣的玩家。",
      },
      {
        userAvatar: Avatar09,
        userName: "TimeTraveler",
        userNameZhHant: "TimeTraveler",
        comment:
          "《满庭芳：宋上繁华》给我带来了一次穿越时空的奇妙体验。游戏中的场景设计逼真，任务设计合理，玩家可以在游玩的过程中深刻体验宋代的文化和生活。",
        commentZhHant:
          "《滿庭芳：宋上繁華》給我帶來了一次穿越時空的奇妙體驗。遊戲中的場景設計逼真，任務設計合理，玩家可以在遊玩的過程中深刻體驗宋代的文化和生活。",
      },
      {
        userAvatar: Avatar10,
        userName: "SongEraEnthusiast",
        userNameZhHant: "SongEraEnthusiast",
        comment:
          "这款游戏让我对宋代文化有了更深的认识。从政治、经济到日常生活，游戏中的每个方面都非常真实，让人感觉就像真的回到了那个时代。",
        commentZhHant:
          "這款遊戲讓我對宋代文化有了更深的認識。從政治、經濟到日常生活，遊戲中的每個方面都非常真實，讓人感覺就像真的回到了那個時代。",
      },
      {
        userAvatar: Avatar01,
        userName: "HistoricalGamer",
        userNameZhHant: "HistoricalGamer",
        comment:
          "《满庭芳：宋上繁华》是一款制作精良的游戏，让人在娱乐的同时学到了很多关于宋代的知识。游戏的互动性和教育性都很强，值得一玩。",
        commentZhHant:
          "《滿庭芳：宋上繁華》是一款製作精良的遊戲，讓人在娛樂的同時學到了很多關於宋代的知識。遊戲的互動性和教育性都很強，值得一玩。",
      },
      {
        userAvatar: Avatar02,
        userName: "DynastyDiver",
        userNameZhHant: "DynastyDiver",
        comment:
          "这款游戏的故事情节吸引人，角色设计独特，让人感觉每一个角色都有自己的故事。它不仅仅是一款游戏，更是一次文化的探索之旅。",
        commentZhHant:
          "這款遊戲的故事情節吸引人，角色設計獨特，讓人感覺每一個角色都有自己的故事。它不僅僅是一款遊戲，更是一次文化的探索之旅。",
      },
    ],
  },
  wuyu: {
    coverIcon: moreMiwu,
    slug: "wuyu",
    isAdultGame: process.env.REACT_APP_API_ENV !== "prod",
    statics: {
      steam: "steam_medium",
      ign: "8.3",
      heiHe: "2682",
      online: "388",
    },
    configuration: {
      base: {
        os: "Windows 7",
        processor: "过去5年内的处理器",
        ram: "需要 4 GB 内存（RAM）",
        graphic: "需要 1 GB 显存的显卡",
        disk: "需要 4 GB 可用空间（ROM）",
      },
    },
    price: "¥39.9",
    originPrice: "¥99.9",
    discount: "-60%",
    intel: {
      zhHant: {
        name: "森久城物語",
        tags: ["獨立", "策略", "卡牌建構式類 Rogue", "迷宮探索", "回合製"],
        brief:
          "《森久城物語》是一款帶有輕度Roguelike的卡組構築策略遊戲。 你需要透過不斷的戰鬥來優化自己的牌組，學習強大的天賦，借助日本戰國時期各個武將的能力，來調查清楚森久城謀反的真相。",
        developer: "Smokingbear Studio",
        publisher: "Thermite Games",
        releaseDate: "2022年2月10日",
        detailMarkdownUrl: wuyuDetailMdUrlZhHant,
        statics: {
          steam: "steam_medium",
          ign: "8.3",
          heiHe: "2682",
          online: "388",
        },
        configuration: {
          base: {
            os: "Windows 7",
            processor: "過去5年內的處理器",
            ram: "需要 4 GB 內存（RAM）",
            graphic: "需要 1 GB 顯存的顯示卡",
            disk: "需要 4 GB 可用空間（ROM）",
          },
        },
        recommend: [
          {
            slug: "miwu",
            price: "¥39.9",
            originPrice: "¥99.9",
            discount: "-60%",
            name: "迷霧偵探",
            link: "/game/miwu",
            image: sideMoreMiwu,
          },
          {
            slug: "mantingfang",
            price: "¥39.9",
            originPrice: "¥99.9",
            discount: "-60%",
            name: "滿庭芳：宋上繁華",
            link: "/game/mantingfang",
            image: sideMoreMantingfang,
          },
          {
            slug: "dust",
            name: "塵末(Dust to the End)",
            link: "/game/dust",
            image: sideMoreDust,
          },
          {
            slug: "blue",
            name: "憂鬱星期三",
            link: "/game/blue",
            image: sideMoreBlue,
          },
        ],
      },
    },
    name: "森久城物语",
    enName: "Castle Morihisa",
    tags: ["独立", "策略", "卡牌构建式类 Rogue", "迷宫探索", "回合制"],
    brief:
      "《森久城物语》是一款带有轻度Roguelike的卡组构筑策略游戏。你需要通过不断的战斗来优化自己的卡组，学习强大的天赋，借助日本战国时期各个武将的能力，来调查清楚森久城谋反的真相。",
    developer: "Smokingbear Studio",
    publisher: "Thermite Games",
    releaseDate: "2022年2月10日",
    images: [
      {
        Icon: main1IconWuyu,
        Main: main1Wuyu,
      },
      {
        Icon: main2IconWuyu,
        Main: main2Wuyu,
      },
      {
        Icon: main3IconWuyu,
        Main: main3Wuyu,
      },
      {
        Icon: main4IconWuyu,
        Main: main4Wuyu,
      },
      {
        Icon: main5IconWuyu,
        Main: main5Wuyu,
      },
    ],
    recommend: [
      {
        slug: "miwu",
        price: "¥39.9",
        originPrice: "¥99.9",
        discount: "-60%",
        name: "迷雾侦探",
        link: "/game/miwu",
        image: sideMoreMiwu,
      },
      {
        slug: "mantingfang",
        price: "¥39.9",
        originPrice: "¥99.9",
        discount: "-60%",
        name: "满庭芳：宋上繁华",
        link: "/game/mantingfang",
        image: sideMoreMantingfang,
      },
      {
        slug: "dust",
        name: "尘末(Dust to the End)",
        link: "/game/dust",
        image: sideMoreDust,
      },
      {
        slug: "blue",
        name: "忧郁星期三",
        link: "/game/blue",
        image: sideMoreBlue,
      },
    ],
    detailMarkdownUrl: wuyuDetailMdUrl,
    comments: [
      {
        userAvatar: Avatar01,
        userName: "寒冰法师",
        userNameZhHant: "寒冰法師",
        comment:
          "尖塔类游戏，《森久城物语》的角色立绘做的很好，森久城物语的美术特别给游戏加分，游戏中的主角们能力设计十分贴合职业，连怪物设计多也采用的是日本本地的神话传说。\n但这里要说明的是，比起早已成熟的《杀戮尖塔》来说，游戏人物和怪物的细节明显不足。举个比较简单的例子是，杀戮尖塔的人物死亡时有倒地的造型，小虫子有蜷缩状态时，被攻击真的会蜷缩。\n而《森久城物语》的人物和怪物，大多只是一张图。部分拥有双形态的怪物，才会因为状态的变化而有所改变。",
        commentZhHant:
          "尖塔類遊戲，《森久城物語》的角色立繪做的很好，森久城物語的美術特別給遊戲加分，遊戲中的主角們能力設計十分貼合職業，連怪物設計多也采用的是日本本地的神話傳說。\n但這裏要說明的是，比起早已成熟的《殺戮尖塔》來說，遊戲人物和怪物的細節明顯不足。舉個比較簡單的例子是，殺戮尖塔的人物死亡時有倒地的造型，小蟲子有蜷縮狀態時，被攻擊真的會蜷縮。\n而《森久城物語》的人物和怪物，大多只是一張圖。部分擁有雙形態的怪物，才會因為狀態的變化而有所改變。",
      },
      {
        userAvatar: Avatar02,
        userName: "武林高手",
        userNameZhHant: "武林高手",
        comment:
          "关于职业平衡问题，确实需要进一步的改进和完善。游戏中的每个职业都有自己独特的技能和玩法，但是在目前的版本中，有些职业可能过于强势，而有些职业则相对较弱。因此，我们建议对阴阳师这个职业进行一些强化，以使其更具有可玩性和竞争力。\n至于令牌系统，您指出了它的万金油性质和三次使用机会的限制。我们很高兴听到您对令牌系统的认可和喜爱。在游戏中，令牌可以作为一种特殊的道具来使用，它们不仅拥有强大的技能和能力，而且还可以在某些特定情况下起到至关重要的作用。",
        commentZhHant:
          "關於職業平衡問題，確實需要進一步的改進和完善。遊戲中的每個職業都有自己獨特的技能和玩法，但是在目前的版本中，有些職業可能過於強勢，而有些職業則相對較弱。因此，我們建議對陰陽師這個職業進行一些強化，以使其更具可玩性和競爭力。\n至於令牌系統，您指出了它的萬金油性質和三次使用機會的限制。 我們很高興聽到您對令牌系統的認可和喜愛。 在遊戲中，令牌可以作為一種特殊的道具來使用，它們不僅擁有強大的技能和能力，而且還可以在某些特定情況下起到至關重要的作用。",
      },
      {
        userAvatar: Avatar03,
        userName: "赛车飞将",
        userNameZhHant: "賽車飛將",
        comment:
          "《森久城物语》以其深度和策略性赢得了我的喜爱。游戏的卡牌构筑和战斗系统都需要玩家深思熟虑，而且游戏的Roguelike元素带来了无尽的挑战和惊喜。\n游戏的剧情和世界观出色，将我吸引进了这个日本战国世界。每个职业的卡牌和天赋树都各具特色，需要玩家灵活搭配和运用，这增加了游戏的策略性和趣味性。",
        commentZhHant:
          "《森久城物語》以其深度和策略性贏得了我的喜愛。遊戲的卡牌構築和戰鬥系統都需要玩家深思熟慮，而且遊戲的Roguelike元素帶來了無盡的挑戰和驚喜。\n遊戲的劇情和世界觀出色，將我吸引進了這個日本戰國世界。每個職業的卡牌和天賦樹都各具特色，需要玩家靈活搭配和運用，這增加了遊戲的策略性和趣味性。",
      },
      {
        userAvatar: Avatar04,
        userName: "暗夜行者",
        userNameZhHant: "暗夜行者",
        comment:
          "虽然游戏的平衡性还有待提高，但每个职业都有自己独特的卡牌和技能，这为玩家提供了丰富的战术选择。游戏的难度适中，让玩家既能够感受到挑战，又不会过于难以入手。",
        commentZhHant:
          "雖然遊戲的平衡性仍有待提高，但每個職業都有自己獨特的卡片和技能，這為玩家提供了豐富的戰術選擇。 遊戲的難度適中，讓玩家既能感受到挑戰，又不會太難以入手。",
      },
      {
        userAvatar: Avatar05,
        userName: "机智小狐",
        userNameZhHant: "機智小狐",
        comment:
          "我特别喜欢《森久城物语》中的美术风格，它充分展现了日本战国和民间传说的特色。游戏中的音乐也非常出色，与游戏的主题和氛围相得益彰。在游戏中，我可以选择自己喜欢的职业，每个职业的卡牌和天赋树都各不相同，这为游戏带来了多种多样的玩法。我认为这款游戏特别适合喜欢策略游戏和日本战国文化的玩家。",
        commentZhHant:
          "我特別喜歡《森久城物語》中的美術風格，它充分展現了日本戰國和民間傳說的特色。遊戲中的音樂也非常出色，與遊戲的主題和氛圍相得益彰。\n在遊戲中，我可以選擇自己喜歡的職業，每個職業的卡片和天賦樹都各不相同，這為遊戲帶來了多種多樣的玩法。 我認為這款遊戲特別適合喜歡策略遊戲和日本戰國文化的玩家。",
      },
      {
        userAvatar: Avatar06,
        userName: "狂风剑客",
        userNameZhHant: "狂風劍客",
        comment:
          "这个游戏以其独特的日本战国和民间传说主题为国产游戏增添了新的色彩。卡牌构筑和战斗系统策略性强，需要玩家灵活搭配和运用。游戏中的Roguelike元素带来了无尽的可能性和挑战。每次开局都有全新的体验，天赋树的解锁更是让游戏玩法变得丰富多样。",
        commentZhHant:
          "這個遊戲以其獨特的日本戰國和民間傳說主題為國產遊戲增添了新的色彩。卡牌構築和戰鬥系統策略性強，需要玩家靈活搭配和運用。\n遊戲中的Roguelike元素帶來了無盡的可能性和挑戰。 每次開局都有全新的體驗，天賦樹的解鎖更是讓遊戲玩法變得豐富多元。",
      },
      {
        userAvatar: Avatar07,
        userName: "智谋军师",
        userNameZhHant: "智謀軍師",
        comment:
          "《森久城物语》是一款非常值得一试的游戏。游戏以一座充满神秘和奇幻色彩的古城为背景，玩家需要在这座城中探索、冒险，解开隐藏在城中的谜题。游戏的画面精美细腻，音乐悠扬动听，让人仿佛置身于一个充满奇幻色彩的世界之中。此外，游戏的剧情紧凑，谜题设计巧妙，让人一边玩游戏，一边思考，乐在其中。如果你喜欢冒险解谜类游戏，那么《森久城物语》绝对是你不容错过的佳作。",
        commentZhHant:
          "《森久城物語》是一款非常值得一試的遊戲。 遊戲以一座充滿神秘和奇幻色彩的古城為背景，玩家需要在這座城中探索、冒險，解開隱藏在城中的謎題。 遊戲的畫面精美細膩，音樂悠揚動聽，讓人彷彿置身於一個充滿奇幻色彩的世界之中。 此外，遊戲的劇情緊湊，謎題設計巧妙，讓人一邊玩遊戲，一邊思考，樂在其中。 如果你喜歡冒險解謎類遊戲，那麼《森久城物語》絕對是你不容錯過的佳作。",
      },
      {
        userAvatar: Avatar08,
        userName: "龙之守护",
        userNameZhHant: "龍之守護",
        comment:
          "《森久城物语》是一款极具创意的冒险解谜游戏。游戏中，你将扮演一名勇敢的冒险者，探索神秘的森久城，寻找隐藏在城中的宝藏。游戏的画面非常精美，场景设计细致入微，让人仿佛置身于一个真实的世界之中。游戏的谜题设计巧妙，需要玩家动脑筋、想办法才能解开。如果你喜欢挑战自己的智慧，那么《森久城物语》一定会让你爱不释手。",
        commentZhHant:
          "《森久城物語》是一款極具創意的冒險解謎遊戲。 遊戲中，你將扮演一個勇敢的冒險者，探索神祕的森久城，尋找隱藏在城中的寶藏。 遊戲的畫面非常精美，場景設計細緻入微，讓人彷彿置身於一個真實的世界之中。 遊戲的謎題設計巧妙，需要玩家動腦、想辦法才能解開。 如果你喜歡挑戰自己的智慧，那麼《森久城物語》一定會讓你愛不釋手。",
      },
      {
        userAvatar: Avatar09,
        userName: "虚空旅者",
        userNameZhHant: "虛空旅者",
        comment:
          "在游戏中，你将扮演一名勇敢的冒险者，探索神秘的森久城，寻找隐藏在城中的宝藏。游戏的画面非常精美，场景设计细致入微，让人仿佛置身于一个真实的世界之中。游戏的谜题设计巧妙，需要玩家动脑筋、想办法才能解开。如果你喜欢挑战自己的智慧，那么《森久城物语》一定会让你爱不释手。",
        commentZhHant:
          "在遊戲中，你將扮演一個勇敢的冒險者，探索神秘的森久城，尋找隱藏在城中的寶藏。 遊戲的畫面非常精美，場景設計細緻入微，讓人彷彿置身於一個真實的世界之中。 遊戲的謎題設計巧妙，需要玩家動腦、想辦法才能解開。 如果你喜歡挑戰自己的智慧，那麼《森久城物語》一定會讓你愛不釋手。",
      },
      {
        userAvatar: Avatar10,
        userName: "影舞者",
        userNameZhHant: "影舞者",
        comment:
          "虽然有杀戮尖塔的影子，但多多少少有点创新，美术也挺好的。但是感觉不那么有诚意。",
        commentZhHant:
          "雖然有殺戮尖塔的影子，但多多少少有點創新，美術也挺好的。 但感覺不那麼有誠意",
      },
    ],
  },
  miwu: {
    coverIcon: moreWuyu,
    slug: "miwu",
    isAdultGame: process.env.REACT_APP_API_ENV !== "prod",
    statics: {
      steam: "steam_good",
      ign: "8.3",
      heiHe: "8万",
      online: "135",
    },
    configuration: {
      base: {
        os: "Windows 7",
        processor: "Intel Core2 Duo E6400 @ 2.13GHz",
        ram: "需要 4 GB 内存（RAM）",
        graphic: "GeForce GT 730 OR Radeon HD 4830",
        disk: "需要 2 GB 可用空间（ROM）",
      },
      recommend: {
        os: "Windows 10",
        processor: "Intel Core I5-2300",
        ram: "需要 8 GB 内存（RAM）",
        graphic: "GeForce GTX 650 OR Radeon HD 7750",
        disk: "需要 2 GB 可用空间（ROM）",
      },
    },
    price: "¥39.9",
    originPrice: "¥99.9",
    discount: "-60%",
    name: "迷雾侦探",
    enName: "Tales of The Neon Sea",
    intel: {
      zhHant: {
        name: "迷霧偵探",
        tags: ["冒險", "獨立", "像素圖形", "賽博朋克", "指向點擊", "劇情豐富"],
        brief:
          "《迷霧偵探》是一款橫版像素劇情解謎遊戲，遊戲透過引人入勝的情節，復古的像素風格，多元化的謎題將玩家帶入一個奇幻又迷離的賽博龐克世界，讓玩家如同欣賞一 部精彩的偵探電影一般，慢慢撥開其中的迷霧。",
        developer: "YiTi Games",
        publisher: "Thermite Games",
        releaseDate: "2019年4月30日",
        detailMarkdownUrl: miwuDetailMdUrlZhHant,
        statics: {
          steam: "steam_good",
          ign: "8.3",
          heiHe: "8萬",
          online: "135",
        },
        configuration: {
          base: {
            os: "Windows 7",
            processor: "Intel Core2 Duo E6400 @ 2.13GHz",
            ram: "需要 4 GB 記憶體（RAM）",
            graphic: "GeForce GT 730 OR Radeon HD 4830",
            disk: "需要 2 GB 可用空間（ROM）",
          },
          recommend: {
            os: "Windows 10",
            processor: "Intel Core I5-2300",
            ram: "需要 8 GB 記憶體（RAM）",
            graphic: "GeForce GTX 650 OR Radeon HD 7750",
            disk: "需要 2 GB 可用空間（ROM）",
          },
        },
        recommend: [
          {
            slug: "wuyu",
            price: "¥39.9",
            originPrice: "¥99.9",
            discount: "-60%",
            name: "森久城物語",
            link: "/game/wuyu",
            image: sideMoreWuyu,
          },
          {
            slug: "mantingfang",
            price: "¥39.9",
            originPrice: "¥99.9",
            discount: "-60%",
            name: "滿庭芳：宋上繁華",
            link: "/game/mantingfang",
            image: sideMoreMantingfang,
          },
          {
            slug: "dust",
            name: "塵末(Dust to the End)",
            link: "/game/dust",
            image: sideMoreDust,
          },
          {
            slug: "blue",
            name: "憂鬱星期三",
            link: "/game/blue",
            image: sideMoreBlue,
          },
        ],
      },
    },
    tags: ["冒险", "独立", "像素图形", "赛博朋克", "指向点击", "剧情丰富"],
    brief:
      "《迷雾侦探》是一款横版像素剧情解谜游戏，游戏通过引人入胜的情节，复古的像素风格，多元化的谜题将玩家带入一个奇幻又迷离的赛博朋克世界，让玩家如同欣赏一部精彩的侦探电影一般，慢慢拨开其中的迷雾。",
    developer: "YiTi Games",
    publisher: "Thermite Games",
    releaseDate: "2019年4月30日",
    images: [
      {
        Icon: main1IconMiwu,
        Main: main1Miwu,
      },
      {
        Icon: main2IconMiwu,
        Main: main2Miwu,
      },
      {
        Icon: main3IconMiwu,
        Main: main3Miwu,
      },
      {
        Icon: main4IconMiwu,
        Main: main4Miwu,
      },
      {
        Icon: main5IconMiwu,
        Main: main5Miwu,
      },
      {
        Icon: main6IconMiwu,
        Main: main6Miwu,
      },
      {
        Icon: main7IconMiwu,
        Main: main7Miwu,
      },
    ],
    recommend: [
      {
        slug: "wuyu",
        price: "¥39.9",
        originPrice: "¥99.9",
        discount: "-60%",
        name: "森久城物语",
        link: "/game/wuyu",
        image: sideMoreWuyu,
      },
      {
        slug: "mantingfang",
        price: "¥39.9",
        originPrice: "¥99.9",
        discount: "-60%",
        name: "满庭芳：宋上繁华",
        link: "/game/mantingfang",
        image: sideMoreMantingfang,
      },
      {
        slug: "dust",
        name: "尘末(Dust to the End)",
        link: "/game/dust",
        image: sideMoreDust,
      },
      {
        slug: "blue",
        name: "忧郁星期三",
        link: "/game/blue",
        image: sideMoreBlue,
      },
    ],
    detailMarkdownUrl: miwuDetailMdUrl,
    comments: [
      {
        userAvatar: Avatar05,
        userName: "狂奔的蜗牛",
        userNameZhHant: "狂奔的蝸牛",
        comment:
          "这款游戏的氛围营造和美术设计相当出色，给人留下了深刻的印象。主线剧情虽然有些许的不足，但总体上还是能够自圆其说，给玩家带来新奇的体验。\n然而，游戏的支线任务设计似乎只是为了数量而存在，对赛博世界的体验并没有多少提升，也缺乏足够的奖励来激励玩家去完成它们。这些谜题小游戏的存在可能会让人觉得有些出戏，希望制作方能够在未来的更新中加入更多的支线任务，以丰富游戏内容，提高玩家的体验度。",
        commentZhHant:
          "這款遊戲的氛圍營造和美術設計相當出色，給人留下了深刻的印象。 主線劇情雖然有些許的不足，但整體上還是能夠自圓其說，帶給玩家新奇的體驗。\n然而，遊戲的支線任務設計似乎只是為了數量而存在，對賽博世界的體驗並沒有太多提升，也缺乏足夠的獎勵來激勵玩家去完成它們。 這些謎題小遊戲的存在可能會讓人覺得有些齣戲，希望製作方能夠在未來的更新中加入更多的支線任務，以豐富遊戲內容，提高玩家的體驗度。",
      },
      {
        userAvatar: Avatar06,
        userName: "梦想家伊卡洛斯",
        userNameZhHant: "夢想家伊卡洛斯",
        comment:
          "在光怪陆离的赛博世界中，解谜游戏往往能够带给人们无尽的乐趣和挑战。这种类型的游戏通常会通过独特的设定和环境来吸引玩家，让人们沉浸在虚构的世界中，并通过解决问题和解密来获得成就感和满足感。\n关于机械人和信仰的问题，如果机械人拥有信仰，那么他们眼中的神可能会被定义为一种高级的存在或是某种神秘的能量。这些信仰可能会基于人类宗教或者是一些全新的理念。不过，这只是一个假设，现实中机械人是否能够拥有真正的信仰还有待探讨。",
        commentZhHant:
          "在光怪陸離的賽博世界中，解謎遊戲往往能夠帶給人們無盡的樂趣和挑戰。這種類型的遊戲通常會透過獨特的設定和環境來吸引玩家，讓人們沉浸在虛構的世界中， 並透過解決問題和解密來獲得成就感和滿足感。\n關於機械人和信仰的問題，如果機械人擁有信仰，那麼他們眼中的神可能會被定義為一種高級的存在或某種神秘的能量。 這些信仰可能會基於人類宗教或是一些全新的理念。 不過，這只是一個假設，現實中機械人是否能擁有真正的信仰還有待探討。",
      },
      {
        userAvatar: Avatar07,
        userName: "雾都探戈",
        userNameZhHant: "霧都探戈",
        comment:
          "这部作品我真的超级爱！它的像素设计塑造出了一种极具吸引力的赛博朋克风格。除此之外，无论是剧情的发展，还是解谜的挑战度，都掌握得恰到好处，让人流连忘返。我还要说的是，游戏里到处都能看到各种各样的文件、见闻、情报，这些元素交织在一起，形成了一个庞大且细腻的世界观。",
        commentZhHant:
          "這部作品我真的超級愛！它的像素設計塑造出了一種極具吸引力的賽博朋克風格。除此之外，無論是劇情的發展，還是解謎的挑戰度，都掌握得 恰到好處，讓人流連忘返。我還要說的是，遊戲裡到處都能看到各種各樣的文件、見聞、情報，這些元素交織在一起，形成了一個龐大且細膩的世界觀。",
      },
      {
        userAvatar: Avatar08,
        userName: "彩虹独角兽",
        userNameZhHant: "彩虹獨角獸",
        comment:
          "游戏的流程长度适中。这款游戏独特的点击式与横版解谜模式让玩家们能够享受到轻松愉快的解谜体验。难度适宜的谜题设计让玩家们无需依赖任何攻略就能顺利完成挑战，让人眼前一亮。\n除了主线剧情外，游戏中还有许多可收集的资料，这些资料为玩家展现了更为广阔的世界观。游戏背景设定别具一格，像素画风细腻，让玩家能够快速沉浸在游戏世界中。虽然游戏的故事背景并非发生在繁华的“天空城”，但商业街等地区的设定依然让人惊叹不已。下水道和废弃区等元素则更加丰富了游戏中的场景，使得游戏的沉浸感更加出色。",
        commentZhHant:
          "遊戲的流程長度適中。這款遊戲獨特的點擊式與橫版解謎模式讓玩家們能夠享受到輕鬆愉快的解謎體驗。難度適宜的謎題設計讓玩家們無需依賴任何攻略就能順利完成 挑戰，讓人眼睛一亮。\n除了主線劇情外，遊戲中還有許多可收集的資料，這些資料為玩家展現了更為廣闊的世界觀。 遊戲背景設定別具一格，像素畫風細膩，讓玩家快速沉浸在遊戲世界中。 雖然遊戲的故事背景並非發生在繁華的“天空城”，但商業街等地區的設定仍然讓人驚嘆不已。 下水道和廢棄區等元素則更豐富了遊戲中的場景，使得遊戲的沉浸感更加出色。",
      },
      {
        userAvatar: Avatar09,
        userName: "星辰之海",
        userNameZhHant: "星辰之海",
        comment:
          "这个世界观还算完整，游戏中的剧情深度和细节打磨都非常出彩，各种元素完美融合，为玩家呈现了一个完整、丰富的游戏世界观。有很多致敬，比如说很多任天堂风格的内容，还有很多日漫的内容，还有银翼杀手这种赛博风的菜单哈哈，游戏的画面非常精美，采用了像素风格，同时又很好地融入了赛博朋克元素，让游戏看起来既复古又充满未来感。",
        commentZhHant:
          "這個世界觀還算完整，遊戲中的劇情深度和細節打磨都非常出彩，各種元素完美融合，為玩家呈現了一個完整、豐富的遊戲世界觀。 有很多致敬，比如說很多任天堂風格的內容，還有很多日漫的內容，還有銀翼殺手這種賽博風的菜單哈哈，遊戲的畫面非常精美，採用了像素風格，同時又很好地 融入了賽博龐克元素，讓遊戲看起來既復古又充滿未來感。",
      },
      {
        userAvatar: Avatar10,
        userName: "夜影之歌",
        userNameZhHant: "夜影之歌",
        comment:
          "本来以为是个小游戏，结果其文本量、故事架构和细节数量都超出了我的预期。是个非常精细的国产解谜游戏，做的很用心，能上内容就上了内容，完全没有说为了减少工作量而凑字数，多放文字，但是开发团队不遗余力地对每个细节进行了描绘和刻画，增加了超级多的细节，这样游玩体验特别好。相比游戏的画面，游戏性上，谜题难度适中，数量合理，而且需要玩家进行操作的部分也恰到好处。游戏进程既不拖沓，也不会让人感到无聊，每个谜题的设计都非常巧妙。",
        commentZhHant:
          "本來以為是個小遊戲，結果其文字量、故事架構和細節數量都超出了我的預期。 是個非常精細的國產解謎遊戲，做的很用心，能上內容就上了內容，完全沒有說為了減少工作量而湊字數，多放文字，但是開發團隊不遺餘力地對每個細節進行了描繪和 刻畫，增加了超級多的細節，這樣遊玩體驗特別好。 相較於遊戲的畫面，遊戲性上，謎題難度適中，數量合理，需要玩家進行操作的部分也恰到好處。 遊戲進程既不拖沓，也不會讓人感到無聊，每個謎題的設計都非常巧妙。",
      },
      {
        userAvatar: Avatar01,
        userName: "碧海青天",
        userNameZhHant: "狂奔的蜗牛",
        comment:
          "游戏让人惊喜，虽然它出自国内开发者之手，但质量真的很高很高很高。这款游戏的画面设计非常出色，一看到它就会立刻被吸引。这也是它最大的优势，美术非常牛逼。游戏将侦探元素融入到了解谜环节当中，结合赛博风格的背景，拥有庞大的剧情，构成了一个精彩绝伦的侦探故事。可以说是近几年国产独立游戏中的佼佼者，更可能是国产解谜游戏得新丰碑，相信是会在日后被大家久久传唱。",
        commentZhHant:
          "遊戲讓人驚喜，雖然它出自國內開發者之手，但品質真的很高很高。這款遊戲的畫面設計非常出色，一看到它就會立刻被吸引。 這也是它最大的優勢，美術非常屌。 遊戲將偵探元素融入了解謎環節當中，結合賽博風格的背景，擁有龐大的劇情，構成了一個精彩絕倫的偵探故事。 可以說是近幾年國產獨立遊戲中的佼佼者，更可能是國產解謎遊戲得新豐碑，相信是會在日後被大家久久傳唱。",
      },
      {
        userAvatar: Avatar04,
        userName: "烈焰凤凰",
        userNameZhHant: "碧海青天",
        comment:
          "画面、剧情、体验都做的非常好，超过我对一般国产游戏的想象。非常优秀的像素风美术和画面，也有一大堆美术细节填充在内，做的真的很好，我是很喜欢这种赛博朋克风格的像素风美术。剧情也是，解谜游戏的剧情应该做的比较好，以独游的标准来评判，它的剧情也可谓是上乘之作，节奏很紧凑，要得到完整的体验，你需要有足够的耐心去收集和阅读游戏中的道具和文档说明。这些道具和文档中蕴含着大量的背景故事和人物信息，阅读后你会对整个故事背景和人物有更深的了解，不过中后期的谜题设计对于普通玩家来说可能有些过于繁琐和复杂，可能会让人失去耐心。",
        commentZhHant:
          "畫面、劇情、體驗都做的非常好，超過我對一般國產遊戲的想像。 非常優秀的像素風美術和畫面，也有一大堆美術細節填充在內，做的真的很好，我很喜歡這種賽博朋克風格的像素風美術。 劇情也是，解謎遊戲的劇情應該要做的比較好，以獨遊的標準來評判，它的劇情也可謂是上乘之作，節奏很緊湊，要得到完整的體驗，你需要有足夠的耐心去收集 和閱讀遊戲中的道具和文件說明。 這些道具和文件中蘊含著大量的背景故事和人物訊息，閱讀後你會對整個故事背景和人物有更深的了解，不過中後期的謎題設計對於普通玩家來說可能有些過於繁瑣和復雜，可能 會讓人失去耐心。",
      },
      {
        userAvatar: Avatar02,
        userName: "冰雪之巅",
        userNameZhHant: "冰雪之巔",
        comment:
          "这款游戏的主创们显然在构建世界观和情节方面付出了很大的努力。虽然游戏采用了像素风格，但是赛博朋克的氛围营造得非常出色。如果你喜欢攻壳剧场版的话，这款游戏的电子迷幻风格应该会让你很喜欢。\n对于结局部分，也许主创们想表达的并不仅仅是小女孩喜欢猫的谋杀案，但可能由于某些原因没有详细展开。至于侦探故事方面，不同的人可能有不同的喜好，所以也许这款游戏的侦探故事并没有达到每个人的期望。\n总的来说，这款游戏应该会给大部分玩家留下深刻的印象，并且展现出了主创们的才华和野心。",
        commentZhHant:
          "這款遊戲的主創們顯然在構建世界觀和情節方面付出了很大的努力。雖然遊戲採用了像素風格，但是賽博朋克的氛圍營造得非常出色。如果你喜歡攻殼劇場版的話，這款 遊戲的電子迷幻風格應該會讓你很喜歡。\n對於結局部分，也許主創們想表達的並不是小女孩喜歡貓的謀殺案，但可能由於某些原因沒有詳細展開。 至於偵探故事方面，不同的人可能有不同的喜好，所以也許這款遊戲的偵探故事並沒有達到每個人的期望。\n總的來說，這款遊戲應該會給大部分玩家留下深刻的印象，並且展現出了主創們的才華和野心。",
      },
      {
        userAvatar: Avatar03,
        userName: "破晓之翼",
        userNameZhHant: "破曉之翼",
        comment:
          "游戏的画风是非常吸引人的一部分，特别是对于喜欢这种像素风格的人来说。关于游戏中的操作，确实不是很难，玩家可以轻松上手。而解谜部分，如果需要自己来的话可能会有些困难，但是游戏的提示非常有用，可以帮助玩家轻松解决谜题。除了收集各种传闻和成就之外，吸猫也是游戏中的一大乐趣。在游戏中可以看到各种可爱的猫咪，对于喜欢猫咪的人来说，这个游戏绝对是不能错过的。最后，关于游戏中存在的未填的坑，确实有很多玩家对此感到有些失望。但是，也许主创们会在未来的更新中逐步完善游戏，给玩家们带来更多的惊喜和乐趣。",
        commentZhHant:
          "遊戲的畫風是非常吸引人的一部分，特別是對於喜歡這種像素風格的人來說。關於遊戲中的操作，確實不是很難，玩家可以輕鬆上手。 而解謎部分，如果需要自己來的話可能會有些困難，但是遊戲的提示非常有用，可以幫助玩家輕鬆解決謎題。除了收集各種傳聞和成就之外，吸貓也是遊戲中的一大樂趣。 在遊戲中可以看到各種可愛的貓咪，對於喜歡貓咪的人來說，這個遊戲絕對是不能錯過的。最後，關於遊戲中存在的未填入的坑，確實有許多玩家對此感到有些失望。 但是，也許主創們會在未來的更新中逐步完善遊戲，為玩家帶來更多的驚喜和樂趣。",
      },
    ],
  },
  "miwu-dlc-1": {
    coverIcon: moreWuyu,
    slug: "miwu-dlc-1",
    isAdultGame: process.env.REACT_APP_API_ENV !== "prod",
    statics: {
      steam: "steam_good",
      ign: "8.3",
      heiHe: "8万",
      online: "135",
    },
    configuration: {
      base: {
        os: "Windows 7",
        processor: "Intel Core2 Duo E6400 @ 2.13GHz",
        ram: "需要 4 GB 内存（RAM）",
        graphic: "GeForce GT 730 OR Radeon HD 4830",
        disk: "需要 2 GB 可用空间（ROM）",
      },
      recommend: {
        os: "Windows 10",
        processor: "Intel Core I5-2300",
        ram: "需要 8 GB 内存（RAM）",
        graphic: "GeForce GTX 650 OR Radeon HD 7750",
        disk: "需要 2 GB 可用空间（ROM）",
      },
    },
    price: "¥5",
    originPrice: "¥7",
    discount: "-60%",
    name: "迷雾侦探DLC1",
    enName: "Tales of The Neon Sea DLC 1",
    intel: {
      zhHant: {
        name: "迷霧偵探DLC1",
        tags: ["冒險", "獨立", "像素圖形", "賽博朋克", "指向點擊", "劇情豐富"],
        brief:
          "《迷霧偵探DLC1》是一款橫版像素劇情解謎遊戲，遊戲透過引人入勝的情節，復古的像素風格，多元化的謎題將玩家帶入一個奇幻又迷離的賽博龐克世界，讓玩家如同欣賞一 部精彩的偵探電影一般，慢慢撥開其中的迷霧。",
        developer: "YiTi Games",
        publisher: "Thermite Games",
        releaseDate: "2019年4月30日",
        detailMarkdownUrl: miwuDetailMdUrlZhHant,
        statics: {
          steam: "steam_good",
          ign: "8.3",
          heiHe: "8萬",
          online: "135",
        },
        configuration: {
          base: {
            os: "Windows 7",
            processor: "Intel Core2 Duo E6400 @ 2.13GHz",
            ram: "需要 4 GB 記憶體（RAM）",
            graphic: "GeForce GT 730 OR Radeon HD 4830",
            disk: "需要 2 GB 可用空間（ROM）",
          },
          recommend: {
            os: "Windows 10",
            processor: "Intel Core I5-2300",
            ram: "需要 8 GB 記憶體（RAM）",
            graphic: "GeForce GTX 650 OR Radeon HD 7750",
            disk: "需要 2 GB 可用空間（ROM）",
          },
        },
        recommend: [
          {
            slug: "wuyu",
            price: "¥39.9",
            originPrice: "¥99.9",
            discount: "-60%",
            name: "森久城物語",
            link: "/game/wuyu",
            image: sideMoreWuyu,
          },
        ],
      },
    },
    tags: ["冒险", "独立", "像素图形", "赛博朋克", "指向点击", "剧情丰富"],
    brief:
      "《迷雾侦探DLC1》是一款横版像素剧情解谜游戏，游戏通过引人入胜的情节，复古的像素风格，多元化的谜题将玩家带入一个奇幻又迷离的赛博朋克世界，让玩家如同欣赏一部精彩的侦探电影一般，慢慢拨开其中的迷雾。",
    developer: "YiTi Games",
    publisher: "Thermite Games",
    releaseDate: "2019年4月30日",
    images: [
      {
        Icon: main1IconMiwu,
        Main: main1Miwu,
      },
      {
        Icon: main2IconMiwu,
        Main: main2Miwu,
      },
      {
        Icon: main3IconMiwu,
        Main: main3Miwu,
      },
      {
        Icon: main4IconMiwu,
        Main: main4Miwu,
      },
      {
        Icon: main5IconMiwu,
        Main: main5Miwu,
      },
      {
        Icon: main6IconMiwu,
        Main: main6Miwu,
      },
      {
        Icon: main7IconMiwu,
        Main: main7Miwu,
      },
    ],
    recommend: [
      {
        slug: "wuyu",
        price: "¥39.9",
        originPrice: "¥99.9",
        discount: "-60%",
        name: "森久城物语",
        link: "/game/wuyu",
        image: sideMoreWuyu,
      },
    ],
    detailMarkdownUrl: miwuDetailMdUrl,
    comments: [
      {
        userAvatar: Avatar05,
        userName: "狂奔的蜗牛",
        userNameZhHant: "狂奔的蝸牛",
        comment:
          "这款游戏的氛围营造和美术设计相当出色，给人留下了深刻的印象。主线剧情虽然有些许的不足，但总体上还是能够自圆其说，给玩家带来新奇的体验。\n然而，游戏的支线任务设计似乎只是为了数量而存在，对赛博世界的体验并没有多少提升，也缺乏足够的奖励来激励玩家去完成它们。这些谜题小游戏的存在可能会让人觉得有些出戏，希望制作方能够在未来的更新中加入更多的支线任务，以丰富游戏内容，提高玩家的体验度。",
        commentZhHant:
          "這款遊戲的氛圍營造和美術設計相當出色，給人留下了深刻的印象。 主線劇情雖然有些許的不足，但整體上還是能夠自圓其說，帶給玩家新奇的體驗。\n然而，遊戲的支線任務設計似乎只是為了數量而存在，對賽博世界的體驗並沒有太多提升，也缺乏足夠的獎勵來激勵玩家去完成它們。 這些謎題小遊戲的存在可能會讓人覺得有些齣戲，希望製作方能夠在未來的更新中加入更多的支線任務，以豐富遊戲內容，提高玩家的體驗度。",
      },
      {
        userAvatar: Avatar06,
        userName: "梦想家伊卡洛斯",
        userNameZhHant: "夢想家伊卡洛斯",
        comment:
          "在光怪陆离的赛博世界中，解谜游戏往往能够带给人们无尽的乐趣和挑战。这种类型的游戏通常会通过独特的设定和环境来吸引玩家，让人们沉浸在虚构的世界中，并通过解决问题和解密来获得成就感和满足感。\n关于机械人和信仰的问题，如果机械人拥有信仰，那么他们眼中的神可能会被定义为一种高级的存在或是某种神秘的能量。这些信仰可能会基于人类宗教或者是一些全新的理念。不过，这只是一个假设，现实中机械人是否能够拥有真正的信仰还有待探讨。",
        commentZhHant:
          "在光怪陸離的賽博世界中，解謎遊戲往往能夠帶給人們無盡的樂趣和挑戰。這種類型的遊戲通常會透過獨特的設定和環境來吸引玩家，讓人們沉浸在虛構的世界中， 並透過解決問題和解密來獲得成就感和滿足感。\n關於機械人和信仰的問題，如果機械人擁有信仰，那麼他們眼中的神可能會被定義為一種高級的存在或某種神秘的能量。 這些信仰可能會基於人類宗教或是一些全新的理念。 不過，這只是一個假設，現實中機械人是否能擁有真正的信仰還有待探討。",
      },
      {
        userAvatar: Avatar07,
        userName: "雾都探戈",
        userNameZhHant: "霧都探戈",
        comment:
          "这部作品我真的超级爱！它的像素设计塑造出了一种极具吸引力的赛博朋克风格。除此之外，无论是剧情的发展，还是解谜的挑战度，都掌握得恰到好处，让人流连忘返。我还要说的是，游戏里到处都能看到各种各样的文件、见闻、情报，这些元素交织在一起，形成了一个庞大且细腻的世界观。",
        commentZhHant:
          "這部作品我真的超級愛！它的像素設計塑造出了一種極具吸引力的賽博朋克風格。除此之外，無論是劇情的發展，還是解謎的挑戰度，都掌握得 恰到好處，讓人流連忘返。我還要說的是，遊戲裡到處都能看到各種各樣的文件、見聞、情報，這些元素交織在一起，形成了一個龐大且細膩的世界觀。",
      },
      {
        userAvatar: Avatar08,
        userName: "彩虹独角兽",
        userNameZhHant: "彩虹獨角獸",
        comment:
          "游戏的流程长度适中。这款游戏独特的点击式与横版解谜模式让玩家们能够享受到轻松愉快的解谜体验。难度适宜的谜题设计让玩家们无需依赖任何攻略就能顺利完成挑战，让人眼前一亮。\n除了主线剧情外，游戏中还有许多可收集的资料，这些资料为玩家展现了更为广阔的世界观。游戏背景设定别具一格，像素画风细腻，让玩家能够快速沉浸在游戏世界中。虽然游戏的故事背景并非发生在繁华的“天空城”，但商业街等地区的设定依然让人惊叹不已。下水道和废弃区等元素则更加丰富了游戏中的场景，使得游戏的沉浸感更加出色。",
        commentZhHant:
          "遊戲的流程長度適中。這款遊戲獨特的點擊式與橫版解謎模式讓玩家們能夠享受到輕鬆愉快的解謎體驗。難度適宜的謎題設計讓玩家們無需依賴任何攻略就能順利完成 挑戰，讓人眼睛一亮。\n除了主線劇情外，遊戲中還有許多可收集的資料，這些資料為玩家展現了更為廣闊的世界觀。 遊戲背景設定別具一格，像素畫風細膩，讓玩家快速沉浸在遊戲世界中。 雖然遊戲的故事背景並非發生在繁華的“天空城”，但商業街等地區的設定仍然讓人驚嘆不已。 下水道和廢棄區等元素則更豐富了遊戲中的場景，使得遊戲的沉浸感更加出色。",
      },
      {
        userAvatar: Avatar09,
        userName: "星辰之海",
        userNameZhHant: "星辰之海",
        comment:
          "这个世界观还算完整，游戏中的剧情深度和细节打磨都非常出彩，各种元素完美融合，为玩家呈现了一个完整、丰富的游戏世界观。有很多致敬，比如说很多任天堂风格的内容，还有很多日漫的内容，还有银翼杀手这种赛博风的菜单哈哈，游戏的画面非常精美，采用了像素风格，同时又很好地融入了赛博朋克元素，让游戏看起来既复古又充满未来感。",
        commentZhHant:
          "這個世界觀還算完整，遊戲中的劇情深度和細節打磨都非常出彩，各種元素完美融合，為玩家呈現了一個完整、豐富的遊戲世界觀。 有很多致敬，比如說很多任天堂風格的內容，還有很多日漫的內容，還有銀翼殺手這種賽博風的菜單哈哈，遊戲的畫面非常精美，採用了像素風格，同時又很好地 融入了賽博龐克元素，讓遊戲看起來既復古又充滿未來感。",
      },
    ],
  },
  "miwu-dlc-7": {
    coverIcon: moreWuyu,
    slug: "miwu-dlc-7",
    isAdultGame: process.env.REACT_APP_API_ENV !== "prod",
    statics: {
      steam: "steam_good",
      ign: "8.3",
      heiHe: "8万",
      online: "135",
    },
    configuration: {
      base: {
        os: "Windows 7",
        processor: "Intel Core2 Duo E6400 @ 2.13GHz",
        ram: "需要 4 GB 内存（RAM）",
        graphic: "GeForce GT 730 OR Radeon HD 4830",
        disk: "需要 2 GB 可用空间（ROM）",
      },
      recommend: {
        os: "Windows 10",
        processor: "Intel Core I5-2300",
        ram: "需要 8 GB 内存（RAM）",
        graphic: "GeForce GTX 650 OR Radeon HD 7750",
        disk: "需要 2 GB 可用空间（ROM）",
      },
    },
    price: "¥5",
    originPrice: "¥7",
    discount: "-60%",
    name: "迷雾侦探DLC7",
    enName: "Tales of The Neon Sea DLC 7",
    intel: {
      zhHant: {
        name: "迷霧偵探DLC7",
        tags: ["冒險", "獨立", "像素圖形", "賽博朋克", "指向點擊", "劇情豐富"],
        brief:
          "《迷霧偵探DLC7》是一款橫版像素劇情解謎遊戲，遊戲透過引人入勝的情節，復古的像素風格，多元化的謎題將玩家帶入一個奇幻又迷離的賽博龐克世界，讓玩家如同欣賞一 部精彩的偵探電影一般，慢慢撥開其中的迷霧。",
        developer: "YiTi Games",
        publisher: "Thermite Games",
        releaseDate: "2019年4月30日",
        detailMarkdownUrl: miwuDetailMdUrlZhHant,
        statics: {
          steam: "steam_good",
          ign: "8.3",
          heiHe: "8萬",
          online: "135",
        },
        configuration: {
          base: {
            os: "Windows 7",
            processor: "Intel Core2 Duo E6400 @ 2.13GHz",
            ram: "需要 4 GB 記憶體（RAM）",
            graphic: "GeForce GT 730 OR Radeon HD 4830",
            disk: "需要 2 GB 可用空間（ROM）",
          },
          recommend: {
            os: "Windows 10",
            processor: "Intel Core I5-2300",
            ram: "需要 8 GB 記憶體（RAM）",
            graphic: "GeForce GTX 650 OR Radeon HD 7750",
            disk: "需要 2 GB 可用空間（ROM）",
          },
        },
        recommend: [
          {
            slug: "wuyu",
            price: "¥39.9",
            originPrice: "¥99.9",
            discount: "-60%",
            name: "森久城物語",
            link: "/game/wuyu",
            image: sideMoreWuyu,
          },
        ],
      },
    },
    tags: ["冒险", "独立", "像素图形", "赛博朋克", "指向点击", "剧情丰富"],
    brief:
      "《迷雾侦探DLC7》是一款横版像素剧情解谜游戏，游戏通过引人入胜的情节，复古的像素风格，多元化的谜题将玩家带入一个奇幻又迷离的赛博朋克世界，让玩家如同欣赏一部精彩的侦探电影一般，慢慢拨开其中的迷雾。",
    developer: "YiTi Games",
    publisher: "Thermite Games",
    releaseDate: "2019年4月30日",
    images: [
      {
        Icon: main1IconMiwu,
        Main: main1Miwu,
      },
      {
        Icon: main2IconMiwu,
        Main: main2Miwu,
      },
      {
        Icon: main3IconMiwu,
        Main: main3Miwu,
      },
      {
        Icon: main4IconMiwu,
        Main: main4Miwu,
      },
      {
        Icon: main5IconMiwu,
        Main: main5Miwu,
      },
      {
        Icon: main6IconMiwu,
        Main: main6Miwu,
      },
      {
        Icon: main7IconMiwu,
        Main: main7Miwu,
      },
    ],
    recommend: [
      {
        slug: "wuyu",
        price: "¥39.9",
        originPrice: "¥99.9",
        discount: "-60%",
        name: "森久城物语",
        link: "/game/wuyu",
        image: sideMoreWuyu,
      },
    ],
    detailMarkdownUrl: miwuDetailMdUrl,
    comments: [
      {
        userAvatar: Avatar05,
        userName: "狂奔的蜗牛",
        userNameZhHant: "狂奔的蝸牛",
        comment:
          "这款游戏的氛围营造和美术设计相当出色，给人留下了深刻的印象。主线剧情虽然有些许的不足，但总体上还是能够自圆其说，给玩家带来新奇的体验。\n然而，游戏的支线任务设计似乎只是为了数量而存在，对赛博世界的体验并没有多少提升，也缺乏足够的奖励来激励玩家去完成它们。这些谜题小游戏的存在可能会让人觉得有些出戏，希望制作方能够在未来的更新中加入更多的支线任务，以丰富游戏内容，提高玩家的体验度。",
        commentZhHant:
          "這款遊戲的氛圍營造和美術設計相當出色，給人留下了深刻的印象。 主線劇情雖然有些許的不足，但整體上還是能夠自圓其說，帶給玩家新奇的體驗。\n然而，遊戲的支線任務設計似乎只是為了數量而存在，對賽博世界的體驗並沒有太多提升，也缺乏足夠的獎勵來激勵玩家去完成它們。 這些謎題小遊戲的存在可能會讓人覺得有些齣戲，希望製作方能夠在未來的更新中加入更多的支線任務，以豐富遊戲內容，提高玩家的體驗度。",
      },
      {
        userAvatar: Avatar06,
        userName: "梦想家伊卡洛斯",
        userNameZhHant: "夢想家伊卡洛斯",
        comment:
          "在光怪陆离的赛博世界中，解谜游戏往往能够带给人们无尽的乐趣和挑战。这种类型的游戏通常会通过独特的设定和环境来吸引玩家，让人们沉浸在虚构的世界中，并通过解决问题和解密来获得成就感和满足感。\n关于机械人和信仰的问题，如果机械人拥有信仰，那么他们眼中的神可能会被定义为一种高级的存在或是某种神秘的能量。这些信仰可能会基于人类宗教或者是一些全新的理念。不过，这只是一个假设，现实中机械人是否能够拥有真正的信仰还有待探讨。",
        commentZhHant:
          "在光怪陸離的賽博世界中，解謎遊戲往往能夠帶給人們無盡的樂趣和挑戰。這種類型的遊戲通常會透過獨特的設定和環境來吸引玩家，讓人們沉浸在虛構的世界中， 並透過解決問題和解密來獲得成就感和滿足感。\n關於機械人和信仰的問題，如果機械人擁有信仰，那麼他們眼中的神可能會被定義為一種高級的存在或某種神秘的能量。 這些信仰可能會基於人類宗教或是一些全新的理念。 不過，這只是一個假設，現實中機械人是否能擁有真正的信仰還有待探討。",
      },
      {
        userAvatar: Avatar07,
        userName: "雾都探戈",
        userNameZhHant: "霧都探戈",
        comment:
          "这部作品我真的超级爱！它的像素设计塑造出了一种极具吸引力的赛博朋克风格。除此之外，无论是剧情的发展，还是解谜的挑战度，都掌握得恰到好处，让人流连忘返。我还要说的是，游戏里到处都能看到各种各样的文件、见闻、情报，这些元素交织在一起，形成了一个庞大且细腻的世界观。",
        commentZhHant:
          "這部作品我真的超級愛！它的像素設計塑造出了一種極具吸引力的賽博朋克風格。除此之外，無論是劇情的發展，還是解謎的挑戰度，都掌握得 恰到好處，讓人流連忘返。我還要說的是，遊戲裡到處都能看到各種各樣的文件、見聞、情報，這些元素交織在一起，形成了一個龐大且細膩的世界觀。",
      },
    ],
  },
};

GameMap["wuyu"].images.unshift({
  Icon: main1IconWuyu,
  Type: "bilibili",
  Main: "https://www.bilibili.com/blackboard/html5mobileplayer.html?aid=209440148&bvid=BV19h41147fB&cid=448692336&p=1&autoplay=true&danmaku=false&high_quality=1&hideDanmakuButton=true&noFullScreenButton=true&hideCoverInfo=true",
});
GameMap["miwu"].images.unshift({
  Icon: main1IconMiwu,
  Type: "bilibili",
  Main: "https://www.bilibili.com/blackboard/html5mobileplayer.html?aid=580095152&bvid=BV1J64y1W7dt&cid=1375543601&p=1&autoplay=true&danmaku=false&high_quality=1&hideDanmakuButton=true&noFullScreenButton=true&hideCoverInfo=true",
});
GameMap["mantingfang"].images.unshift({
  Icon: main1Mantingfang,
  Type: "bilibili",
  Main: "https://www.bilibili.com/blackboard/html5mobileplayer.html?aid=218536258&bvid=BV1R841147Ni&cid=847955870&p=1&autoplay=true&danmaku=false&high_quality=1&hideDanmakuButton=true&noFullScreenButton=true&hideCoverInfo=true",
});
GameMap["dust"].images.unshift({
  Icon: main1IconDust,
  Type: "bilibili",
  Main: "https://www.bilibili.com/blackboard/html5mobileplayer.html?aid=847583351&bvid=BV1FL4y1Y7q9&cid=396415545&p=1&autoplay=true&danmaku=false&high_quality=1&hideDanmakuButton=true&noFullScreenButton=true&hideCoverInfo=true",
});
GameMap["blue"].images.unshift({
  Icon: main1IconBlue,
  Type: "bilibili",
  Main: "https://www.bilibili.com/blackboard/html5mobileplayer.html?bvid=BV1Np4y177Vj&p=1&autoplay=true&danmaku=false&high_quality=1&hideDanmakuButton=true&noFullScreenButton=true&hideCoverInfo=true",
});
export default GameMap;
