import styles from "./GamePage.module.css";
import CommentList from "./Comments/CommentList";
import Detail from "./Detail";
import Gallery from "./Gallery";
import Brief from "./Brief";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import GameMap from "../../data/gameData";
import Configuration from "./Configuration";
import Statics from "./Statics";
import Support from "./Support";
import { BackTop, Skeleton, Modal, Button } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BlurContext } from "../../contexts/blurContext";
import { extractGameName } from "../../utils/i18n";
import { useDispatch, useSelector } from "react-redux";
import { fetchGame } from "../../features/gameSlice";
import Dlc from "./Dlc";
import SideRecommand from "./SideRecommand";
import { trackViewEvent } from "../../service/trackingService";

const GamePage = () => {
  const isIframe = self !== top; /* eslint-disable-line no-restricted-globals */
  const { slug } = useParams();
  const dispatch = useDispatch();
  const [gallerryloaded, setGalleryLoaded] = useState(false);
  const { t, i18n } = useTranslation();
  const { isBlurred, setIsBlurred } = useContext(BlurContext);
  const [isAgeModalVisible, setIsAgeModalVisible] = useState(false);
  const gameItem = useSelector((state) => state.game.games[slug]);
  const gameFetchErr = useSelector((state) => state.game.error[slug]);
  const [gameName, setGameName] = useState("");
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const language = queryParams.get("language");
  const fid = queryParams.get("fid");

  useEffect(() => {
    trackViewEvent(isIframe, slug, slug, fid);
  }, []);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
      sessionStorage.setItem("language", language);
    }
  }, [language]);

  useEffect(() => {
    if (slug && !gameItem) {
      dispatch(fetchGame(slug));
    }
  }, [slug, gameItem, dispatch]);

    useEffect(() => {
        if (gameItem) {
            document.title = gameName;
            document.querySelector('meta[name="description"]').setAttribute('content', gameName);
            document.querySelector('meta[name="keywords"]').setAttribute('content', `乌图游戏, ${gameName}`);
        }
    }, [gameName]);

    useEffect(() => {
        if (gameFetchErr) {
            window.location.href = "/";
        }
    }, [gameFetchErr]);

  const gameSlug = slug;
  const gameData = GameMap[gameSlug];

  useEffect(() => {
    const isAdultGame = gameItem?.data
      ? gameItem?.data.latestVersion.isAdultGame
      : gameData?.isAdultGame;
    const ageConfirmation = localStorage.getItem(`ageConfirmed-${slug}`);
    if (isAdultGame && !ageConfirmation) {
      setIsAgeModalVisible(true);
      setIsBlurred(true);
    } else {
      setIsBlurred(false);
    }
    if (gameItem) {
      setGameName(extractGameName(gameItem?.data, i18n.language));
    }
    if (gameData) {
      setGameName(extractGameName(gameData, i18n.language));
    }
  }, [gameItem, gameData, slug]);

  const navigate = useNavigate();

  const handleContinue = () => {
    localStorage.setItem(`ageConfirmed-${slug}`, "true");
    setIsAgeModalVisible(false);
    setIsBlurred(false);
  };

  const handleGoBack = () => {
    setIsBlurred(false);
    navigate("/");
  };

  const showDlc =
    gameItem &&
    gameItem.data &&
    (gameItem.data.standaloneGame ||
      (gameItem.data.dlcGames && gameItem.data.dlcGames.length > 0));

  return (
    <div>
      <div
        className={styles.web}
        style={{ filter: isBlurred ? "blur(15px)" : "unset" }}
      >
        <BackTop />
        <div id="content-container" className={styles.row1}>
          <Gallery
            gameData={gameData}
            gameItem={gameItem?.data}
            onImgLoaded={() => {
              setGalleryLoaded(true);
            }}
          />
          <Brief gameData={gameData} gameItem={gameItem?.data} />
        </div>
        <div className={styles.row2}>
          {gallerryloaded ? (
            <>
              <div
                className={styles.div26}
                style={{
                  filter: isBlurred ? "blur(5px)" : "unset",
                  minHeight: "2100px",
                }}
              >
                {showDlc && (
                  <Dlc gameData={gameData} gameItem={gameItem?.data} />
                )}
                <Detail gameData={gameData} gameItem={gameItem?.data} />
                <CommentList gameData={gameData} />
              </div>
              <div
                className={styles.div}
                style={{ filter: isBlurred ? "blur(15px)" : "unset" }}
              >
                <Statics gameData={gameData} gameItem={gameItem?.data} />
                <Configuration gameData={gameData} gameItem={gameItem?.data} />
                <Support />
                <SideRecommand slug={slug} />
              </div>
            </>
          ) : (
            <Skeleton
              active
              style={{
                marginTop: "100px",
              }}
            />
          )}
        </div>
      </div>
      <Modal
        title={"《" + gameName + "》" + t("age_hint")}
        visible={isAgeModalVisible}
        style={{ textAlign: "center" }}
        centered
        width={400}
        footer={
          <div className={styles.ageHintBtnGroup}>
            <Button
              style={{
                height: "48px",
                marginTop: "24px",
              }}
              type="primary"
              onClick={handleContinue}
            >
              {t("continue")}
            </Button>
            <Button
              style={{
                marginLeft: "0px",
                marginTop: "4px",
              }}
              type="text"
              onClick={handleGoBack}
            >
              {t("back_home")}
            </Button>
          </div>
        }
        closable={false}
      />
    </div>
  );
};

export default GamePage;
