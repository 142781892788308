import React, { useEffect, useState } from "react";
import { Space, Table, Button } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { getAllCommentList, auditComment } from "../../../service/gameService";
import { EditableTableComponet, getEditableColumns } from "../../EditableTable";

const pageSize = 10;

function setAuditStatus(status) {
  return status;
}

const CommentList = () => {
  const [comments, setComments] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageSettings, setPageSettings] = useState([0, pageSize]);

  function updateComment(cId, status) {
    const newComments = comments.map((c) => {
      if (c.id === cId) {
        c.auditStatus = status;
      }
      return c;
    });
    setComments(newComments);
  }

  useEffect(() => {
    const fetchComments = async () => {
      const response = await getAllCommentList();
      setComments(response.results);
      setTotal(response.total);
    };

    fetchComments();
  }, [pageSettings]);

  const columns = [
    {
      title: "评论内容",
      dataIndex: "textContent",
      key: "textContent",
    },
    {
      title: "游戏名",
      dataIndex: "gameSlug",
      key: "gameSlug",
    },
    {
      title: "是否推荐",
      dataIndex: "attitude",
      key: "attitude",
    },
    {
      title: "创建时间",
      dataIndex: "createdTime",
      key: "createdTime",
    },
    {
      title: "操作",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          {record.auditStatus === "init" && (
            <Button
              danger
              size="small"
              onClick={() => {
                const status = setAuditStatus("accept");
                auditComment(record.id, status)
                  .then((data) => {
                    updateComment(record.id, status);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            >
              审核通过
            </Button>
          )}
          {record.auditStatus === "init" && (
            <Button
              size="small"
              onClick={() => {
                const userRoles = setAuditStatus("reject");
                auditComment(record.id, "reject")
                  .then((data) => {
                    updateComment(record.id, "reject");
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            >
              审核不通过
            </Button>
          )}
          {record.auditStatus === "accept" && (
            <Button size="small" disabled={true}>
              审核已通过
            </Button>
          )}
          {record.auditStatus === "reject" && (
            <Button size="small" disabled={true}>
              审核已拒绝
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const handleSave = (row) => {
    const newData = [...comments];
    const index = newData.findIndex((item) => row.id === item.id);
    newData[index] = row;
    setComments(newData);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <div style={{ display: "flex", gap: "10px" }}>
        <Button
          onClick={() => {
            window.location.reload();
          }}
          icon={<ReloadOutlined />}
        />
      </div>
      <Table
        components={EditableTableComponet}
        rowClassName={() => "editable-row"}
        columns={getEditableColumns(columns, handleSave)}
        dataSource={comments}
        rowKey={(record) => record.id}
        pagination={{
          total: total,
          pageSize: pageSize,
          hideOnSinglePage: false,
          onChange: (page, pageSize) => {
            setPageSettings([(page - 1) * pageSize, pageSize]);
          },
        }}
        bordered
      />
    </div>
  );
};

export default CommentList;
