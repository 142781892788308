import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  userInfo,
  searchUserByEmail,
  updateUserInfo,
} from "../../service/userService";
import { Modal, Input, Button, message } from "antd";
import styles from "./UserPage.module.css";
import { CloseOutlined } from "@ant-design/icons";
import { sendEmailCodeCall, VerifyEmailCode } from "../../service/authService";
import { useTranslation } from "react-i18next";
import { hideEmailResetModal } from "../../features/userSlice";

const EmailResetModal = ({user}) => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [countDown, setCountDown] = useState(0);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const codeInput = useRef(null);
  const emailInput = useRef(null);
  const isEmailResetModalVisible = useSelector(
    (state) => state.user.isEmailResetModalVisible,
  );

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => setCountDown(countDown - 1), 1000);
    }
  }, [countDown]);

  const sendCode = async () => {
    // pushAutoBaiduEvent(isIframe, {tagA: "login_send_code", tagB: email});
    // Call API to send code
    try {
      await sendEmailCodeCall(email);
      setCountDown(60);
      message.success({
        key: "send_code_success",
        content: t("send_code_success"),
        duration: 5,
      });
    } catch (e) {
      console.log(e);
      message.error({
        key: "send_code_error",
        content: t("send_code_error"),
        duration: 5,
      });
    }
  };
  const onCodeClear = () => setCode("");
  const onEmailClear = () => setEmail("");

  const onEmail = (e) => {
    const { value: inputValue } = e.target;
    // const regex = /^1[3-9]\d{9}$/;
    // if (regex.test(inputValue) || inputValue === "") {
    //   setPhone(inputValue);
    // }
    setEmail(inputValue);
  };

  const onCode = (e) => {
    const { value: inputValue } = e.target;
    const reg = /^\d*(\d*)?$/;
    if (reg.test(inputValue) || inputValue === "") {
      setCode(inputValue);
    }
  };

  const handleVerifyCode = async () => {
    if (user.email === email) {
      message.error({
        key: "reset_email_duplicate",
        content: t("reset_email_duplicate"),
        duration: 5,
      });
      return;
    }

    try {
      // Call API to verify phone has exist or not
      const data = await searchUserByEmail(email);
      if (data !== null) {
        message.error({
          key: "reset_email_exist",
          content: t("reset_email_exist"),
          duration: 5,
        });
        return;
      }
    } catch (e) {
      console.log(e);
    }

    try {
      // Call API to verify code
      await VerifyEmailCode(email, code);
      await updateUserInfo({ email: email });
      message.success({
        key: "reset_success",
        content: t("reset_success"),
        duration: 5,
      });
      dispatch(hideEmailResetModal());
    } catch (e) {
      console.log(e);
      message.error({
        key: "verify_code_error",
        content: t("verify_code_error"),
        duration: 5,
      });
    }
  };

  const modalTitle =
    user.email === "" ? "new_email_title" : "reset_email_title";

  return (
    <Modal
      title={t(modalTitle)}
      open={isEmailResetModalVisible}
      onCancel={() => dispatch(hideEmailResetModal())}
      footer={null}
    >
      <div style={{ marginBottom: "8px" }}>输入邮箱</div>
      <Input
        ref={emailInput}
        className={styles.codeInput}
        suffix={
          code.length > 0 && (
            <CloseOutlined onClick={onEmailClear} className={styles.clear} />
          )
        }
        style={{ backgroundColor: "#efefef" }}
        bordered={false}
        // maxLength={11}
        placeholder={t("email_hint")}
        value={email}
        onChange={onEmail}
      />
      <div style={{ marginTop: "8px" }}>验证码</div>
      <div className={styles.row2}>
        <Input
          // onClick={() => pushAutoBaiduEvent(isIframe, {tagA: "login_code_enter", tagB: phone})}
          ref={codeInput}
          className={styles.codeInput}
          allowClear={{
            clearIcon: <CloseOutlined className={styles.clear} />,
          }}
          style={{ backgroundColor: "#efefef" }}
          bordered={false}
          maxLength={6}
          placeholder={t("code_hint")}
          value={code}
          onChange={onCode}
        />
        <Button
          className={styles.codeBtn}
          onClick={sendCode}
          disabled={countDown}
        >
          {countDown ? countDown + "s" : t("code_input")}
        </Button>
      </div>
      <Button
        className={styles.loginBtn}
        type="primary"
        onClick={handleVerifyCode}
        disabled={code.length !== 6}
      >
        {t("new_phone_verify")}
      </Button>
    </Modal>
  );
};

export default EmailResetModal;
