import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  userInfo,
  searchUserByPhone,
  updateUserInfo,
} from "../../service/userService";
import { Modal, Upload, Input, Button, Checkbox, message } from "antd";
import styles from "./UserPage.module.css";
import { CloseOutlined } from "@ant-design/icons";
import { sendCodeCall, VerifyPhoneCode } from "../../service/authService";
import { useTranslation } from "react-i18next";
import { hideMobileResetModal } from "../../features/userSlice";


const MobileResetModal = ({user}) => {
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [countDown, setCountDown] = useState(0);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const codeInput = useRef(null);
  const phoneInput = useRef(null);
  const isMobileResetModalVisible = useSelector(
    (state) => state.user.isMobileResetModalVisible
  );

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => setCountDown(countDown - 1), 1000);
    }
  }, [countDown]);

  const sendCode = async () => {
    // pushAutoBaiduEvent(isIframe, {tagA: "login_send_code", tagB: phone});
    // Call API to send code
    try {
      await sendCodeCall(phone);
      setCountDown(60);
      message.success({
        key: "send_code_success",
        content: t("send_code_success"),
        duration: 5,
      });
    } catch (e) {
      console.log(e);
      message.error({
        key: "send_code_error",
        content: t("send_code_error"),
        duration: 5,
      });
    }
  };
  const onCodeClear = () => setCode("");
  const onMobileClear = () => setPhone("");

  const onPhone = (e) => {
    const { value: inputValue } = e.target;
    // const regex = /^1[3-9]\d{9}$/;
    // if (regex.test(inputValue) || inputValue === "") {
    //   setPhone(inputValue);
    // }
    setPhone(inputValue);
  };

  const onCode = (e) => {
    const { value: inputValue } = e.target;
    const reg = /^\d*(\d*)?$/;
    if (reg.test(inputValue) || inputValue === "") {
      setCode(inputValue);
    }
  };

  const phoneNumberValidation = (phone) => {
    if (phone.length === 11) {
      return false;
    }
    const regex = /^1[3-9]\d{9}$/;
    return !regex.test(phone);
  };

  const handleVerifyCode = async () => {
    if (user.mobile === phone) {
      message.error({
        key: "reset_phone_duplicate",
        content: t("reset_phone_duplicate"),
        duration: 5,
      });
      return;
    }

    try {
      // Call API to verify phone has exist or not
      const data = await searchUserByPhone(phone);
      if (data !== null) {
        message.error({
          key: "reset_phone_exist",
          content: t("reset_phone_exist"),
          duration: 5,
        });
        return;
      }
    } catch (e) {
      console.log(e);
    }

    try {
      // Call API to verify code
      await VerifyPhoneCode(phone, code);
      await updateUserInfo({ mobile: phone });
      message.success({
        key: "reset_success",
        content: t("reset_success"),
        duration: 5,
      });
      dispatch(hideMobileResetModal());
    } catch (e) {
      console.log(e);
      message.error({
        key: "verify_code_error",
        content: t("verify_code_error"),
        duration: 5,
      });
    }
  };

  const modalTitle =
    user.mobile === "" ? "new_phone_title" : "reset_phone_title";

  return (
    <Modal
      title={t(modalTitle)}
      open={isMobileResetModalVisible}
      onCancel={() => dispatch(hideMobileResetModal())}
      footer={null}
    >
      <div style={{ marginBottom: "8px" }}>输入新手机号</div>
      <Input
          ref={phoneInput}
          className={styles.codeInput}
          suffix={
            code.length > 0 && (
              <CloseOutlined
                onClick={onMobileClear}
                className={styles.clear}
              />
            )
          }
          style={{ backgroundColor: "#efefef" }}
          bordered={false}
          maxLength={11}
          placeholder={t("mobile_hint")}
          value={phone}
          onChange={onPhone}
        />
      <div style={{ marginTop: "8px" }}>
        验证码
      </div>
      <div className={styles.row2}>
        <Input
          // onClick={() => pushAutoBaiduEvent(isIframe, {tagA: "login_code_enter", tagB: phone})}
          ref={codeInput}
          className={styles.codeInput}
          allowClear={{
            clearIcon: <CloseOutlined className={styles.clear} />,
          }}
          style={{ backgroundColor: "#efefef" }}
          bordered={false}
          maxLength={6}
          placeholder={t("code_hint")}
          value={code}
          onChange={onCode}
        />
        <Button
          className={styles.codeBtn}
          onClick={sendCode}
          disabled={phoneNumberValidation(phone) || countDown}
        >
          {countDown ? countDown + "s" : t("code_input")}
        </Button>
      </div>
      <Button
            className={styles.loginBtn}
            type="primary"
            onClick={handleVerifyCode}
            disabled={phoneNumberValidation(phone) || code.length !== 6}
          >
            {t("new_phone_verify")}
          </Button>
    </Modal>
  );
};

export default MobileResetModal;
