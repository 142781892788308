import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchGameBySlug } from "../../../service/gameService";
import styles from "./CommentList.module.css";
import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import commentIcon from "../../../assets/icon/comment.svg";
import { setCommentAreaVisible } from "../../../features/commentSlice";
import { hasEntitlement } from "../../../service/entitlementService";
import { navigate } from "../../../features/userSlice";
import { postOrder } from "../../../service/orderService";

const CommentButton = () => {
  const dispatch = useDispatch();
  const entitlements = useSelector((state) => state.entitlements);
  const user = useSelector((state) => state.user.value);
  const userLoaded = useSelector((state) => state.user.loaded);
  const { slug } = useParams();
  const [game, setGame] = useState(null);
  const { t } = useTranslation();
  const [notPurchasePopupVisible, setNotPurchasePopupVisible] = useState(false);

  const gameSlug = slug;

  useEffect(() => {
    const updateGame = async () => {
      if (gameSlug && !game) {
        const fetchedGame = await fetchGameBySlug(gameSlug);
        setGame(fetchedGame);
      }
    };

    updateGame();
  }, [gameSlug, game, user]);

  const handleStartComment = async () => {
    console.log("comment ", gameSlug, user);
    if (user.roles?.includes("admin")) {
      dispatch(setCommentAreaVisible(true));
    } else if (hasEntitlement(entitlements, gameSlug) && game) {
      dispatch(setCommentAreaVisible(true));
    } else {
      //popup
      setNotPurchasePopupVisible(true);
    }
  };

  const onPurchase = async () => {
    console.log("purchase ", gameSlug, user);
    if (user.id) {
      const order = await postOrder(gameSlug);
      dispatch(
        navigate({
          url: `/order-confirm/${order.id}`,
        }),
      );
    } else {
      dispatch(
        navigate({
          gameSlug,
        }),
      );
    }
  };

  const onCancel = async () => {
    setNotPurchasePopupVisible(false);
  };

  const renderButton = () => {
    return (
      <Button
        type="primary"
        className={styles.buttonprimarylarge}
        onClick={handleStartComment}
      >
        <img
          src={commentIcon}
          alt="sale-icon"
          style={{
            width: "16px",
            marginRight: "2px",
            verticalAlign: "sub",
          }}
        />
        {t("comment_now")}
      </Button>
    );
  };

  const renderModal = () => {
    return (
      <Modal
        style={{ borderRadius: "16px", padding: "24px 32px 12px 32px" }}
        title={t("purchase_before_comment")}
        open={notPurchasePopupVisible}
        onCancel={onCancel}
        footer={
          <div style={{ textAlign: "center", marginTop: "24px" }}>
            <Button
              style={{
                borderRadius: "28px",
              }}
              type="primary"
              onClick={() => {
                onPurchase();
                onCancel();
              }}
            >
              {t("purchase_now")}
            </Button>
          </div>
        }
      ></Modal>
    );
  };

  return (
    <div>
      {(userLoaded && !user.id) || (entitlements && game) ? (
        renderButton()
      ) : (
        <Button
          type="primary"
          loading
          className={styles.buttonprimarysmall}
        ></Button>
      )}
      {renderModal()}
    </div>
  );
};

export default CommentButton;
