import axios from "axios";

const apiClient = axios.create();

apiClient.interceptors.request.use(
  (config) => {
    config.headers.Authorization = localStorage.getItem("token");
    config.headers["X-Token-Type"] = "user";
    return config;
  },
  (error) => Promise.reject(error),
);

apiClient.interceptors.response.use(
    (response) => {
        response.data = replaceStringInData(response.data);
        return response;
    },
    (error) => Promise.reject(error),
);

function replaceStringInData(data) {
    if (typeof data === 'string') {
        return data.replace(
            /gamecenter-cdn\.u4ugames\.com/g,
            'gamecenter-prd-1319533103.cos.accelerate.myqcloud.com'
        );
    } else if (Array.isArray(data)) {
        return data.map(item => replaceStringInData(item));
    } else if (typeof data === 'object' && data !== null) {
        Object.keys(data).forEach(key => {
            data[key] = replaceStringInData(data[key]);
        });
        return data;
    }
    return data;
}

export function getAuthHeaders() {
  const token = localStorage.getItem("token");
  return {
    Authorization: token,
    "X-Token-Type": "user",
  };
}

export default apiClient;
