import React, { useEffect, useState } from "react";
import { Space, Table, Button, Modal, Form, InputNumber } from "antd";
import {
  ExclamationCircleFilled,
  EyeOutlined,
  ReloadOutlined,
} from "@ant-design/icons";

import ObjectPreviewModal from "../../ObjectPreviewModal";
import PromotionModal from "./PromotionEdit";
import {
  deletePromotion,
  promotionList,
} from "../../../service/promotionService";

const { confirm } = Modal;

const PromotionList = () => {
  const [promotions, setPromotions] = useState([]);

  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [previewingObject, setPreviewingObject] = useState(null);

  const showPromiseConfirm = (id) => {
    confirm({
      title: "确认删除这个促销?",
      icon: <ExclamationCircleFilled />,
      async onOk() {
          await deletePromotion(id);
          window.location.reload();
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    const fetchPromotions = async () => {
      const response = promotionList();
      const data = await response;
      setPromotions(data.results);
    };

    fetchPromotions();
  }, []);

  const columns = [
    {
      title: "促销ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "名称",
      dataIndex: "name",
      key: "name",
      editable: false,
    },
    {
      title: "开始时间(UTC时间)",
      dataIndex: "startTime",
      key: "startTime",
      editable: false,
    },
    {
      title: "结束时间(UTC时间)",
      dataIndex: "endTime",
      key: "endTime",
      editable: false,
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      editable: false,
    },
    {
      title: "促销类型",
      dataIndex: "type",
      key: "type",
      editable: false,
    },
    {
      title: "全部内容",
      key: "raw",
      render: (_, record) => (
        <Button
          icon={<EyeOutlined />}
          onClick={() => {
            setPreviewingObject(record);
            setOpenPreviewModal(true);
          }}
        />
      ),
    },
    {
      title: "操作",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <PromotionModal record={record} />
          <Button danger onClick={() => showPromiseConfirm(record.id)}>
            删除
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <div style={{ display: "flex", gap: "8px" }}>
        <PromotionModal />
        <Button
          onClick={() => {
            window.location.reload();
          }}
          icon={<ReloadOutlined />}
        />
      </div>
      <Table
        rowClassName={() => "editable-row"}
        columns={columns}
        dataSource={promotions}
        rowKey={(record) => record.id}
        pagination={{
          hideOnSinglePage: true,
        }}
        onChange={(pagination, filters, sorter, extra) => {
          console.log("params", pagination, filters, sorter, extra);
        }}
        bordered
      />
      <ObjectPreviewModal
        show={openPreviewModal}
        object={previewingObject}
        onCancel={() => {
          setOpenPreviewModal(false);
          setPreviewingObject(null);
        }}
      />
    </div>
  );
};

export default PromotionList;
