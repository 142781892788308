/*
    checkCustomProtocol - check if custom protocol exists
    inProtocol - URL of application to run eg: MyApp://
    inInstallLink - URL to run when the protocol does not exist.
    inTimeOut - time in miliseconds to wait for application to Launch.
*/
export default function checkCustomProtocol(
  inProtocol,
  inInstalLink,
  inTimeOut,
) {
  // Check if Microsoft Edge
  if (navigator.msLaunchUri) {
    navigator.msLaunchUri(
      inProtocol,
      function () {
        //It launched, nothing to do
      },
      function () {
        window.location = inInstalLink; //Launch alternative, typically app download.
      },
    );
  } else {
    // Not Edge
    var timeout = inTimeOut;
    //Set up a listener to see if it navigates away from the page.
    // If so we assume the papplication launched
    window.addEventListener("blur", function (e) {
      window.clearTimeout(timeout);
    });
    //Set a timeout so that if the application does not launch within the timeout we
    // assume the protocol does not exist
    timeout = window.setTimeout(function () {
      window.location = inInstalLink; //Try to launch application
    }, inTimeOut);

    window.location = inProtocol; //Launch alternative, typically app download.
  }
}

export function handleOpenHub() {
  checkCustomProtocol("uatut://", process.env.REACT_APP_HUB_DOWNLOAD_URL, 1000);
}
