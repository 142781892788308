import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Button, Layout, Menu, Spin } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { currentUser } from "../../service/authService";
import { useParams } from 'react-router-dom';
const { Header, Content, Footer, Sider } = Layout;

const PublisherLayout = () => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const { publisherId } = useParams();
    const menuItems = [
        {
            key: "1",
            label: "游戏列表",
            link: `/publisher/${publisherId}/items`,
        },
        {
            key: "2",
            label: "订单列表",
            link: `/publisher/${publisherId}/orders`,
        },
        {
            key: "3",
            label: "游戏数据中心",
            link: `/publisher/${publisherId}/gameDashboard`,
        },
    ];


  useEffect(() => {
    const token = localStorage.getItem("token");
    const checkUser = async (token) => {
      if (token) {
        try {
          const userData = await currentUser();
          if (
            !userData.roles?.includes("publisher") &&
            userData.mobile !== "13761519301"
          ) {
            console.log("not publisher", userData.roles);
            window.location.href = "/";
          }
          setLoaded(true);
        } catch (e) {
          console.log("error", e);
          window.location.href = "/";
        }
      } else {
        window.location.href = "/";
      }
    };
    checkUser(token);
  }, []);

  if (!loaded) {
    return <Spin />;
  }

  return (
    <Layout style={{ background: "white", minHeight: "100vh" }}>
      <Sider
        width={200}
        style={{ background: "#cccccc", flex: "1 1 auto", borderRight: 0 }}
      >
        <Header
          style={{
            background: "#333333",
            padding: "0 0 0 24px",
            fontSize: "20px",
            fontWeight: 600,
            color: "white",
          }}
        >
          开发者平台
        </Header>
        <Menu
          onClick={(e) => {
            // console.log('click', e);
            navigate(menuItems.find((item) => item.key === e.key).link);
          }}
          mode="inline"
          defaultSelectedKeys={["1"]}
          items={menuItems}
          style={{ background: "#cccccc", fontWeight: 600 }}
          selectedKeys={
            menuItems.find((item) => item.link === window.location.pathname)
              ?.key
          }
        />
      </Sider>
      <Layout>
        <Header
          style={{
            background: "#333333",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            ghost
            icon={<HomeOutlined style={{ color: "white" }} />}
            onClick={() => {
              navigate("/");
            }}
          />
        </Header>
        <Content style={{ padding: "16px" }}>
          <Outlet />
        </Content>
        <Footer style={{ textAlign: "center", color: "#cccccc" }}>
          U4U Games
        </Footer>
      </Layout>
    </Layout>
  );
};

export default PublisherLayout;
