import React, { useState, createContext } from "react";

export const BlurContext = createContext();

export const BlurProvider = ({ children }) => {
  const [isBlurred, setIsBlurred] = useState(false);

  return (
    <BlurContext.Provider value={{ isBlurred, setIsBlurred }}>
      {children}
    </BlurContext.Provider>
  );
};
