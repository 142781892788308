import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    value: {},
    isLoginPopupVisible: false,
    isMobileVerifyModalVisible: false,
    isMobileResetModalVisible: false,
    isEmailVerifyModalVisible: false,
    verifyTitle: "phone",
    navigationUrl: null,
    loaded: false,
    refreshInfo: false,
  },
  reducers: {
    login: (state, action) => {
      state.value = action.payload;
      state.loaded = true;
    },
    showLoginPopup: (state) => {
      state.isLoginPopupVisible = true;
    },
    hideLoginPopup: (state) => {
      state.isLoginPopupVisible = false;
    },
    showMobileVerifyModal: (state, action) => {
      state.isMobileVerifyModalVisible = true;
      state.verifyTitle = action.payload;
    },
    hideMobileVerifyModal: (state) => {
      state.isMobileVerifyModalVisible = false;
    },
    showMobileResetModal: (state) => {
      state.isMobileResetModalVisible = true;
    },
    hideMobileResetModal: (state) => {
      state.isMobileResetModalVisible = false;
      state.refreshInfo = !state.refreshInfo;
    },
    showEmailVerifyModal: (state, action) => {
      state.isEmailVerifyModalVisible = true;
      state.verifyTitle = action.payload;
    },
    hideEmailVerifyModal: (state) => {
      state.isEmailVerifyModalVisible = false;
    },
    showEmailResetModal: (state) => {
      state.isEmailResetModalVisible = true;
    },
    hideEmailResetModal: (state) => {
      state.isEmailResetModalVisible = false;
      state.refreshInfo = !state.refreshInfo;
    },
    showPasswordResetModal: (state) => {
      state.isPasswordResetModalVisible = true;
    },
    hidePasswordResetModal: (state) => {
      state.isPasswordResetModalVisible = false;
      state.refreshInfo = !state.refreshInfo;
    },
    navigate: (state, action) => {
      if (
        Object.keys(state.value).length > 0 &&
        state.value.constructor === Object
      ) {
        window.location.href = action.payload.url;
      } else {
        state.navigationUrl = action.payload;
        state.isLoginPopupVisible = true;
      }
    },
  },
});

export const {
  login,
  showLoginPopup,
  hideLoginPopup,
  navigate,
  showMobileVerifyModal,
  hideMobileVerifyModal,
  showMobileResetModal,
  hideMobileResetModal,
  showEmailVerifyModal,
  hideEmailVerifyModal,
  showEmailResetModal,
  hideEmailResetModal,
  showPasswordResetModal,
  hidePasswordResetModal,
} = userSlice.actions;

export default userSlice.reducer;
