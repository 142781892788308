import apiClient from "./apiClient";

export async function fetchGameBySlug(slug) {
  const gameResponse = await apiClient.get(
    `${process.env.REACT_APP_API_URL}/api/game/slug/${slug}`,
  );
  return gameResponse.data;
}

export async function gameList(allStatus, searchName, searchSlug, publisherId) {
  let url = `${process.env.REACT_APP_API_URL}/api/game/list`;
  let params = "?";
  if (allStatus) {
    params += "allStatus=true&";
    url = `${process.env.REACT_APP_API_URL}/api/game/list/admin`;
  }
  if (searchName) {
    params += `name=${searchName}&`;
  }
  if (searchSlug) {
    params += `slug=${searchSlug}&`;
  }
  if (publisherId) {
    params += `publisherId=${publisherId}&`;
  }
  params = params.slice(0, -1);
  url += params;

  const response = await apiClient.get(url);
  return response.data;
}

export async function purchasedGameList(allStatus, searchName, searchSlug) {
  let url = `${process.env.REACT_APP_API_URL}/api/user/games`;

  const response = await apiClient.get(url);
  return response.data;
}

export async function putGame(gameId, data) {
  const response = await apiClient.put(
    `${process.env.REACT_APP_API_URL}/api/game/${gameId}`,
    data,
  );
  return response.data;
}

export async function getGamePackagePutUrl(gameId) {
  const response = await apiClient.get(
    `${process.env.REACT_APP_API_URL}/api/game/${gameId}/package/url/put`,
  );
  return response.data;
}

export async function getGameWithHubPackagePutUrl(gameId) {
  const response = await apiClient.get(
    `${process.env.REACT_APP_API_URL}/api/game/${gameId}/package-hub/url/put`,
  );
  return response.data;
}

export async function getCommentList(slug) {
  const response = await apiClient.get(
    `${process.env.REACT_APP_API_URL}/api/slug/${slug}/comments`,
  );
  return response.data;
}

export async function createGame(game) {
  const response = await apiClient.post(
    `${process.env.REACT_APP_API_URL}/api/admin/game`,
    game,
  );
  return response.data;
}

export async function deleteGame(id) {
  await apiClient.delete(
    `${process.env.REACT_APP_API_URL}/api/admin/game/${id}`,
  );
}

export async function uploadImg(formData) {
  const response = await apiClient.post(
    `${process.env.REACT_APP_API_URL}/api/admin/game/image`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    },
  );
  if (response.data && response.data.length > 0) {
    return response.data[0];
  }
  return "";
}

export async function getAllCommentList() {
  const response = await apiClient.get(
    `${process.env.REACT_APP_API_URL}/api/comments`,
  );
  return response.data;
}

export async function postComment(slug, userId, textContent, attitude) {
  const response = await apiClient.post(
    `${process.env.REACT_APP_API_URL}/api/slug/${slug}/comment`,
    {
      attitude,
      textContent,
    },
  );
  return response.data;
}

export async function auditComment(commentId, auditStatus) {
  const response = await apiClient.post(
    `${process.env.REACT_APP_API_URL}/api/audit/${commentId}`,
    {
      auditStatus,
    },
  );
  return response.data;
}
