import { useSelector } from "react-redux";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import styles from "./CommentList.module.css";
import { useEffect, useRef, useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { getCommentList } from "../../../service/gameService";
import CommentButton from "./CommentButton";
import likeIcon from "../../../assets/icon/like.svg";
import frownIcon from "../../../assets/icon/frown.svg";
import CommentArea from "./CommentArea";
import { AvatarMap } from "../../../data/gameData";
import { NickNameMap } from "../../../data/nickNameData";
import { pushAutoBaiduEvent } from "../../../utils/baidu";

const CommentList = ({ gameData }) => {
  const isIframe = self !== top; /* eslint-disable-line no-restricted-globals */
  const [detailExpand, setDetailExpand] = useState(false);
  const [contentHeight, setContentHeight] = useState("0px");
  const [showToggleButton, setShowToggleButton] = useState(true);
  const contentRef = useRef(null);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [comments, setComments] = useState([]);
  const user = useSelector((state) => state.user.value);
  const commentAreaVisible = useSelector(
    (state) => state.comment.commentAreaVisible,
  );
  const showRealComment = !gameData;
  const showCommentBtn =
    process.env.REACT_APP_API_ENV !== "prod" || user.roles?.includes("admin");
  const { slug } = useParams();

  const gameSlug = slug;

  useEffect(() => {
    const fetchComments = async () => {
      const response = getCommentList(gameSlug);
      const data = await response;
      setComments(data.results);
    };

    fetchComments();
  }, []);

  const checkContentHeight = () => {
    const contentHeight = contentRef.current
      ? contentRef.current.scrollHeight
      : 0;
    setShowToggleButton(contentHeight > 800); // Set your threshold height here
  };

  useEffect(() => {
    checkContentHeight();
  }, [gameData?.comments, comments]);

  useEffect(() => {
    if (detailExpand) {
      setContentHeight(`${contentRef.current.scrollHeight + 100}px`);
    } else {
      setContentHeight("0px");
    }
  }, [detailExpand, gameData?.comments]);

  const handleExpandDetail = () => {
    pushAutoBaiduEvent(isIframe, {
      tagA: "trigger_comment_expand",
      tagB: gameData?.slug,
    });
    setDetailExpand(!detailExpand);
  };

  const renderCommentItems = () => {
    return gameData?.comments.map((item, index) => {
      return (
        <div className={styles.div49}>
          <div className={styles.ellipseParent}>
            <div className={styles.frameItem}>
              <img
                className={styles.userAvatar}
                src={item.userAvatar}
                alt="user-avatar"
              />
            </div>
            <div className={styles.yydsParent}>
              <div className={styles.yyds}>
                {i18n.language === "zhHant" && item.userNameZhHant
                  ? item.userNameZhHant
                  : item.userName || t("annoymous_user")}
              </div>
              <div className={styles.like}>
                {i18n.language === "zhHant" && item.commentZhHant
                  ? item.commentZhHant
                  : item.comment}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const renderRealCommentItems = () => {
    if (!comments || comments.length === 0) {
      return;
    }
    return comments.map((item, index) => {
      var avatarIndex = (parseInt(item.user) + item.offset) % AvatarMap.length;
      var nickNameIndex =
        (parseInt(item.user) + item.offset) % NickNameMap.length;
      return (
        <div className={styles.div49}>
          <div className={styles.ellipseParent}>
            <div className={styles.frameItem}>
              <img
                className={styles.userAvatar}
                src={AvatarMap[avatarIndex]}
                alt="user-avatar"
              />
            </div>
            <div className={styles.yydsParent}>
              <div className={styles.userWrapper}>
                <div className={styles.yyds}>{NickNameMap[nickNameIndex]}</div>
                {item.attitude === "like" && (
                  <Button className={styles.attitudeLikeBtn} type="primary">
                    <img
                      src={likeIcon}
                      alt="sale-icon"
                      style={{
                        width: "16px",
                        marginRight: "2px",
                        verticalAlign: "sub",
                      }}
                    />
                    {t("attitude_like")}
                  </Button>
                )}
                {item.attitude === "dislike" && (
                  <Button
                    className={styles.attitudeFrownBtnActive}
                    type="primary"
                  >
                    <img
                      src={frownIcon}
                      alt="sale-icon"
                      style={{
                        width: "16px",
                        marginRight: "2px",
                        verticalAlign: "sub",
                      }}
                    />
                    {t("attitude_dislike")}
                  </Button>
                )}
              </div>
              <div className={styles.like}>{item.textContent}</div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={styles.div47}>
      <div
        className={cx(styles.div48, detailExpand && styles.expanded)}
        style={{ maxHeight: detailExpand ? contentHeight : "850px" }}
      >
        <div className={styles.inner2}>
          <div className={styles.inner}>
            <div className={styles.rectangleParent}>
              <div className={styles.instanceChild} />
              <div className={styles.dlc2}>{t("user_comment")}</div>
            </div>
          </div>
          {showCommentBtn && !!user.id && (
            <CommentButton game={gameData} className={styles.purchaseButton} />
          )}
        </div>
        <div
          style={{
            flexDirection: "column",
            gap: "16px",
            display: "flex",
            width: "100%",
          }}
          ref={contentRef}
        >
          {showCommentBtn && !!user.id && !!commentAreaVisible && (
            <CommentArea />
          )}
          {!showRealComment && renderCommentItems()}
          {showRealComment && renderRealCommentItems()}
        </div>
      </div>
      {showToggleButton && (
        <div className={styles.div46} onClick={handleExpandDetail}>
          <div className={styles.item} />
          <div className={styles.linkdefault}>
            {detailExpand ? (
              <UpOutlined className={styles.iconDown} />
            ) : (
              <DownOutlined className={styles.iconDown} />
            )}
            <div className={styles.button}>
              {detailExpand ? t("fold_comment") : t("unfold_comment")}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommentList;
