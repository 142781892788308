import { createSlice } from "@reduxjs/toolkit";

export const commentSlice = createSlice({
  name: "comment",
  initialState: {
    commentAreaVisible: false,
  },
  reducers: {
    setCommentAreaVisible: (state, action) => {
      state.commentAreaVisible = action.payload;
    },
  },
});

export const { setCommentAreaVisible } = commentSlice.actions;

export default commentSlice.reducer;
