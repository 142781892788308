import React, { useEffect, useRef, useState } from "react";
import { Button, Input, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
// import { login, navigate } from "../../../features/userSlice";
import { useParams } from "react-router-dom";
import styles from "./CommentList.module.css";
import { postComment } from "../../../service/gameService";
import { useTranslation } from "react-i18next";
import { showLoginPopup } from "../../../features/userSlice";
import { fetchGameBySlug } from "../../../service/gameService";
import cls from "classnames";
import likeIcon from "../../../assets/icon/like.svg";
import frownIcon from "../../../assets/icon/frown.svg";
import { AvatarMap } from "../../../data/gameData";

const maxCommentLength = 200;

const { TextArea } = Input;

const CommentArea = ({}) => {
  const [attitude, setAttitude] = useState("like");
  const [textContent, setTextContent] = useState("");
  const [areaVisible, setAreaVisible] = useState(true);
  const textContentInput = useRef(null);
  const dispatch = useDispatch();
  const isCommentAreaVisible = useSelector(
    (state) => state.user.isCommentAreaVisible,
  );
  const { t, i18n } = useTranslation();
  const navigationUrl = useSelector((state) => state.user.navigationUrl);
  const user = useSelector((state) => state.user.value);
  const { slug } = useParams();
  const [game, setGame] = useState(null);

  useEffect(() => {
    if (textContent.length > 0) {
      // textContent.current.focus();
    }
  }, [textContent]);

  useEffect(() => {
    const updateGame = async () => {
      if (slug && !game) {
        const fetchedGame = await fetchGameBySlug(slug);
        setGame(fetchedGame);
      }
    };

    updateGame();
  }, [slug, game, user]);

  const onTextContent = (e) => {
    const { value: inputValue } = e.target;
    setTextContent(inputValue);
    if (inputValue.trim().length < 6) {
      message.error({ content: t("min_char_num_is_6") });
    }
  };

  const handleLike = async () => {
    if (!user || !user.id) {
      console.log("dispatch login");
      dispatch(showLoginPopup());
    } else if (user.id) setAttitude("like");
  };
  const handleDislike = async () => {
    if (!user || !user.id) {
      console.log("dispatch login");
      dispatch(showLoginPopup());
    } else if (user.id) console.log("dislike ", slug, user);
    setAttitude("dislike");
  };

  const handleComment = async () => {
    if (!user || !user.id) {
      console.log("dispatch login");
      dispatch(showLoginPopup());
      // setIsLoading(false);
    } else if (user && user.id) console.log("comment ", slug, user);
    if (textContent.trim().length < 6) {
      message.error({ content: t("min_char_num_is_6") });
    } else {
      try {
        await postComment(slug, user.id, textContent, attitude);
        message.success({ content: t("comment_success") });
      } catch (e) {
        message.error({
          content: t("comment_failure"),
          duration: 5,
        });
      }
      setTextContent("");
      setAreaVisible(false);
    }
  };

  if (!areaVisible) {
    return;
  }

  return (
    <div className={styles.div49}>
      <div className={styles.ellipseParent}>
        <div className={styles.frameItem}>
          <img
            className={styles.userAvatar}
            src={AvatarMap[user.mobile % 10]}
            alt="user-avatar"
          />
        </div>
        <div className={styles.yydsParent}>
          <TextArea
            rows={6}
            showCount
            ref={textContentInput}
            className={"textContentInputWrapper"}
            classNames={{
              textarea: cls(styles.textContentInput, {
                [styles.textContentExceed]:
                  textContent.length >= maxCommentLength,
              }),
              count: cls(styles.textContentCount, {
                [styles.textContentExceed]:
                  textContent.length >= maxCommentLength,
              }),
            }}
            style={{
              border: "1px solid #BFCBD4",
              borderRadius: "8px",
              height: "143px",
            }}
            bordered={false}
            maxLength={maxCommentLength}
            placeholder={t("comment_hint")}
            value={textContent}
            onChange={onTextContent}
          />
          <div className={styles.attitudeWrapper}>
            <div className={styles.attitudeLeftWrapper}>
              <div className={styles.likeHint}>{t("like_or_not")}</div>
              <Button
                className={
                  attitude === "like"
                    ? styles.attitudeLikeBtn
                    : styles.attitudeLikeBtnDisactive
                }
                type="primary"
                onClick={handleLike}
                disabled={textContent.length <= 0}
              >
                <img
                  src={likeIcon}
                  alt="sale-icon"
                  style={{
                    width: "16px",
                    marginRight: "2px",
                    verticalAlign: "sub",
                  }}
                />
                {t("attitude_like")}
              </Button>
              <Button
                className={
                  attitude === "dislike"
                    ? styles.attitudeFrownBtnActive
                    : styles.attitudeFrownBtn
                }
                type="primary"
                onClick={handleDislike}
                disabled={textContent.length <= 0}
              >
                <img
                  src={frownIcon}
                  alt="sale-icon"
                  style={{
                    width: "16px",
                    marginRight: "2px",
                    verticalAlign: "sub",
                  }}
                />
                {t("attitude_dislike")}
              </Button>
            </div>
            <Button
              className={styles.loginBtn}
              type="primary"
              onClick={handleComment}
              disabled={textContent.length <= 0}
            >
              {t("publish_now")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentArea;
