import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Input, Checkbox, message, Menu } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { login, navigate } from "../../features/userSlice";
import {
  loginUserByCode,
  loginUserByPassword,
  sendCodeCall,
  sendEmailCodeCall,
} from "../../service/authService";
import {
  hasEntitlement,
  userEntitlements,
} from "../../service/entitlementService";
import { setEntitlements } from "../../features/entitilementSlice";
import { hideLoginPopup } from "../../features/userSlice";
import styles from "./LoginPopup.module.css";
import { postOrder } from "../../service/orderService";
import { useTranslation } from "react-i18next";
import * as baidu from "../../utils/baidu";
import { pushAutoBaiduEvent } from "../../utils/baidu";
import { trackViewEvent } from "../../service/trackingService";
import siteTitleIconBlack from "../../assets/icon/siteTitleBlack.svg";
import siteTitleHantIconBlack from "../../assets/icon/siteTitleHantBlack.svg";
import logoIcon from "../../assets/icon/logoWhite.svg";

const LoginPopup = () => {
  const isIframe = self !== top; /* eslint-disable-line no-restricted-globals */
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [activeTab, setActiveTab] = useState("smsCode");
  const [countDown, setCountDown] = useState(0);
  const [firstCount, setFirstCount] = useState(true);
  const phoneInput = useRef(null);
  const codeInput = useRef(null);
  const dispatch = useDispatch();
  const isLoginPopupVisible = useSelector(
    (state) => state.user.isLoginPopupVisible,
  );
  const { t, i18n } = useTranslation();
  const navigationUrl = useSelector((state) => state.user.navigationUrl);
  const [termChecked, setTermChecked] = useState(false);

  useState(() => {
    setActiveTab("smsCode");
  }, []);

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => setCountDown(countDown - 1), 1000);
    }
  }, [countDown]);

  useEffect(() => {
    if (isLoginPopupVisible) {
      trackViewEvent(isIframe, "login", "login");
    }
  }, [isLoginPopupVisible]);

  const sendCode = async () => {
    pushAutoBaiduEvent(isIframe, { tagA: "login_send_code", tagB: phone });
    // Call API to send code
    try {
      if (phone.indexOf("@") != -1) {
        await sendEmailCodeCall(phone);
      } else {
        const reg = /^\d*(\d*)?$/;
        if (!reg.test(phone) || phone.length !== 11) {
          message.error({
            key: "send_code_invalid",
            content: t("send_code_invalid"),
            duration: 5,
          });
          return;
        }
        await sendCodeCall(phone);
      } 
      setCountDown(60);
      setFirstCount(false);
      message.success({
        key: "send_code_success",
        content: t("send_code_success"),
        duration: 5,
      });
    } catch (e) {
      console.log(e);
      message.error({
        key: "send_code_error",
        content: t("send_code_error"),
        duration: 5,
      });
    }
  };
  const onCodeClear = () => setCode("");
  const onPhoneClear = () => setPhone("");
  const onPasswordClear = () => setPassword("");

  const onPhoneNumber = (e) => {
    const { value: inputValue } = e.target;
    setPhone(inputValue);
  };

  useEffect(() => {
    if (phone.length > 0) {
      phoneInput.current.focus();
    }
  }, [phone]);
  useEffect(() => {
    if (code.length > 0) {
      codeInput.current.focus();
    }
  }, [code]);

  const onCode = (e) => {
    const { value: inputValue } = e.target;
    const reg = /^\d*(\d*)?$/;
    if (reg.test(inputValue) || inputValue === "") {
      setCode(inputValue);
    }
  };

  const onPassword = (e) => {
    const { value: inputValue } = e.target;
    setPassword(inputValue);
  };

  const onTermsCheck = (e) => {
    pushAutoBaiduEvent(isIframe, { tagA: "login_term", tagB: phone });
    setTermChecked(e.target.checked);
  };

  const handleLoginUser = async () => {
    try {
      // Fetch user info
      var userData;
      if (code !== "") {
        userData = await loginUserByCode(phone, code);
      } else {
        userData = await loginUserByPassword(phone, password);
      }
      pushAutoBaiduEvent(isIframe, { tagA: "login_success", tagB: "" });
      message.success({ key: "login_success", content: t("login_success") });
      // Save user info to Redux
      dispatch(login(userData));
      baidu.setUserId(userData.id);
      const currentLang = sessionStorage.getItem("language");
      if (!currentLang) {
        i18n.changeLanguage("zh");
      } else {
        i18n.changeLanguage(currentLang);
      }
      // Close Popup
      const entitlementData = await userEntitlements(userData.id);
      dispatch(setEntitlements(entitlementData));
      dispatch(hideLoginPopup());
      if (
        navigationUrl &&
        navigationUrl.gameSlug &&
        !hasEntitlement(entitlementData, navigationUrl.gameSlug)
      ) {
        pushAutoBaiduEvent(isIframe, {
          tagA: "purchase",
          tagB: navigationUrl.gameSlug,
        });
        const order = await postOrder(navigationUrl.gameSlug);
        pushAutoBaiduEvent(isIframe, { tagA: "login", tagB: "purchase" });
        dispatch(
          navigate({
            url: `/order-confirm/${order.id}`,
          }),
        );
      } else {
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (err) {
      console.error(err);
      if (code !== "") {
        message.error({
          key: "login_error",
          content: t("login_error"),
        });
      } else {
        if (err.response.data.errorCode === "ResourceNotFound") {
          message.error({
            key: "login_error_not_found",
            content: t("login_error_not_found"),
          });
        } else {
          message.error({
            key: "login_error_psd",
            content: t("login_error_psd"),
          });
        }
       
      }   
      // handle error
    }
  };

  const phoneNumberValidation = (phone) => {
    if (phone.length === 8) {
      return false;
    }
    const regex = /^1[3-9]\d{9}$/;
    return !regex.test(phone);
  };

  const menu = (
    <Menu mode="horizontal" style={{ marginBottom: "16px" }}>
      <Menu.Item>
        <div
          className={
            activeTab === "smsCode" ? styles.menuItemWrapperSelected : null
          }
          onClick={() => setActiveTab("smsCode")}
        >
          验证码登录
        </div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => setActiveTab("password")}>密码登录</div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Modal
        // title={
        //   <div className={styles.titleWrapper}>
        //     <div className={styles.title}>{t("login_title")}</div>
        //   </div>
        // }
        open={isLoginPopupVisible}
        closeIcon={<CloseOutlined className={styles.close} />}
        width={300}
        onCancel={() => dispatch(hideLoginPopup())}
        footer={null}
      >
        <div className={styles.logoWrap}>
          <img className={styles.button} src={logoIcon} alt="icon" />
          {i18n.language === "zhHant" ? (
            <img
              src={siteTitleHantIconBlack}
              alt="sale-icon"
              style={{
                width: "96px",
                marginTop: "7px",
                marginBottom: "10px",
              }}
            />
          ) : (
            <img
              src={siteTitleIconBlack}
              alt="sale-icon"
              style={{
                width: "96px",
                marginTop: "7px",
                marginBottom: "10px",
              }}
            />
          )}
        </div>
        {menu}
        <div className={styles.wrapper}>
          <div className={styles.row1}>
            {/* <div className={styles.phonePrefix}>+86</div> */}
            <Input
              onClick={() =>
                pushAutoBaiduEvent(isIframe, {
                  tagA: "login_phone_enter",
                  tagB: phone,
                })
              }
              ref={phoneInput}
              className={styles.phoneInput}
              suffix={
                phone.length > 0 && (
                  <CloseOutlined
                    onClick={onPhoneClear}
                    className={styles.clear}
                  />
                )
              }
              style={{ backgroundColor: "#efefef" }}
              bordered={false}
              // maxLength={11}
              placeholder={t("phone_hint")}
              value={phone}
              onChange={onPhoneNumber}
            />
          </div>
          {activeTab === "smsCode" && (
            <div>
              <div className={styles.row2}>
                <Input
                  onClick={() =>
                    pushAutoBaiduEvent(isIframe, {
                      tagA: "login_code_enter",
                      tagB: phone,
                    })
                  }
                  ref={codeInput}
                  className={styles.codeInput}
                  suffix={
                    code.length > 0 && (
                      <CloseOutlined
                        onClick={onCodeClear}
                        className={styles.clear}
                      />
                    )
                  }
                  style={{ backgroundColor: "#efefef" }}
                  bordered={false}
                  maxLength={6}
                  placeholder={t("code_hint")}
                  value={code}
                  onChange={onCode}
                />
                <Button
                  className={styles.codeBtn}
                  onClick={sendCode}
                  disabled={countDown}
                >
                  {countDown ? countDown + "s" : (firstCount ?  t("code_input") : t("code_input_again"))}
                </Button>
              </div>
              <Button
                className={styles.loginBtn}
                type="primary"
                onClick={handleLoginUser}
                disabled={!termChecked || code.length !== 6}
              >
                {t("register")}
              </Button>
            </div>
          )}
          {activeTab === "password" && (
            <div>
              <div className={styles.row2}>
                <Input
                  type="password"
                  onClick={() =>
                    pushAutoBaiduEvent(isIframe, {
                      tagA: "login_code_enter",
                      tagB: phone,
                    })
                  }
                  ref={codeInput}
                  className={styles.codeInput}
                  suffix={
                    code.length > 0 && (
                      <CloseOutlined
                        onClick={onPasswordClear}
                        className={styles.clear}
                      />
                    )
                  }
                  style={{ backgroundColor: "#efefef" }}
                  bordered={false}
                  // maxLength={6}
                  placeholder={t("password_hint")}
                  value={password}
                  onChange={onPassword}
                />
              </div>
              <Button
                className={styles.loginBtn}
                type="primary"
                onClick={handleLoginUser}
                disabled={!termChecked}
              >
                {t("login")}
              </Button>
            </div>
          )}
          <div className={styles.row3}>
            <Checkbox onChange={onTermsCheck} className={styles.term}>
              {t("terms.start")}
              <a
                onClick={() =>
                  pushAutoBaiduEvent(isIframe, {
                    tagA: "login_term_a",
                    tagB: phone,
                  })
                }
                href="/public/乌图游戏_用户服务协议.pdf"
              >
                {t("terms.user_service_agreement")}
              </a>
              {t("terms.and")}
              <a
                onClick={() =>
                  pushAutoBaiduEvent(isIframe, {
                    tagA: "login_term_b",
                    tagB: phone,
                  })
                }
                href="/public/乌图游戏_个人信息处理规则.pdf"
              >
                {t("terms.personal_information_processing_rules")}
              </a>
            </Checkbox>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LoginPopup;
