import { Modal } from "antd";

export const ConfirmDialog = ({
  show,
  title,
  message,
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal
      title={title}
      open={show}
      onCancel={onCancel}
      onOk={() => {
        onConfirm();
        onCancel();
      }}
      okText={"确认"}
      cancelText={"取消"}
    >
      {message}
    </Modal>
  );
};
