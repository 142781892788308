import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userInfo, updateUserInfo } from "../../service/userService";
import { Modal, Input, Button, message } from "antd";
import styles from "./UserPage.module.css";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { hidePasswordResetModal } from "../../features/userSlice";
import md5 from "js-md5";

const PasswordResetModal = ({user}) => {
  const [fPassword, setFPassword] = useState("");
  const [sPassword, setSPassword] = useState("");
  const [fErrMsg, setFErrMsg] = useState("");
  const [sErrMsg, setSErrMsg] = useState("");
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const fPasswordInput = useRef(null);
  const sPasswordInput = useRef(null);
  const isPasswordResetModalVisible = useSelector(
    (state) => state.user.isPasswordResetModalVisible,
  );

  const onFPassword = (e) => {
    const { value: inputValue } = e.target;
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d. _@$!%*?&]{8,}$/;
    if (!regex.test(inputValue)) {
      setFErrMsg("not_match");
    } else if (inputValue === "") {
      setFErrMsg("f_null");
    } else {
      setFErrMsg("");
    }
    setFPassword(inputValue);
  };

  const onSPassword = (e) => {
    const { value: inputValue } = e.target;
    if (inputValue === "" && fPassword !== "") {
      setSErrMsg("s_null");
    } else if (inputValue !== fPassword) {
      setSErrMsg("inconsistent");
    } else {
      setSErrMsg("");
    }
    setSPassword(inputValue);
  };

  const handleSavePassword = async () => {
    if (fPassword !== sPassword) {
      message.error({
        key: "inconsistent",
        content: t("inconsistent"),
        duration: 5,
      });
      return;
    }
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d. _@$!%*?&]{8,}$/;
    if (!regex.test(fPassword)) {
      message.error({
        key: "invalid_password",
        content: t("invalid_password"),
        duration: 5,
      });
      return;
    }
    try {
      await updateUserInfo({ password: md5(fPassword) });
      message.success({
        key: "reset_success",
        content: t("reset_success"),
        duration: 5,
      });
      dispatch(hidePasswordResetModal());
      setFPassword("");
      setSPassword("");
      setFErrMsg("");
      setSErrMsg("");
    } catch (e) {
      console.log(e);
      message.error({
        key: "reset_failure",
        content: t("reset_failure"),
        duration: 5,
      });
    }
  };

  const modalTitle =
    user.password === "" ? "new_password_title" : "reset_password_title";

  return (
    <Modal
      title={t(modalTitle)}
      open={isPasswordResetModalVisible}
      onCancel={() => {
        dispatch(hidePasswordResetModal());
        setFPassword("");
        setSPassword("");
      }}
      footer={null}
    >
      <div style={{ marginBottom: "8px" }}>密码</div>
      <Input
        type="password"
        ref={fPasswordInput}
        className={styles.codeInput}
        allowClear={{
          clearIcon: <CloseOutlined className={styles.clear} />,
        }}
        style={{ backgroundColor: "#efefef" }}
        bordered={false}
        maxLength={15}
        placeholder={t("f_password_hint")}
        value={fPassword}
        onChange={onFPassword}
      />
      {fErrMsg ? <div className={styles.errMsg}>{t(fErrMsg)}</div> : null}
      <div style={{ marginBottom: "8px" }}>重新输入密码</div>
      <Input
        type="password"
        ref={sPasswordInput}
        className={styles.codeInput}
        allowClear={{
          clearIcon: <CloseOutlined className={styles.clear} />,
        }}
        style={{ backgroundColor: "#efefef" }}
        bordered={false}
        maxLength={15}
        placeholder={t("s_password_hint")}
        value={sPassword}
        onChange={onSPassword}
      />
      {sErrMsg ? <div className={styles.errMsg}>{t(sErrMsg)}</div> : null}
      <Button
        className={styles.loginBtn}
        type="primary"
        onClick={handleSavePassword}
        disabled={fErrMsg === "" && sErrMsg !== ""}
      >
        {t("new_phone_verify")}
      </Button>
    </Modal>
  );
};

export default PasswordResetModal;
