import AppHeader from "./AppHeader";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { currentUser } from "../../service/authService";
import { login } from "../../features/userSlice";
import { userEntitlements } from "../../service/entitlementService";
import { setEntitlements } from "../../features/entitilementSlice";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
import { BlurProvider } from "../../contexts/blurContext";

const Layout = () => {
  const dispatch = useDispatch();
  const isIframe = self !== top; /* eslint-disable-line no-restricted-globals */
  const { i18n } = useTranslation();

  useEffect(() => {
    const currentLang = sessionStorage.getItem("language");
    const checkUser = async (token) => {
      if (token) {
        try {
          const userData = await currentUser();
          dispatch(login(userData));
          if (!currentLang) {
            i18n.changeLanguage("zh");
          } else {
            i18n.changeLanguage(currentLang);
          }
          const entitlementData = await userEntitlements(userData.id);
          dispatch(setEntitlements(entitlementData));
        } catch (error) {
          dispatch(login({}));
          console.error("Error during token verification:", error);
          localStorage.removeItem("token");
        }
      } else {
        if (currentLang) {
          i18n.changeLanguage(currentLang);
        }
        dispatch(login({}));
      }
    };

    const receiveMessage = (event) => {
      // if (event.origin !== 'http://localhost:7777') { // replace with the origin of the parent window
      //     return;
      // }
      const token = event.data;
      localStorage.setItem("token", token);
      checkUser(token);
    };

    const token = localStorage.getItem("token");
    checkUser(token);

    if (self !== top /* eslint-disable-line no-restricted-globals */) {
      window.addEventListener("message", receiveMessage, false);
    }
    return () => {
      if (self !== top /* eslint-disable-line no-restricted-globals */) {
        window.removeEventListener("message", receiveMessage, false);
      }
    };
  }, [dispatch]);

  return (
    <BlurProvider>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        {!isIframe && <AppHeader />}
        <main
          className="app-content"
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Outlet />
        </main>
        {!isIframe && <Footer />}
      </div>
    </BlurProvider>
  );
};

export default Layout;
