import React, { useState } from "react";
import { Modal, DatePicker } from "antd";
import {useParams} from "react-router-dom";

const { RangePicker } = DatePicker;

const GetCSVModal = ({ open, handleClose }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
    const { publisherId } = useParams();

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      title="导出CSV统计"
      okText={"确认"}
      cancelText={"取消"}
      onOk={() => {
        console.log(startDate, endDate);
        if (startDate && endDate) {
          window.open(
            `${process.env.REACT_APP_API_URL}/api/order/statistics?startDate=${startDate}&endDate=${endDate}&publisherId=${publisherId || ''}`,
          );
        }
      }}
    >
      <p
        style={{
          marginBottom: 8,
        }}
      >
        请选择日期区间：
      </p>
      <RangePicker
        onChange={(_dates, dateStrings) => {
          console.log(dateStrings);
          setStartDate(dateStrings[0]);
          setEndDate(dateStrings[1]);
        }}
      />
    </Modal>
  );
};

export default GetCSVModal;
