import { Modal, Upload, Input, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { putGame } from "../../../service/gameService";

const { Dragger } = Upload;

const UpdatePackageHubModal = (props) => {
  const [fileList, setFileList] = useState([]);
  const [exeName, setExeName] = useState(
    props.exePath?.replace(".exe", "") || "",
  );
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    console.log(props);
    setExeName(props.exePath?.replace(".exe", "") || "");
  }, [props.exePath]);

  return (
    <Modal
      title="上传游戏包并更新（联想）"
      open={props.open}
      onCancel={() => {
        setFileList([]);
        props.onCancel();
      }}
      onOk={() => {
        if (fileList.length === 0 || exeName === "" || isUploading) {
          message.error("请完成游戏包上传并填写EXE名称");
          return;
        }

        putGame(props.gameId, {
          packageUploads: {
            "lenovo" : {
              exePath: exeName + ".exe",
              // md5: "string",
              url: [
                `https://gamecenter-cdn.u4ugames.com/${props.putGamePackageName}`,
              ],
            }
          }
        }).then((_) => {
          setFileList([]);
          props.onCancel();
          window.location.reload();
        });
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <div>
          <p style={{ margin: 0 }}>{`ID: ${props.gameId}`}</p>
          <p style={{ margin: 0 }}>{`名称: ${props.gameName}`}</p>
          <p style={{ margin: 0 }}>{`包名: ${props.putGamePackageName}`}</p>
        </div>
        <Dragger
          multiple={false}
          action={props.putGameUrl}
          method="put"
          accept=".zip"
          fileList={fileList}
          beforeUpload={(file) => {
            const isZIP =
              file.type === "application/x-zip-compressed" ||
              file.type === "application/zip";
            if (!isZIP) {
              message.error("只能上传ZIP格式文件!");
            }
            console.log(file);
            return isZIP || Upload.LIST_IGNORE;
          }}
          onChange={(info) => {
            let fileList = [...info.fileList];
            fileList = fileList.slice(-1);

            const { status } = info.file;

            if (status !== "uploading") {
              console.log(info.file, info.fileList);
            } else {
              setIsUploading(true);
            }
            if (status === "done") {
              setIsUploading(false);
              message.success(`${info.file.name} 上传成功.`);
              // props.onRefreshUrl();
            } else if (status === "error") {
              setIsUploading(false);
              message.error(`${info.file.name} 上传失败.`);
              console.log(info);
              props.onRefreshUrl();
            }

            setFileList(fileList);
          }}
          onDrop={(e) => {
            console.log("Dropped files", e.dataTransfer.files);
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">点击或拖拽文件到此区域上传游戏包</p>
          <p className="ant-upload-hint">
            上传时请勿关闭此页面，上传完成后填写完EXE名称后点击确认
          </p>
        </Dragger>
        <div>
          <p style={{ margin: 0, marginBottom: 6 }}>输入游戏启动EXE名称:</p>
          <Input
            placeholder="请输入EXE名称"
            addonAfter=".exe"
            onChange={(e) => {
              setExeName(e.target.value);
            }}
            value={exeName}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UpdatePackageHubModal;
