import styles from "./OrderConfirm.module.css";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getOrderById } from "../../service/orderService";
import GameMap from "../../data/gameData";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Radio } from "antd";
import { useTranslation } from "react-i18next";
import { extractGameName } from "../../utils/i18n";
import { useNavigate } from "react-router-dom";
import NonHostedCheckoutButton from "./NonHostedCheckoutButton";
import alipayIcon from "../../assets/icon/alipay.svg";
import wechatpayIcon from "../../assets/icon/wechatpay.svg";
import CheckoutButton from "./CheckoutButton";
import { pushAutoBaiduEvent } from "../../utils/baidu";
import { fetchGame } from "../../features/gameSlice";

const OrderConfirm = () => {
  const { id } = useParams();
  const [order, setOrder] = useState({ orderItems: [] });
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const [payMethod, setPayMethod] = useState("pingpong");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isIframe = self !== top; /* eslint-disable-line no-restricted-globals */
  const showHostedPay =
    (process.env.REACT_APP_API_ENV === "prod" ||
      process.env.REACT_APP_API_ENV === "dev") &&
    user.id !== "6581137cf607d172b3c41777" &&
    user.id !== "655b1468d47aa0732e6cb44c";
  const games = useSelector((state) => state.game.games);

  const options = [
    { label: <img src={wechatpayIcon}></img>, value: "pingpong" },
    { label: <img src={alipayIcon}></img>, value: "alipay" },
  ];

  const onChange = ({ target: { value } }) => {
    setPayMethod(value);
  };

  useEffect(() => {
    async function fetchData() {
      if (!id) {
        window.location.href = "/";
      }
      const orderData = await getOrderById(id);
      if (
        !orderData ||
        !orderData.orderItems ||
        orderData.orderItems.length === 0
      ) {
        window.location.href = "/";
      }
      orderData.orderItems.forEach((item) => {
        if (item && item.itemSlug && !games[item.itemSlug]) {
          dispatch(fetchGame(item.itemSlug));
        }
      });
      setOrder(orderData);
    }
    fetchData();
  }, [id]);

  const renderOrderItems = () => {
    return order?.orderItems?.map((item, index) => {
      const gameItem = GameMap[item.itemSlug];
      const game = games[item.itemSlug]?.data;
      if (!game && !gameItem) {
        return null;
      }
      const name = extractGameName(gameItem ?? game, i18n.language);
      return (
        <div keyclassName={styles.inner}>
          <div className={styles.rectangleParent}>
            <div className={styles.frameChild} />
            <div className={styles.frameWrapper}>
              <div className={styles.rectangleGroup}>
                <img
                  className={styles.frameItem}
                  src={
                    gameItem
                      ? gameItem.coverIcon
                      : game.latestVersion?.images?.landscapeIcon
                  }
                  alt="cover-icon"
                />
                <div className={styles.parent}>
                  <div
                    className={styles.div3}
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/game/${gameItem.slug}`)}
                  >
                    {name}
                  </div>
                  <div className={styles.div5}>
                    {t("publish_date")}：
                    {gameItem ? gameItem.releaseDate : game.firstReleaseDate}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.group}>
              <div className={styles.div6}>¥{item.originPrice}</div>
              <div className={styles.div7}>¥{item.price}</div>
            </div>
          </div>
        </div>
      );
    });
  };

  let breadcrumbName = t("wishlist");
  if (order?.orderItems?.length === 1) {
    const item1Slug = order?.orderItems[0].itemSlug;
    const gameData = GameMap[item1Slug];
    const gameItem = games[item1Slug]?.data || {};
    breadcrumbName = extractGameName(gameData ?? gameItem, i18n.language);
  }
  const breadcrumbLink =
    order?.orderItems?.length === 1
      ? `/game/${order?.orderItems[0].itemSlug}`
      : "/wishlist";
  return (
    <div className={styles.web}>
      <div className={styles.breadCrumb}>
        <div
          style={{ cursor: "pointer", marginRight: "8px" }}
          onClick={() => {
            pushAutoBaiduEvent(isIframe, {
              tagA: "order_cofrim_back",
              tagB: breadcrumbLink,
            });
            window.location.href = breadcrumbLink;
          }}
        >
          {breadcrumbName}
        </div>
        {` > ${t("purchase")} `}
      </div>
      <div className={styles.div1}>
        <div className={styles.div2}>
          <div className={styles.div3}>
            {t("choose1")}
            {order.orderItems?.length || 0}
            {t("choose2")}
          </div>
          {renderOrderItems()}
        </div>
        {!showHostedPay && (
          <div className={styles.container}>
            <div className={styles.div8}>{t("pay_choose")}</div>
            <div className={styles.radioButtonParent}>
              <Radio.Group
                options={options}
                onChange={onChange}
                value={payMethod}
              />
            </div>
          </div>
        )}
        <div className={styles.div13}>
          <div className={styles.frameParent}>
            <div className={styles.iconUserParent}>
              <UserOutlined className={styles.iconUser1} />
              <div className={styles.button}>
                {t("current_account")}：{user.mobile}
              </div>
            </div>
            <div className={styles.frameInner} />
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameDiv}>
              <div className={styles.button}>
                {order.orderItems?.length}
                {t("total_hint")}：
              </div>
              <div className={styles.div7}>¥{order.totalAmount}</div>
            </div>
            {showHostedPay ? (
              <CheckoutButton payMethod={payMethod} orderId={order.id} />
            ) : (
              <NonHostedCheckoutButton
                payMethod={payMethod}
                orderId={order.id}
              />
            )}
            <div className={styles.tip}>
              {t(showHostedPay ? "checkout_hint" : "pay_btn_hint")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirm;
