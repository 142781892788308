import styles from "./GamePage.module.css";
import cx from "classnames";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import MarkdownRender from "../MarkdownRender";
import { useTranslation } from "react-i18next";
import { pushAutoBaiduEvent } from "../../utils/baidu";
import { extractGameField } from "../../utils/i18n";

const Detail = ({ gameData, gameItem }) => {
  const [gameDetailMd, setGameDetailMd] = useState("");
  const [detailExpand, setDetailExpand] = useState(false);
  const { t, i18n } = useTranslation();
  const isIframe = self !== top; /* eslint-disable-line no-restricted-globals */

  useEffect(() => {
    async function fetchData(url) {
      const response = await fetch(url);
      const data = await response.text();
      setGameDetailMd(data);
    }
    if (gameData) {
      const markdownUrl = extractGameField(
        gameData,
        i18n.language,
        "detailMarkdownUrl",
      );
      fetchData(markdownUrl);
    }
    if (!gameData && gameItem) {
      setGameDetailMd(extractGameField(gameItem, i18n.language, "markdown"));
    }
  }, [gameData, gameItem, i18n.language]);

  const handleExpandDetail = () => {
    pushAutoBaiduEvent(isIframe, {
      tagA: "trigger_detail_expand",
      tagB: gameItem?.slug,
    });
    setDetailExpand(!detailExpand);
  };

  return (
    <div className={styles.div36}>
      <div className={cx(styles.div37, detailExpand && styles.expanded)}>
        <div className={styles.div2}>
          <div className={styles.rectangleParent}>
            <div className={styles.instanceChild} />
            <div className={styles.dlc2}>{t("game_detail")}</div>
          </div>
        </div>
        <div className={styles.div38}>
          {<MarkdownRender content={gameDetailMd} />}
        </div>
      </div>
      <div className={styles.div46} onClick={handleExpandDetail}>
        <div className={styles.item} />
        <div className={styles.linkdefault}>
          {detailExpand ? (
            <UpOutlined className={styles.iconDown} />
          ) : (
            <DownOutlined className={styles.iconDown} />
          )}
          <div className={styles.button}>
            {detailExpand ? t("fold_detail") : t("unfold_detail")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
