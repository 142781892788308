import styles from "./OrderConfirm.module.css";
import { useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getOrderById } from "../../service/orderService";
import { Button } from "antd";
import successIcon from "../../assets/icon/orderSuccess.svg";
import failIcon from "../../assets/icon/orderFail.svg";
import { handleOpenHub } from "../../utils/openHub";
import { useTranslation } from "react-i18next";
import { extractGameName } from "../../utils/i18n";
import { pushAutoBaiduEvent } from "../../utils/baidu";
import { trackClickEvent } from "../../service/trackingService";
import { fetchGameBySlug } from "../../service/gameService";

const OrderStatus = () => {
  const { id } = useParams();
  const [order, setOrder] = useState({ orderItems: [] });
  const [game, setGame] = useState({});
  const [isWishlistOrder, setIsWishlistOrder] = useState(false);
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();

  const isIframe = self !== top; /* eslint-disable-line no-restricted-globals */
  const handleOpenGame = () => {
    window.parent.postMessage("playgameid_" + game.id, "*");
  };

  let isSuccess = searchParams.get("status") === "finished";

  useEffect(() => {
    async function fetchData() {
      if (!id) {
        window.location.href = "/";
      }
      const orderData = await getOrderById(id, true);
      if (
        !orderData ||
        !orderData.orderItems ||
        orderData.orderItems.length === 0
      ) {
        window.location.href = "/";
      }
      setOrder(orderData);
      if (orderData.orderItems.length > 1) {
        setIsWishlistOrder(true);
        setGame({ id: orderData.orderItems[0].itemId });
      } else {
        const fetchedGame = await fetchGameBySlug(
          orderData.orderItems[0].itemSlug,
        );
        setGame(fetchedGame);
      }
    }
    fetchData();
  }, [id]);

  if (order.id !== null) {
    isSuccess = order.status === "finished";
  }

  const name = extractGameName(game, i18n.language);

  const renderDownload = () => {
    if (isIframe) {
      return (
        <Button
          type="primary"
          loading={!game.id}
          className={styles.buttonDownload}
          onClick={handleOpenGame}
        >
          {t("play_now")}
        </Button>
      );
    }
    return (
      <div className={styles.downloadWrapper}>
        <div className={styles.hint}>
          <div>{t("download_hint1")}</div>
          <div>{t("download_hint2")}</div>
        </div>
        <Button
          type="primary"
          className={styles.buttonDownload}
          onClick={() => {
            trackClickEvent(isIframe, "open_hub", "order");
            pushAutoBaiduEvent(isIframe, { tagA: "open_hub", tagB: "order" });
            handleOpenHub();
          }}
        >
          {t("download")}
        </Button>
      </div>
    );
  };

  return (
    <div className={styles.web}>
      <div className={styles.breadCrumb}>
        <div
          style={{ cursor: "pointer", marginRight: "8px" }}
          onClick={() =>
            (window.location.href = isWishlistOrder
              ? "/"
              : `/game/${game.slug}`)
          }
        >
          {isWishlistOrder ? t("home_page") : name}
        </div>
        {` > ${t("purchase")} `}
      </div>
      <div className={styles.div1}>
        <div className={styles.orderStatusInfo}>
          {isSuccess ? (
            <div className={styles.div3}>
              {t("purchase_success1")}
              {order.orderItems?.length || 0}
              {t("purchase_success2")}
            </div>
          ) : (
            <div className={styles.div3}>{t("purchase_fail")}</div>
          )}
          <div className={styles.statusIcon}>
            {isSuccess ? (
              <img src={successIcon} alt="success-icon" />
            ) : (
              <img src={failIcon} alt="fail-icon" />
            )}
          </div>
          {isSuccess && renderDownload()}
          {!isSuccess && <div style={{ fontSize: "14px" }}>{order.id}</div>}
        </div>
      </div>
    </div>
  );
};

export default OrderStatus;
