import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

function AffiliateRouteMiddleware({ children }) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let fid = queryParams.get("fid");
    if (fid) {
      sessionStorage.setItem("fid", fid);
    } else if (!fid && !sessionStorage.getItem("fid")) {
      if (
        document.referrer &&
        !document.referrer.includes("u4ugames") &&
        !document.referrer.includes("pingpongx") &&
        !document.referrer.includes("tauri")
      ) {
        fid = document.referrer;
      }
      if (fid) {
        sessionStorage.setItem("fid", fid);
      }
    }
  }, [location, navigate]);

  return children;
}

export default AffiliateRouteMiddleware;
