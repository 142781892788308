import apiClient from "./apiClient";

export async function promotionList() {
  let url = `${process.env.REACT_APP_API_URL}/api/promotion/list`;
  const response = await apiClient.get(url);
  return response.data;
}

export async function deletePromotion(id) {
  await apiClient.delete(
    `${process.env.REACT_APP_API_URL}/api/promotion/${id}`,
  );
}

export async function createPromotion(promotion) {
  const response = await apiClient.post(
    `${process.env.REACT_APP_API_URL}/api/promotion`,
    promotion,
  );
  return response.data;
}

export async function putPromotion(promotionId, data) {
  const response = await apiClient.put(
    `${process.env.REACT_APP_API_URL}/api/promotion/${promotionId}`,
    data,
  );
  return response.data;
}
