export const NickNameMap = [
  "StarryKnight",
  "MysticMeadow",
  "PixelPioneer",
  "VelvetLuna",
  "CosmicCanvas",
  "EchoNebula",
  "LunarLagoon",
  "SolarFlareSurfer",
  "TwilightTrekker",
  "AuroraWanderer",
  "DigitalDrifter",
  "NeonNomad",
  "CelestialSailor",
  "AstroAthena",
  "ShadowSphinx",
  "GalacticGuru",
  "ZenithVoyager",
  "HorizonHopper",
  "InfiniteIris",
  "SkyboundSprite",
  "StardustSorcerer",
  "CyberCentaur",
  "PhantomPhoenix",
  "NovaNavigator",
  "EtherElemental",
  "CrystalCoyote",
  "TerraTracker",
  "QuantumQuill",
  "EmberEnigma",
  "OceanOracle",
  "SolarSprite",
  "ZephyrZealot",
  "BlazeBard",
  "WhisperingWillow",
  "MarbleMystic",
  "ArcticArchitect",
  "BinaryBanshee",
  "CobaltCrafter",
  "DawnDreamer",
  "ElectricEagle",
  "FrostFable",
  "GravityGypsy",
  "JadeJester",
  "KryptonKnight",
  "LotusLurker",
  "MoonlitMonk",
  "NexusNymph",
  "OpalOutlaw",
  "PulsarPirate",
  "QuartzQuester",
  "RadiantRover",
  "SapphireSage",
  "ThalassicThief",
  "UltravioletUrchin",
  "VortexVagabond",
  "WindWalker",
  "XenonXylophonist",
  "YonderYogi",
  "ZestfulZodiac",
  "EmberEmissary",
  "GildedGlitch",
  "HolographicHalo",
  "IridescentIllusion",
  "JungleJaguar",
  "KineticKoi",
  "LuminousLynx",
  "MagneticMoth",
  "NebulaNomad",
  "ObsidianOwl",
  "PrismaticPanther",
  "QuantumQuokka",
  "RadiantRaven",
  "SnowySphinx",
  "ThunderTiger",
  "UrbanUnicorn",
  "VerdantViper",
  "WhisperWolf",
  "ExoticEcho",
  "FieryFalcon",
  "GlacialGazelle",
  "HummingHawk",
  "IvoryImpulse",
  "JovialJellyfish",
  "KaleidoscopicKestrel",
  "LustrousLemur",
  "MysticMongoose",
  "NeonNightingale",
  "OpulentOctopus",
  "PolychromePeacock",
  "QuartzQuetzal",
  "ResplendentRaccoon",
  "StellarSwan",
  "TurbulentToucan",
  "VividVulture",
  "StarryDreamer",
  "CosmicVoyager",
  "TwilightTraveler",
  "MysticMeadow",
  "EternalEcho",
  "GalacticGardener",
  "LunarLabyrinth",
  "SolarSorcerer",
  "AuroraAdventurer",
  "NebulaNavigator",
  "PixelPilgrim",
  "NeonNinja",
  "CelestialSailor",
  "AstroArtist",
  "ShadowSage",
  "InfiniteIllusionist",
  "ZenithExplorer",
  "HorizonHiker",
  "IrisInventor",
  "SkywardSpirit",
  "StardustShaman",
  "CyberCentaur",
  "PhantomPhoenix",
  "NovaNomad",
  "EtherElement",
  "CrystalCoyote",
  "TerraTrekker",
  "QuantumQuester",
  "EmberEnchanter",
  "OceanOracle",
  "SolarSprite",
  "ZephyrZealot",
  "BlazeBard",
  "WhisperingWillow",
  "MarbleMystic",
  "ArcticArchitect",
  "BinaryBanshee",
  "CobaltCrafter",
  "DawnDancer",
  "ElectricEagle",
  "FrostFable",
  "GravityGuru",
  "JadeJuggler",
  "KryptonKnight",
  "LotusLurker",
  "MoonlitMonk",
  "NexusNymph",
  "OpalOutlaw",
  "PulsarPirate",
  "QuartzQuill",
  "RadiantRover",
  "SapphireSeer",
  "ThalassicThief",
  "UltravioletUrchin",
  "VortexVoyager",
  "WindWalker",
  "XenonXplorer",
  "YonderYogi",
  "ZestfulZephyr",
  "EmberEmissary",
  "GildedGargoyle",
  "HolographicHero",
  "IridescentImp",
  "JungleJoker",
  "KineticKraken",
  "LuminousLion",
  "MagneticMermaid",
  "NebulaNymph",
  "ObsidianOracle",
  "PrismaticPanther",
  "QuantumQueen",
  "RadiantRanger",
  "SnowySerpent",
  "ThunderTitan",
  "UrbanUnicorn",
  "VerdantViking",
  "WhisperWolf",
  "ExoticExplorer",
  "FieryFalcon",
  "GlacialGiant",
  "HummingHarpy",
  "IvoryIllusionist",
  "JovialJester",
  "KaleidoscopicKing",
  "LustrousLioness",
  "MysticMongoose",
  "NeonNarwhal",
  "OpulentOwl",
  "PolychromePegasus",
  "QuartzQueen",
  "ResplendentRabbit",
  "StellarSwan",
  "TurbulentTroll",
  "VividValkyrie",
  "WildWarrior",
  "XenialXenomorph",
  "YouthfulYeti",
  "ZenZodiac",
  "AmberAbyss",
  "BreezyBasilisk",
  "CrimsonCrown",
  "DancingDragon",
  "EchoingEagle",
  "FlamingFox",
  "GlowingGoblin",
  "HarmonicHydra",
  "IlluminatedImpala",
  "JubilantJaguar",
  "幽梦影",
  "墨雨轩",
  "静水深流",
  "烟花易冷",
  "浮光掠影",
  "风之行者",
  "云端漫步",
  "星海游侠",
  "月下独行",
  "寂静岭",
  "苍穹之翼",
  "碧落黄泉",
  "幻梦初醒",
  "墨染青衫",
  "飘渺之旅",
  "梦回吹雪",
  "寒光照铁衣",
  "剑舞红尘",
  "墨舞碧海",
  "千山暮雪",
  "琴心剑魄",
  "素衣飘风",
  "碎星河",
  "苍穹变",
  "风华绝代",
  "流水无情",
  "碧落苍穹",
  "墨雨丹心",
  "青石古道",
  "古韵今风",
  "月影星痕",
  "寂寞风铃",
  "碧海潮生",
  "雨后春笋",
  "风雪夜归人",
  "风月无边",
  "孤云野鹤",
  "独步天涯",
  "草木皆兵",
  "落花有意",
  "流沙世界",
  "青衫故人",
  "望月空思",
  "雪中行者",
  "剑胆琴心",
  "云海游龙",
  "古道西风",
  "一剑飘红",
  "笑傲江湖",
  "风中追风",
  "紫竹林中",
  "梦里花落",
  "断桥残雪",
  "半生烟雨",
  "青石古街",
  "流年似水",
  "雨落江南",
  "听风忆雪",
  "若水三千",
  "兰亭序",
  "雪落无痕",
  "墨海心田",
  "风起云涌",
  "独钓寒江雪",
  "柳暗花明",
  "半江瑟瑟",
  "一叶扁舟",
  "墨色浮生",
  "青石老街",
  "云深不知处",
  "青山遮不住",
  "月下思故人",
  "听风观雨",
  "半壶纱",
  "静水流深",
  "夜雨声烦",
  "听风吟",
  "问月轩",
  "梦回故里",
  "半夏微凉",
  "墨花月白",
  "浅夏初澈",
  "潇湘夜雨",
  "清风徐来",
  "雪中悍刀行",
  "星辰大海",
  "云深不知处",
  "岁月神偷",
  "墨染青衣",
  "醉卧美人膝",
  "浮生若梦",
  "半窗疏影",
  "青石古道",
  "落花流水",
  "风月无边",
  "听风是雨",
  "青衫烟雨",
  "忆江南",
  "浮光掠影",
  "岁月静好",
  "独步天下",
  "半壶纱",
  "桃之夭夭",
  "青丝暮雪",
  "千山暮雪",
  "落日熔金",
  "陌上花开",
  "繁花似锦",
  "寒烟翠微",
  "青瓷时光",
  "流年似水",
  "寒梅傲雪",
  "暖风熏得游人醉",
  "樱花落尽春意闲",
  "风卷残云",
  "岁月如歌",
  "独钓寒江雪",
  "诗酒趁年华",
  "月下独酌",
  "一剑飘香",
  "浅墨青衣",
  "琴瑟在御",
  "温茶软语",
  "梦里花落",
  "南风未起",
  "清酒斩寒",
  "桃花影落",
  "流光容易把人抛",
  "折柳轻歌",
  "清风明月",
  "离人暮雨",
  "夜未央",
  "北风行",
  "白衣苍狗",
  "一叶扁舟",
  "凌云志",
  "半壁江山",
  "寒山远黛",
  "桂林一枝",
  "青梅煮酒",
  "半江瑟瑟半江红",
  "墨舞碧歌",
  "朱颜醉",
  "青春已逝",
  "浮华褪尽",
  "梨花带雨",
  "梦落星河",
  "惜春长怕花开晚",
  "踏歌行",
  "剑胆琴心",
  "长歌行",
  "剑起风云",
  "江山如画",
  "一醉江湖",
  "落笔成诗",
  "千秋岁",
  "逐梦之人",
  "红尘客",
  "白衣少侠",
  "墨香未凉",
  "听风念旧",
  "琴心剑胆",
  "词穷画尽",
  "寒光照铁衣",
  "风中追风",
  "寂寞如烟",
  "静待花开",
  "醉卧沙场君莫笑",
  "梦之旅者",
  "星河漫步",
  "静水深流",
  "古风游侠",
  "墨舞云端",
  "幽兰雅韵",
  "风雨同舟",
  "琴心剑魄",
  "墨隐江湖",
  "月下独行",
  "诗酒年华",
  "流星追梦",
  "雪夜枫林",
  "云起龙骧",
  "风花雪月",
  "梦回大唐",
  "剑指江湖",
  "浮生若梦",
  "碧海潮生",
  "天涯行者",
  "风中追风",
  "柳暗花明",
  "书剑飘零",
  "琴瑟和鸣",
  "风流云散",
  "烟花易冷",
  "云淡风轻",
  "月落乌啼",
  "独步天下",
  "烟雨江南",
  "樱花落尽",
  "白衣苍狗",
  "半壶纱窗",
  "醉卧沙场",
  "浊酒一杯",
  "清风徐来",
  "倚剑听风",
  "无心快语",
  "岁月静好",
  "晚风拂柳",
  "剑胆琴心",
  "星辰大海",
  "浮光掠影",
  "清风明月",
  "孤帆远影",
  "落雨听禅",
  "青石古道",
  "云中歌者",
  "潇湘夜雨",
  "青衫磊落",
  "落花流水",
  "枕江山梦",
  "苍松迎客",
  "独钓寒江",
  "千山暮雪",
  "听风识雨",
  "夜雨寄北",
  "清风卷地",
  "半生浮尘",
  "雨落无声",
  "寒光照铁",
  "长歌当哭",
  "梦中的麒麟",
  "赤焰战场",
  "碎星如雨",
  "风卷残云",
  "天边飞鹰",
  "寂寞山丘",
  "问剑天涯",
  "流云诗意",
  "鹿鸣幽谷",
  "雪落长松",
  "风起云涌",
  "青衣沽酒",
  "断剑重铸",
  "黄昏独步",
  "晓看天色",
  "夜听风雨",
  "旧梦如风",
  "碧海青天",
  "凌波微步",
  "紫竹林中",
  "晨曦微露",
  "古道西风",
  "忆江南词",
  "碧落黄泉",
  "铁马冰河",
  "赤血长殷",
  "墨竹风声",
  "听海潮生",
  "青灯古佛",
  "雨打芭蕉",
  "梦回吹角",
  "醉眼看花",
  "青山依旧",
  "忘川无岸",
  "天涯孤客",
  "幻影剑客",
  "寒夜孤狼",
  "紫电青霜",
  "流云飞扬",
  "朔风独行",
  "碎星寒月",
  "破晓之光",
  "夜雨寄思",
  "极夜寒冰",
  "狂澜海骑",
  "剑舞红尘",
  "风行影者",
  "雷霆战狼",
  "无双剑魔",
  "幽冥鬼影",
  "飞翔火凤",
  "醉梦天涯",
  "浪客剑心",
  "霜火之瞳",
  "幻境迷踪",
  "天际流星",
  "冥海龙王",
  "狂风绝息",
  "苍穹之翼",
  "炽焰狂潮",
  "御剑乘风",
  "魔界战神",
  "极光神射",
  "影月幽魂",
  "天启骑士",
  "星陨暗影",
  "寂灭天使",
  "龙翔四海",
  "白骨血影",
  "噬魂死神",
  "凤舞九天",
  "雷动乾坤",
  "黑暗游侠",
  "夜刃之影",
  "风暴烈焰",
  "灵魂歌者",
  "寒冰巫师",
  "飞羽天箭",
  "炎龙战士",
  "疾风剑豪",
  "灭世魔尊",
  "血雨剑神",
  "霸天战狼",
  "暗夜精灵",
  "冰凌雪女",
  "龙傲天下",
  "幽谷仙狐",
  "斗破苍穹",
  "九幽死神",
  "魅影刺客",
  "苍穹雷霆",
  "风之旅人",
  "战天斗地",
  "神秘游侠",
  "幻影狂风",
  "绝世唐门",
  "星辰变异",
  "幽冥之眼",
  "天罡星君",
  "无极剑圣",
  "青龙偃月",
  "暗夜猎手",
  "赤血龙魂",
  "玄冰魔导",
  "血族君王",
  "龙渊侍卫",
  "风暴使者",
  "天剑仙尊",
  "噬天魔狼",
  "幻翼飞天",
  "冰霜战神",
  "猎魔战士",
  "霜月寒刃",
  "破影游龙",
  "夜枭暗影",
  "翼风旅者",
  "雷霆之怒",
  "暗影鬼刺",
  "冥王神射",
  "白虎傲世",
  "血刃魔心",
  "火焰之子",
  "夜之魅影",
  "幻灵术士",
  "黑炎龙王",
  "寒冰凤凰",
  "雷霆剑尊",
  "风云变幻",
  "幽灵狙击",
  "炼狱魔龙",
  "暗黑旋风",
  "剑痕斩魂",
  "炎帝焚天",
  "龙翼战神",
  "毁灭之锤",
  "暗夜之猎",
  "末日审判",
  "神影幻杀",
  "甜蜜小厨",
  "花园守望",
  "梦幻甜点",
  "蓝莓小屋",
  "快乐种植者",
  "时光旅人",
  "繁星牧场",
  "彩虹小猫",
  "暖阳书屋",
  "甜梦糖果屋",
  "浅夏花园",
  "森林探险家",
  "奇幻农夫",
  "糖果魔法师",
  "小鹿乐园",
  "樱桃小镇",
  "雪落茶坊",
  "绿野仙踪",
  "梦境花农",
  "晴空小筑",
  "冬雪温泉",
  "星光农场",
  "宝石猎人",
  "蔚蓝海岸",
  "阳光果园",
  "薰衣草梦",
  "玫瑰小镇",
  "幻彩鱼池",
  "白云牧场",
  "彩蝶花园",
  "蘑菇小屋",
  "雨后春笋",
  "云端烘焙师",
  "星辰海洋",
  "阳光小农",
  "梦幻小熊",
  "森林小精灵",
  "水晶湖畔",
  "蜂蜜工坊",
  "蔬菜大亨",
  "雏菊田园",
  "小桥流水",
  "草莓果园",
  "薯条小镇",
  "蝴蝶谷",
  "冰淇淋制造者",
  "彩虹制造机",
  "熊猫村长",
  "樱花师",
  "仙境导游",
  "水果农场主",
  "梦境画家",
  "星空观察者",
  "热气球驾驶",
  "幸福小烘培",
  "海豚岛屿",
  "熹光植物园",
  "玉米迷宫",
  "糖霜工匠",
  "悠闲鱼夫",
  "田园诗人",
  "绿意园丁",
  "晴空摄影师",
  "秘密花匠",
  "彩虹桥守护者",
  "棉花糖云",
  "蓝天飞翔家",
  "蘑菇森林",
  "巧克力城堡",
  "宠物小医生",
  "蜜蜂小队长",
  "花朵设计师",
  "魔法药师",
  "幸福面包师",
  "甜蜜果酱厨",
  "糖果雨制造者",
  "梦幻咖啡师",
  "星光糕点师",
  "宝藏猎人",
  "蔬菜魔法师",
  "水上乐园管理员",
  "星空花艺师",
  "幻想园艺家",
  "童话镇居民",
  "独角兽训练师",
  "甜甜圈烘焙师",
  "农场乐园主",
  "糖果屋主人",
  "水果篮编织者",
  "夏日冰饮专家",
  "彩虹冰沙师",
  "奇幻蛋糕工",
  "沙滩派对达人",
  "草坪音乐家",
  "阳光果酱专家",
  "夜光花园师",
  "彩绘陶艺家",
  "星际探险者",
  "海滨垂钓高手",
  "夕阳下的骑士",
  "梦之队长",
  "樱花雪制造者",
  "夢境遊俠",
  "星辰旅者",
  "幻影劍客",
  "寒夜孤行",
  "紫電青霜",
  "飄渺雲海",
  "浪跡天涯",
  "飛羽箭神",
  "霜火狂刃",
  "月影幽靈",
  "破曉之光",
  "天際巡者",
  "星塵舞者",
  "夜風傳說",
  "雲端之歌",
  "霧隱刺客",
  "天涯浪客",
  "雨落蒼穹",
  "夢回故里",
  "寂靜山林",
  "流雲逐月",
  "碎星追夢",
  "烈日行者",
  "白雪劍影",
  "追風箭手",
  "翠綠山谷",
  "狂濤劍士",
  "凌霄霸主",
  "碧海潮生",
  "燃燒戰魂",
  "星海漫遊",
  "飛翼之翼",
  "寒冰巫師",
  "炎龍戰士",
  "靈魂詠唱",
  "龍吟劍影",
  "夢幻旋律",
  "幽冥夜行",
  "冰凌騎士",
  "炎舞狂沙",
  "狂風之息",
  "流沙時光",
  "破曉星辰",
  "幻夜魅影",
  "疾風迅雷",
  "霜刃冷月",
  "幻想飛行",
  "絕影忍者",
  "天空行者",
  "夢境徘徊",
  "晴空微笑",
  "星光閃耀",
  "雨後彩虹",
  "寒霜術士",
  "彩虹獵人",
  "暴風之力",
  "星際旅行",
  "風暴掌控",
  "雷霆戰狼",
  "幻影舞者",
  "夢幻之眼",
  "銀月神射",
  "冰霜巨龍",
  "風之御者",
  "星塵旋風",
  "夜影俠客",
  "翼風征途",
  "雷動山河",
  "冰火交融",
  "天翔龍騎",
  "幽蘭雅集",
  "絕地求生",
  "雲海遨遊",
  "暗影遊俠",
  "寂靜咆哮",
  "靈狐傳說",
  "夜空之主",
  "星之守護",
  "海洋之心",
  "翡翠夢境",
  "烈火戰魂",
  "雪花舞者",
  "雲端遊俠",
  "流星雨下",
  "風雲變色",
  "暗夜流星",
  "夢幻泡影",
  "白虎嘯天",
  "絕影刀客",
  "霧海漫遊",
  "翼龍天際",
  "火山爆發",
  "天際飛翔",
  "冰川時代",
  "雷霆使者",
];
