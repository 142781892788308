import { handleOpenHub } from "../../utils/openHub";
import styles from "./GamePage.module.css";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { pushAutoBaiduEvent } from "../../utils/baidu";
import { trackClickEvent } from "../../service/trackingService";

const GameButton = ({ gameId, type }) => {
  const isIframe = self !== top; /* eslint-disable-line no-restricted-globals */
  const { t } = useTranslation();
  const handleOpenGame = () => {
    window.parent.postMessage("playgameid_" + gameId, "*");
  };

  return (
    <div>
      {!isIframe ? (
        <Button
          className={
            type === "dlc" ? styles.buttonsecondarylarge : styles.gameButton
          }
          onClick={() => {
            trackClickEvent(isIframe, "open_hub", "detail");
            pushAutoBaiduEvent(isIframe, { tagA: "open_hub", tagB: "detail" });
            handleOpenHub();
          }}
        >
          {t("download_now")}
        </Button>
      ) : (
        <Button
          className={
            type === "dlc" ? styles.buttonsecondarylarge : styles.gameButton
          }
          onClick={() => {
            trackClickEvent(isIframe, "open_game_web", gameId);
            pushAutoBaiduEvent(isIframe, {
              tagA: "open_game_web",
              tagB: gameId,
            });
            handleOpenGame();
          }}
        >
          {t("play_now")}
        </Button>
      )}
    </div>
  );
};

export default GameButton;
