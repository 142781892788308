import styles from "./GamePage.module.css";
import { Button, message, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { postMultipeItemsOrder, postOrder } from "../../service/orderService";
import { navigate } from "../../features/userSlice";
import { useParams } from "react-router-dom";
import { hasEntitlement } from "../../service/entitlementService";
import GameButton from "./GameButton";
import React from "react";
import { extractGameName } from "../../utils/i18n";
import { pushAutoBaiduEvent } from "../../utils/baidu";
import { trackClickEventAsync } from "../../service/trackingService";

const { confirm } = Modal;

const Dlc = ({ gameItem }) => {
  const isIframe = self !== top; /* eslint-disable-line no-restricted-globals */
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const entitlements = useSelector((state) => state.entitlements);
  const { t, i18n } = useTranslation();
  const handleLink = (link) => {
    window.location.href = link;
  };
  const { slug } = useParams();

  if (!gameItem) {
    return null;
  }

  const isDlc = gameItem.type === "dlc";

  const purchasableDlcs = isDlc
    ? []
    : gameItem.dlcGames
        .filter((i) => !hasEntitlement(entitlements, i.slug))
        .map((t) => t.slug);

  const handleMultiplePurchase = async () => {
    if (user.id) {
      if (showConfirmDialog()) {
        return;
      }
      try {
        const order = await postMultipeItemsOrder(purchasableDlcs);
        dispatch(
          navigate({
            url: `/order-confirm/${order.id}`,
          }),
        );
      } catch (e) {
        message.error(t("purchase_duplicate"));
      }
    } else {
      dispatch(
        navigate({
          slug,
        }),
      );
    }
  };

  const showConfirmDialog = (dlcItem) => {
    if (!isDlc && !hasEntitlement(entitlements, gameItem?.slug)) {
      let title =
        `${t("dlc_hint1")}《` +
        extractGameName(gameItem, i18n.language) +
        `》${t("dlc_hint2")}`;
      if (dlcItem) {
        title += "《" + extractGameName(dlcItem, i18n.language) + "》";
      }
      confirm({
        title,
        okText: t("purchase_now"),
        cancelText: t("cancel"),
        okType: "primary",
        okButtonProps: {
          style: { backgroundColor: "#FE9923" },
        },
        async onOk() {
          await trackClickEventAsync(isIframe, "purchase", gameItem?.slug);
          pushAutoBaiduEvent(isIframe, {
            tagA: "purchase",
            tagB: gameItem?.slug,
          });
          const order = await postOrder(gameItem?.slug);
          dispatch(
            navigate({
              url: `/order-confirm/${order.id}`,
            }),
          );
        },
      });
      return true;
    }
    return false;
  };

  const handlePurchase = async (dlcItem) => {
    if (user.id) {
      if (showConfirmDialog(dlcItem)) {
        return;
      }
      await trackClickEventAsync(isIframe, "purchase", dlcItem.slug);
      pushAutoBaiduEvent(isIframe, { tagA: "purchase", tagB: dlcItem.slug });
      const order = await postOrder(dlcItem.slug);
      dispatch(
        navigate({
          url: `/order-confirm/${order.id}`,
        }),
      );
    } else {
      dispatch(
        navigate({
          slug,
        }),
      );
    }
  };

  const renderDlcItem = (item) => {
    const discount = Math.round(
      ((item.originPrice - item.price) / item.originPrice) * 100,
    );
    const isPurchase = hasEntitlement(entitlements, item.slug);
    return (
      <div className={styles.div28}>
        <img
          alt={"dlc"}
          className={styles.child}
          onClick={() => handleLink(`/game/${item.slug}`)}
          src={item?.latestVersion?.images?.landscapeIcon}
        />
        <div className={styles.div29}>
          <div className={styles.div30}>
            <div
              className={styles.button}
              style={{ cursor: "pointer" }}
              onClick={() => handleLink(`/game/${item.slug}`)}
            >
              {extractGameName(item, i18n.language)}
            </div>
            <div className={styles.div32}>
              <div className={styles.div33}>{`¥${item.price}`}</div>
              <div className={styles.div34}>{`¥${item.originPrice}`}</div>
              {discount > 4 && (
                <div className={styles.wrapper}>
                  <div className={styles.div35}>-{discount}%</div>
                </div>
              )}
            </div>
          </div>
          {isPurchase && item.id ? (
            <GameButton gameId={item.id} type="dlc" />
          ) : (
            <Button
              className={styles.buttonsecondarylarge}
              onClick={() => handlePurchase(item)}
            >
              {t("purchase_now")}
            </Button>
          )}
        </div>
      </div>
    );
  };

  const renderDlcItems = (recommend) => {
    return recommend.map((item, index) => {
      return renderDlcItem(item);
    });
  };
  return (
    <div className={styles.div27}>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
        className={styles.rectangleParent}
      >
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          <div className={styles.instanceChild} />
          <div className={styles.dlc2}>
            {isDlc ? t("standalone_title") : t("dlc_title")}
          </div>
        </div>
        {!isDlc && purchasableDlcs.length > 0 && (
          <div>
            <Button
              style={{ color: "#fe9923" }}
              type="text"
              onClick={() => handleMultiplePurchase()}
            >
              {t("purchase_all")}
            </Button>
          </div>
        )}
      </div>
      {isDlc && renderDlcItem(gameItem.standaloneGame)}
      {!isDlc && renderDlcItems(gameItem.dlcGames)}
    </div>
  );
};

export default Dlc;
