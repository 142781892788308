import styles from "./GamePage.module.css";
import { useTranslation } from "react-i18next";

const Support = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.div22}>
      <div className={styles.div23}>
        <div className={styles.inner}>
          <div className={styles.rectangleParent}>
            <div className={styles.instanceChild} />
            <div className={styles.dlc2}>{t("support")}</div>
          </div>
        </div>
      </div>
      <div className={styles.div24}>
        <div className={styles.dfkdffkaljj}>
          <ul className={styles.dfkdffkaljj1}>
            <li className={styles.dfkdffkaljj2}>
              {t("support_email")}：support@u4ugames.com
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Support;
