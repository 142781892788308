import styles from "./GamePage.module.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { pushAutoBaiduEvent } from "../../utils/baidu";
import { EyeOutlined } from "@ant-design/icons";
import cls from "classnames";
import { gameList } from "../../service/gameService";
import { shuffleArray } from "../../utils/common";
import { extractGameName } from "../../utils/i18n";

const SideRecommand = ({ slug }) => {
  const isIframe = self !== top; /* eslint-disable-line no-restricted-globals */
  const { t, i18n } = useTranslation();
  const [hoveredItem, setHoveredItem] = useState(null);
  const [recommends, setRecommends] = useState([]);

  const handleLink = (link) => {
    pushAutoBaiduEvent(isIframe, { tagA: "recommand", tagB: link });
    window.location.href = link;
  };
  const games = useSelector((state) => state.game.games);

  useEffect(() => {
    const fetchGames = async () => {
      let searchName = "";
      let searchSlug = "";
      const response = gameList(false, searchName, searchSlug);
      const data = await response;
      const recommends = shuffleArray(data.results)
        .filter(
          (item) =>
            item.latestVersion?.images &&
            item.latestVersion?.images["sideRecommendIcon"] &&
            item.slug !== slug,
        )
        .slice(0, 5);
      setRecommends(recommends);
    };
    fetchGames();
  }, []);

  const renderRecommandItems = () => {
    return recommends.map((item, index) => {
      const discount =
        item &&
        Math.round(((item.originPrice - item.price) / item.originPrice) * 100);

      return (
        <a
          href={`/game/${item.slug}`}
          className={styles.div28}
          onMouseEnter={() => setHoveredItem(item.slug)}
          onMouseLeave={() => setHoveredItem(null)}
          style={{
            height: "auto",
            width: "100%",
            flexDirection: "column",
            padding: "unset",
            cursor: "pointer",
            background: "#F7F7F7",
            borderRadius: "16px",
            marginBottom: "12px",
            textDecoration: "none",
            color: "var(--color-darkslategray)",
          }}
        >
          <div className={styles.childWrapper}>
            <img
              style={{
                borderRadius: "16px 16px 0 0",
                width: "296px",
              }}
              className={styles.child}
              alt="recommand-img"
              src={
                item.latestVersion?.images &&
                item.latestVersion?.images["sideRecommendIcon"]
              }
            />
            <div
              className={cls(styles.childTransparent, {
                [styles.displayChildTransparent]: hoveredItem === item.slug,
              })}
            >
              <EyeOutlined />
              <div className={styles.childHint}>{t("show_detail")}</div>
            </div>
          </div>
          <div>
            <div
              style={{
                margin: "14px 14px",
                width: "100%",
              }}
              className={styles.div30}
            >
              <div
                className={styles.recommandButton}
                title={extractGameName(item, i18n.language)}
              >
                {extractGameName(item, i18n.language)}
              </div>
              <div className={styles.div32}>
                <div className={styles.div33}>{`¥${item.price}`}</div>
                <div className={styles.div34}>{`¥${item.originPrice}`}</div>
                {discount > 4 && (
                  <div className={styles.wrapper}>
                    <div className={styles.div35}>-{discount}%</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </a>
      );
    });
  };

  return (
    <div className={styles.div22} style={{ width: "328px" }}>
      <div className={styles.div23}>
        <div
          style={{
            justifyContent: "space-between",
            width: "296px",
          }}
          className={styles.inner}
        >
          <div className={styles.rectangleParent}>
            <div className={styles.instanceChild} />
            <div className={styles.dlc2}>{t("more_recommand")}</div>
          </div>
          <div
            onClick={() => handleLink("/")}
            style={{
              cursor: "pointer",
              color: "#FE9923",
              fontSize: "14px",
            }}
          >
            {t("sid_recommand")}
          </div>
        </div>
      </div>
      <div className={styles.sideRecommandContainer}>
        {renderRecommandItems()}
      </div>
    </div>
  );
};

export default SideRecommand;
