import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/userSlice";
import entitlementReducer from "../features/entitilementSlice";
import gameReducer from "../features/gameSlice";
import wishlistReducer from "../features/wishlistSlice";
import commentReducer from "../features/commentSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    entitlements: entitlementReducer,
    game: gameReducer,
    wishlist: wishlistReducer,
    comment: commentReducer,
  },
});
