import React, { useEffect, useState } from "react";
import { gameList } from "../../service/gameService";
import GameCard from "./GameCard";
import styles from "./HomePage.module.css";
import { useTranslation } from "react-i18next";
import { extractGameName } from "../../utils/i18n";
import { trackViewEvent } from "../../service/trackingService";
import { useLocation } from "react-router-dom";

const HomePage = () => {
  const isIframe = self !== top; /* eslint-disable-line no-restricted-globals */
  const [games, setGames] = useState([]);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fid = queryParams.get("fid");

  useEffect(() => {
    const fetchGames = async () => {
      let searchName = "";
      let searchSlug = "";
      const response = gameList(false, searchName, searchSlug);
      const data = await response;
      setGames(data.results);
    };

    trackViewEvent(isIframe, "home", "home", fid);

    fetchGames();
  }, []);

  return (
    <div className={styles.web}>
      <h1
        style={{
          width: "100%",
          fontSize: "28px",
        }}
      >
        {t("home_page")}
      </h1>
      <div
        style={{
          display: "flex",
          gap: "48px",
          flexWrap: "wrap",
        }}
      >
        {games.map((game) => {
          const name = extractGameName(game, i18n.language);
          return (
            <GameCard
              key={game.id}
              imageUri={game.latestVersion?.images?.portraitIcon}
              name={name}
              slug={game.slug}
              type="link"
              link={`/game/${game.slug}`}
              price={game.price}
              originPrice={game.originPrice}
            />
          );
        })}
      </div>
    </div>
  );
};

export default HomePage;
