import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { getDashboardList } from "../../../service/dashboardService";

const pageSize = 10;

const DashboardList = () => {
  const [datas, setDatas] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageSettings, setPageSettings] = useState([0, pageSize]);

  useEffect(() => {
    const fetchDatas = async () => {
      const dataResponse = await getDashboardList();
      setDatas(dataResponse.results);
      setTotal(dataResponse.total);
    };

    fetchDatas();
  }, []);
  // 在此处获取Dashboard数据

  const columns = [
    {
      title: "日期",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "主页浏览量",
      dataIndex: "homeView",
      key: "homeView",
    },
    {
      title: "新增注册数",
      dataIndex: "newRegister",
      key: "newRegister",
    },
    {
      title: "登陆成功数",
      dataIndex: "signin",
      key: "signin",
    },
    {
      title: "弹窗打开次数",
      dataIndex: "openSignPopup",
      key: "openSignPopup",
    },
    {
      title: "新增游戏购买数量",
      dataIndex: "newPurchase",
      key: "newPurchase",
    },
    {
      title: "新增总下载量",
      dataIndex: "newDownloadGame",
      key: "newDownloadGame",
    },
    {
      title: "有过购买行为的user总数量",
      dataIndex: "purchasedUser",
      key: "purchasedUser",
    },
    {
      title: "平均每用户下载数量",
      dataIndex: "perUserDownload",
      key: "perUserDownload",
      render: (_, record) => (
        <div>{record.newDownloadGame / record.purchasedUser}</div>
      ),
    },
    {
      title: "新增总退款量",
      dataIndex: "newRefundedGame",
      key: "newRefundedGame",
    },
    {
      title: "累计总退款量",
      dataIndex: "allRefundedGame",
      key: "allRefundedGame",
    },
    {
      title: "DAU",
      dataIndex: "dau",
      key: "dau",
    },
    {
      title: "WAU",
      dataIndex: "wau",
      key: "wau",
    },
    {
      title: "MAU",
      dataIndex: "mau",
      key: "mau",
    },
    {
      title: "hub凤凰曝光量",
      dataIndex: "fhView",
      key: "fhView",
    },
    {
      title: "hub凤凰跳转量",
      dataIndex: "fhClick",
      key: "fhClick",
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Table
        rowClassName={() => "editable-row"}
        columns={columns}
        dataSource={datas}
        rowKey={(record) => record.id}
        pagination={{
          total: total,
          pageSize: pageSize,
          hideOnSinglePage: false,
          onChange: (page, pageSize) => {
            setPageSettings([(page - 1) * pageSize, pageSize]);
          },
        }}
        bordered
      />
    </div>
  );
};

export default DashboardList;
