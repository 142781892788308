import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const GameCard = (props) => {
  const [isHover, setIsHover] = React.useState(false);
  const navigate = useNavigate();
  const downloadButtonRef = React.useRef(null);
  // // Open a selection dialog for directories

  useEffect(() => {
    let downloadingItemId = new URLSearchParams(window.location.search).get(
      "download",
    );

    if (downloadingItemId !== null) {
      const itemId = downloadingItemId;
      if (itemId === props.item_id) {
        if (downloadButtonRef.current) {
          downloadButtonRef.current.click();
        }
      }
    }
  }, [downloadButtonRef, props.item_id]);

  const CardImageHover = {
    width: "160px",
    height: "213px",
    borderRadius: "12px",
    backgroundColor: "rgb(255 255 255 / 10%)",
    cursor: "pointer",
  };

  const CardImageStyle = {
    width: "160px",
    height: "213px",
    borderRadius: "12px",
    backgroundImage: `url(${props.imageUri})`,
    backgroundSize: "cover",
    backgroundColor: "lightgray",
    backgroundPosition: "center",
    cursor: "pointer",
  };

  return (
    <div>
      <a
        href={props.link}
        style={CardContainerStyle}
        onMouseOver={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
      >
        <div
          style={CardImageStyle}
          onClick={() => {
            if (props.type === "download") {
              if (props.link) {
                navigate(props.link);
              }
            }
          }}
        >
          {isHover && <div style={CardImageHover} />}
        </div>
        <p style={CardNameStyle}>{props.name}</p>
        {props.type === "link" && (
          <div style={{ display: "flex", gap: "4px", cursor: "pointer" }}>
            <p style={{ margin: 0, color: "#E42727", fontSize: "16px" }}>
              ¥{props.price}
            </p>
            {props.price &&
              props.originPrice &&
              props.price < props.originPrice && (
                <>
                  <p
                    style={{
                      margin: 0,
                      color: "#666",
                      textDecorationLine: "line-through",
                      fontSize: "14px",
                    }}
                  >
                    ¥{props.originPrice}
                  </p>
                  <div
                    style={{
                      borderRadius: "13px",
                      background: "var(---3, #FFB156)",
                      padding: "3px 6px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        color: "#FFF",
                        fontSize: "10px",
                        fontWeight: 600,
                      }}
                    >
                      {`-${Math.floor(
                        ((props.originPrice - props.price) /
                          props.originPrice) *
                          100,
                      )}%`}
                    </p>
                  </div>
                </>
              )}
          </div>
        )}
      </a>
    </div>
  );
};

export default GameCard;

const CardContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "160px",
  gap: "4px",
  textDecoration: "none",
};

const CardNameStyle = {
  fontSize: "14px",
  fontWeight: "600",
  color: "var(--text-primary, #333)",
  margin: "0",
};
