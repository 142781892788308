import apiClient from "./apiClient";

export async function userList(pageSettings, searchId, searchMobile) {
  let url = `${process.env.REACT_APP_API_URL}/api/user/list?start=${pageSettings[0]}&count=${pageSettings[1]}`;
  if (searchMobile) {
    url += `&mobile=${searchMobile}`;
  }
  if (searchId) {
    url += `&id=${searchId}`;
  }
  const response = await apiClient.get(url);
  return response.data;
}

export async function upsertUserRoles(userId, userRoles) {
  const response = await apiClient.put(
    `${process.env.REACT_APP_API_URL}/api/user/${userId}/roles`,
    { roles: userRoles },
  );
  return response.data;
}

export async function putUser(userId, data) {
  const response = await apiClient.put(
    `${process.env.REACT_APP_API_URL}/api/user/${userId}`,
    data,
  );
  return response.data;
}

export async function userInfo() {
  let url = `${process.env.REACT_APP_API_URL}/api/user/info`;
  const response = await apiClient.get(url);
  return response.data;
}

export async function updateUserInfo(data) {
  const response = await apiClient.post(
    `${process.env.REACT_APP_API_URL}/api/user/updateInfo`,
    data,
  );
  return response.data;
}

export async function userGames() {
  let url = `${process.env.REACT_APP_API_URL}/api/user/games`;
  const response = await apiClient.get(url);
  return response.data;
}

export async function searchUserByPhone(phone) {
  let url = `${process.env.REACT_APP_API_URL}/api/user/other?phone=${phone}`;
  const response = await apiClient.get(url);
  return response.data;
}

export async function searchUserByEmail(email) {
  let url = `${process.env.REACT_APP_API_URL}/api/user/other?email=${email}`;
  const response = await apiClient.get(url);
  return response.data;
}
