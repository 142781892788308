import styles from "./GamePage.module.css";
import { useTranslation } from "react-i18next";
import { extractGameDeepField, extractGameField } from "../../utils/i18n";

const Configuration = ({ gameData, gameItem }) => {
  const { t, i18n } = useTranslation();

  if (!gameData && !gameItem) {
    return <div />;
  }

  const lowConfig = gameData
    ? extractGameDeepField(gameData, i18n.language, "configuration", "base")
    : extractGameField(gameItem, i18n.language, "lowConfiguration");
  const highConifg = gameData
    ? extractGameDeepField(
        gameData,
        i18n.language,
        "configuration",
        "recommend",
      )
    : extractGameField(gameItem, i18n.language, "highConfiguration");

  const renderConfig = (config) => {
    return (
      <div>
        <div className={styles.div18}>
          <div className={styles.div19}>
            <p className={styles.p}>{t("os")}：</p>
          </div>
          <div className={styles.windows7AnythingContainer}>
            <p className={styles.p}>{config.os}</p>
          </div>
        </div>
        <div className={styles.div18}>
          <div className={styles.div19}>
            <p className={styles.p}>{t("processor")}：</p>
          </div>
          <div className={styles.windows7AnythingContainer}>
            <p className={styles.p}>{config.processor}</p>
          </div>
        </div>
        <div className={styles.div18}>
          <div className={styles.div19}>
            <p className={styles.p}>{t("memory")}：</p>
          </div>
          <div className={styles.windows7AnythingContainer}>
            <p className={styles.p}>{config.ram}</p>
          </div>
        </div>
        <div className={styles.div18}>
          <div className={styles.div19}>
            <p className={styles.p}>{t("graphics")}：</p>
          </div>
          <div className={styles.windows7AnythingContainer}>
            <p className={styles.p}>{config.graphic}</p>
          </div>
        </div>
        <div className={styles.div18}>
          <div className={styles.div19}>
            <p className={styles.p}>{t("storage")}：</p>
          </div>
          <div className={styles.windows7AnythingContainer}>
            <p className={styles.p}>{config.disk}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.div13}>
      <div className={styles.div14}>
        <div className={styles.rectangleParent}>
          <div className={styles.instanceChild} />
          <div className={styles.dlc2}>{t("configuration")}</div>
        </div>
      </div>
      <div className={styles.parent}>
        <div className={styles.div16}>
          <div className={styles.dfkdffkaljj}>
            {t("minimum_configuration")}:
          </div>
        </div>
        {lowConfig && renderConfig(lowConfig)}
      </div>
      {highConifg && highConifg.os && (
        <div className={styles.div20}>
          <div className={styles.dfkdffkaljj}>
            <p className={styles.configRecommend}>
              {t("recommend_configuration")}:
            </p>
            {renderConfig(highConifg)}
          </div>
        </div>
      )}
    </div>
  );
};

export default Configuration;
